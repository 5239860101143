import React from "react";

import { ContentContainer, Title } from "components/simple";

import styles from "./styles.module.scss";

const DetailsBox = ({ data, openModal }) => {
  const name = data?.name || "";
  const label = data?.label || "";
  const type = data?.type || "";
  const counstTickets = data?.tickets || 0;
  const last_seen = data?.last_seen || "";
  return (
    <ContentContainer className={styles.container}>
      <Title>Asset details</Title>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.label}>Asset Name:</div>
          <div className={styles.value}>{name}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Label:</div>
          <div className={styles.value}>{label}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Type:</div>
          <div className={styles.value}>{type}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Last Seen:</div>
          <div className={styles.value}>{last_seen}</div>
        </div>
        <div className={styles.blockRow}>
          <div className={`${styles.block} ${styles.red}`} onClick={() => openModal("Tickets")}>
            <div className={styles.count}>{counstTickets}</div>
            <div className={styles.title}>Tickets</div>
          </div>
          <div className={`${styles.block} ${styles.green}`} onClick={() => openModal("Groups")}>
            <div className={styles.count}>{data?.groups}</div>
            <div className={styles.title}>Groups</div>
          </div>
          <div
            className={`${styles.block} ${styles.orange}`}
            onClick={() => openModal("Audit Files")}
          >
            <div className={styles.count}>{data?.audit_files}</div>
            <div className={styles.title}>Audit Files</div>
          </div>
          <div className={`${styles.block} ${styles.blue}`} onClick={() => openModal("Monitors")}>
            <div className={styles.count}>{data?.monitors}</div>
            <div className={styles.title}>Monitors</div>
          </div>
          <div
            className={`${styles.block} ${styles.darkBlue}`}
            onClick={() => openModal("Linked Assets")}
          >
            <div className={styles.count}>{data?.linkedAssets}</div>
            <div className={styles.title}>Linked Assets</div>
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default DetailsBox;
