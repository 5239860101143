import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { createLoadingSelector } from "store/entities/loading/selector";
import {
  getBaselineConfigFiltersSelect,
  getbaselineConfigControlSelector
} from "store/entities/baseline/selectors";
import { getBaselineControl, getBaselineTagById, postTags } from "store/entities/baseline/actions";

import { getTags as fetchTags } from "store/entities/tags/service";

import {
  Button,
  Input,
  Textarea,
  Select,
  SelectAsync,
  DatePicker,
  Icon,
  Loader,
  Switch
} from "components/simple";

import styles from "./styles.module.scss";
import { formatDate } from "helper/DateLib";

const Metadata = ({
  baselineId,
  controlIds,
  isSingleMode,
  setSingleMode,
  onCloseModal,
  isClear = true,
  ...rest
}) => {
  const initialState = {
    expectedResults: "",
    tags: [],
    impact: "",
    systems_impacted: "",
    controls: "",
    risk: "",
    date: "",
    comments: "",
    issues: "",
    apply_actual: false
  };
  const [state, setState] = useState({
    ...initialState
  });

  const clickedControl = useSelector(getbaselineConfigControlSelector);
  const expectedResultOptions = useSelector(getBaselineConfigFiltersSelect);
  const isGetControlLoading = useSelector(s => createLoadingSelector([getBaselineControl.type])(s));
  const isPostTagsLoading = useSelector(s => createLoadingSelector([postTags.type])(s));

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      Object.getOwnPropertyNames(clickedControl).length !== 0 &&
      (rest?.fromDetails || isSingleMode)
    ) {
      const { expected_result, tags: fields, tag } = clickedControl;
      const tagsSelected = tag?.map(elem => ({ value: elem.id, label: elem.value }));
      const expectedResults = expectedResultOptions.result?.find(
        ({ value }) => value === expected_result
      );

      setState({
        ...state,
        ...fields,
        expectedResults,
        tags: tagsSelected
      });
    }
  }, [clickedControl]);

  const inputChangeHandler = (value, name) => {
    setState({
      ...state,
      [name]: value
    });
  };

  const closeModalHandler = () => {
    onCloseModal();
    if (isClear) {
      setState({ ...initialState });
      setSingleMode(false);
    }
  };

  const submitDetailsHandler = () => {
    if (rest?.fromDetails) {
      dispatch(
        getBaselineTagById({
          id: rest?.id,
          controlId: rest?.id,
          baselineId
        })
      );
    }
    closeModalHandler();
  };

  useEffect(() => {
    return () => closeModalHandler();
  }, []);

  const submitMetadataHandler = () => {
    const {
      systems_impacted,
      impact,
      risk,
      controls,
      issues,
      comments,
      date,
      expectedResults,
      tags,
      apply_actual
    } = state;
    const tag = tags.reduce((acc, elem) => [...acc, elem.value], []);

    dispatch(
      postTags({
        data: {
          expected_result: expectedResults.value,
          tags: {
            date,
            systems_impacted,
            impact,
            risk,
            controls,
            issues,
            comments
          },
          tag,
          apply_actual,
          baseline_id: baselineId,
          control_ids: controlIds
        },
        successCallback: submitDetailsHandler,
        id: baselineId
      })
    );
  };

  const request = async (search, page) => {
    try {
      const res = await fetchTags({ search, page, pageSize: 20 }).then(r => r.json());
      const listOptions = res?.results?.map(item => ({ value: item?.id, label: item?.value }));
      return { options: listOptions, next: res?.next };
    } catch (e) {
      // return console.log("err", e);
    }
  };

  const loadOptions = async (search, prevOptions, { page }) => {
    const { options, next } = await request(search, page);
    const hasMore = !search ? Boolean(next) : false;

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    };
  };

  return (
    <>
      {isGetControlLoading ? (
        <Loader />
      ) : (
        <>
          {rest?.fromDetails && !!rest?.groupIds?.length && (
            <div className={styles.row}>
              <Switch
                label="Apply Actual to Group"
                value={state?.apply_actual}
                className={styles.switch}
                onChange={v => inputChangeHandler(v, "apply_actual")}
                labelPlacement="start"
              />
            </div>
          )}
          <div className={styles.fieldsContainer}>
            <div className={styles.column}>
              <Select
                value={state.expectedResults}
                options={rest?.resOptions || expectedResultOptions.result}
                label="Expected Results"
                onChange={val => inputChangeHandler(val, "expectedResults")}
              />
              <SelectAsync
                containerClass={styles.selectPanel}
                onChange={val => inputChangeHandler(val, "tags")}
                value={state.tags}
                label="Tag"
                isClearable
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                cacheOptions
                defaultOptions
                loadOptions={loadOptions}
                onInputChange={() => null}
                additional={{
                  page: 0
                }}
              />
              <Input
                value={state.impact}
                label="Operational/Business Impact"
                onChange={val => inputChangeHandler(val.target.value, "impact")}
              />
              <Input
                value={state.systems_impacted}
                label="Systems Impacted"
                onChange={val => inputChangeHandler(val.target.value, "systems_impacted")}
              />
            </div>
            <div className={styles.column}>
              <Input
                value={state.controls}
                label="Mitigating/Compensation Controls"
                onChange={val => inputChangeHandler(val.target.value, "controls")}
              />
              <Input
                value={state.risk}
                label="Technical Security Risk"
                onChange={val => inputChangeHandler(val.target.value, "risk")}
              />
              <DatePicker
                value={state.date}
                label="Date Approved"
                onChange={val => {
                  const date = formatDate(val, "DD/MM/YYYY");
                  inputChangeHandler(date, "date");
                }}
              />
            </div>
            <div className={styles.column}>
              <Textarea
                value={state.comments}
                label="Comments"
                onChange={val => inputChangeHandler(val, "comments")}
                className={styles.textarea}
              />
              <Input
                value={state.issues}
                label="Technical Issues"
                onChange={val => inputChangeHandler(val.target.value, "issues")}
              />
            </div>
          </div>
          <div className={styles.controls}>
            {controlIds.length > 1 && (
              <div className={styles.alert}>
                <Icon.Warning />
                <span>You are editing multiple items</span>
              </div>
            )}
            <Button
              onClick={submitMetadataHandler}
              isLoading={isPostTagsLoading}
              className={styles.btn}
            >
              Apply
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default Metadata;
