import Sort from "assets/images/Sort.svg";
import CircleLogo from "assets/images/CircleLogo.svg";
import Reactangle from "assets/images/Reactangle.svg";
import PreviewCards from "assets/images/PreviewCards.svg";
import Arrow from "assets/images/Arrow.svg";

export default {
  sort: Sort,
  circleLogo: CircleLogo,
  reactangle: Reactangle,
  previewCards: PreviewCards,
  arrow: Arrow
};
