/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { editLabel, addLabel, getLabel } from "store/entities/settings/actions";

import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Title, Button, Input } from "components/simple";

import styles from "./styles.module.scss";

const LabelDetails = () => {
  const initialState = {
    name: "",
    aws_prefix: ""
  };
  const [state, setState] = useState({ ...initialState });
  const selectTenant = useSelector(s => s.users.selectTenant);

  const label = useSelector(s => s.settings.label);

  const isSaveLoading = useSelector(
    s => createLoadingSelector([editLabel.type])(s) || createLoadingSelector([addLabel.type])(s)
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  const inputChangeHandler = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(getLabel({ id }));
      return;
    }
    setState({
      ...initialState
    });
  }, [id, selectTenant]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(label).length !== 0 && id) {
      setState({
        ...label
      });
    }
  }, [label]);

  const saveHandler = e => {
    e.preventDefault();
    const { name, aws_prefix } = state;
    if (id) {
      dispatch(
        editLabel({
          id,
          data: {
            name,
            aws_prefix
          }
        })
      );
      return;
    }
    dispatch(
      addLabel({
        name,
        aws_prefix
      })
    );
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>{id ? "Label details" : "Add label"}</Title>
      <form onSubmit={saveHandler}>
        <Input
          label="Name"
          value={state.name}
          onChange={e => inputChangeHandler(e.target.value, "name")}
          containerClassName={styles.input}
          required
        />
        <Input
          label="Prefix"
          value={state.aws_prefix}
          onChange={e => inputChangeHandler(e.target.value, "aws_prefix")}
          containerClassName={styles.input}
          required
        />

        <Button className={styles.btn} type="submit" isLoading={isSaveLoading}>
          Save
        </Button>
      </form>
    </ContentContainer>
  );
};

LabelDetails.propTypes = {};

export default LabelDetails;
