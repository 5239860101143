/* eslint-disable */
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";

// Routes paths
import * as routes from "./routes";

// Guard routes
import PrivateRoute from "components/PrivateRoute";
import UnauthorizedOnlyRoute from "components/UnauthorizedOnlyRoute";

// Components
import AuthContainer from "screens/Auth/AuthContainer";
import Dashboard from "screens/Dashboard";

import { navigationRef } from "helper/history";

import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material";
import DivyColorTheme from "../theme";
import { changeTheme } from "store/entities/theme/actions";

const MainRouter = () => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.theme.theme);
  const localStorageTheme = localStorage.getItem("theme") || theme;

  // setup theme
  const [colorTheme, setColorTheme] = useState(DivyColorTheme[localStorageTheme]);
  const divyTheme = colorTheme;

  useEffect(() => {
    setColorTheme(DivyColorTheme[localStorageTheme]);
    dispatch(changeTheme(localStorageTheme));
  }, [theme]);

  return (
    <ThemeProvider theme={divyTheme}>
      <Router ref={navigationRef}>
        <Switch>
          <UnauthorizedOnlyRoute path={routes.loginScreen} component={AuthContainer} />
          <UnauthorizedOnlyRoute path={`${routes.verification}/:id`} component={AuthContainer} />
          <UnauthorizedOnlyRoute exact path={routes.forgotPassword} component={AuthContainer} />
          <UnauthorizedOnlyRoute
            exact
            path={`${routes.resetPasswordScreen}/:uid/:user_token`}
            component={AuthContainer}
          />
          <PrivateRoute exact path={routes.home} component={Dashboard} />
          <PrivateRoute exact path={routes.dataStructureHistory} component={Dashboard} />
          <PrivateRoute exact path={routes.dataStructureTags} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.dataStructureTagsDetails}/:id`}
            component={Dashboard}
          />
          <PrivateRoute exact path={routes.dataStructureGroups} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.dataStructureGroupsDetails}/:id`}
            component={Dashboard}
          />
          <PrivateRoute exact path={routes.dataStructureDatalist} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.dataStructureDatalistCreateTicket}/:ticketConfig_id`}
            component={Dashboard}
          />

          <PrivateRoute exact path={routes.dataStructureAssets} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.dataStructureAssetsDetails}/:id`}
            component={Dashboard}
          />

          <PrivateRoute exact path={routes.dataStructureLabels} component={Dashboard} />
          <PrivateRoute exact path={routes.dataStructureBaselines} component={Dashboard} />
          <PrivateRoute exact path={routes.vulnerabilities} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.vulnerabilitiesCreateTicket}/:ticketConfig_id`}
            component={Dashboard}
          />
          <PrivateRoute exact path={`${routes.vulnerabilitiesDetails}/:id`} component={Dashboard} />
          <PrivateRoute exact path={routes.baselines} component={Dashboard} />
          <PrivateRoute exact path={`${routes.failedBaseline}/:id`} component={Dashboard} />
          <PrivateRoute exact path={routes.createBaseline} component={Dashboard} />
          <PrivateRoute exact path={`${routes.editBaseline}/:id`} component={Dashboard} />
          <PrivateRoute exact path={`${routes.baselineConfiguration}/:id`} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.baselineConfigurationCreateTicket}/:ticketConfig_id/:baseline_id`}
            component={Dashboard}
          />
          <PrivateRoute exact path={`${routes.compareBaselines}/:ids`} component={Dashboard} />
          <PrivateRoute exact path={routes.targets} component={Dashboard} />
          <PrivateRoute exact path={`${routes.targetsDetails}/:id`} component={Dashboard} />

          <PrivateRoute exact path={routes.cloudAccounts} component={Dashboard} />
          <PrivateRoute exact path={routes.addCloudAccount} component={Dashboard} />
          <PrivateRoute exact path={`${routes.editCloudAccount}/:id`} component={Dashboard} />

          <PrivateRoute exact path={routes.tickets} component={Dashboard} />
          <PrivateRoute exact path={routes.ticketsDetails} component={Dashboard} />
          <PrivateRoute exact path={`${routes.ticketsDetails}/:id`} component={Dashboard} />

          <PrivateRoute exact path={routes.reports} component={Dashboard} />
          <PrivateRoute exact path={routes.reportsDetails} component={Dashboard} />
          <PrivateRoute exact path={`${routes.reportsDetails}/:id`} component={Dashboard} />

          <PrivateRoute exact path={routes.notifications} component={Dashboard} />
          <PrivateRoute exact path={`${routes.notificationsDetails}/:id`} component={Dashboard} />

          <PrivateRoute exact path={routes.settings} component={Dashboard} />
          <PrivateRoute exact path={`${routes.settingsDataSourceEdit}`} component={Dashboard} />
          {/* Settings Integrations */}
          <PrivateRoute exact path={routes.settingsIntegrations} component={Dashboard} />
          <PrivateRoute exact path={routes.settingsIntegrationsJiraServer} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.settingsIntegrationsJiraServer}/:id`}
            component={Dashboard}
          />
          <PrivateRoute exact path={routes.settingsIntegrationsJiraCloud} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.settingsIntegrationsJiraCloud}/:id`}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={routes.settingsIntegrationsFreshservice}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={`${routes.settingsIntegrationsFreshservice}/:id`}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={routes.settingsIntegrationsEmailsDetails}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={`${routes.settingsIntegrationsEmailsDetails}/:id`}
            component={Dashboard}
          />

          <PrivateRoute exact path={routes.settingsIntegrationsConnectWise} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.settingsIntegrationsConnectWise}/:id`}
            component={Dashboard}
          />
          {/* Settings Integrations End */}
          <PrivateRoute exact path={routes.settingsTicketTemplates} component={Dashboard} />
          <PrivateRoute exact path={routes.settingsTicketTemplatesDetails} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.settingsTicketTemplatesDetails}/:id`}
            component={Dashboard}
          />
          <PrivateRoute exact path={routes.settingsRbac} component={Dashboard} />
          <PrivateRoute exact path={routes.settingsRbacRoleDetails} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.settingsRbacRoleDetails}/:id`}
            component={Dashboard}
          />

          <PrivateRoute exact path={routes.settingsLabels} component={Dashboard} />
          <PrivateRoute exact path={routes.settingsLabelsDetails} component={Dashboard} />
          <PrivateRoute exact path={`${routes.settingsLabelsDetails}/:id`} component={Dashboard} />

          <PrivateRoute exact path={routes.settingsUserProfiles} component={Dashboard} />
          <PrivateRoute
            exact
            path={`${routes.settingsUserProfilesDetails}`}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={`${routes.settingsUserProfilesDetails}/:id`}
            component={Dashboard}
          />

          <PrivateRoute exact path={routes.profile} component={Dashboard} />

          {/* Monitors */}
          <PrivateRoute exact path={routes.monitors} component={Dashboard} />
          <PrivateRoute exact path={`${routes.monitorsConfiguration}`} component={Dashboard} />
          <PrivateRoute exact path={`${routes.monitorsConfiguration}/:id`} component={Dashboard} />

          <Redirect to={routes.home} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default MainRouter;
export { routes };
