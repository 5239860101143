/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import {
  getDataListsSelector,
  getTicketsConfigSelect,
  getGroupByListSelect,
  getDisplayFieldsListSelect
} from "store/entities/datalist/selectors";

import { addNotification } from "store/entities/notifications/actions";

import {
  getDataLists,
  setDataListsParams,
  updDataListsParams,
  exportDataList,
  actionGroupModal,
  actionTargetModal,
  changeGroupByDataType,
  setPanelState
} from "store/entities/datalist/actions";

import { createLoadingSelector } from "store/entities/loading/selector";
import { ActionButton, Search, Select, CustomDetailPanelToggle } from "components/simple";
import { Table } from "components/complex";
import SubTable from "./SubTable";

import {
  useGridApiRef,
  visibleGridColumnsSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from "@mui/x-data-grid-pro";

import styles from "./styles.module.scss";
import Panel from "./Panel";
import { getTicketsConfig } from "store/entities/tickets/actions";
import { onChangeUrlParams, useQuery } from "helper/history";

import { CreateTargetModal } from "./CreateTargetModal";
import { CreateGroupModal } from "./CreateGroupModal";

import { routes } from "router";

const DataList = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");
  const querySearch = query.get("search");

  const {
    page,
    pageSize,
    selectConfig,
    temporaryData,
    filters,
    groupBy,
    orderModel,
    selectTicketConfig,
    search,
    panelState
  } = useSelector(state => state.datalist);

  const ticketsConfigList = useSelector(getTicketsConfigSelect);

  const displayFieldsList = useSelector(getDisplayFieldsListSelect);

  const display_fields = temporaryData?.display_fields || "";

  const display_fields_width = temporaryData?.display_fields_width || "";

  const groupByList = useSelector(state => getGroupByListSelect(state, display_fields));

  const { columns, data, total } = useSelector(state =>
    getDataListsSelector(state, display_fields, groupBy, display_fields_width, false)
  );

  const isLoading = useSelector(state => createLoadingSelector([getDataLists.type])(state));

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = React.useRef(selectionModel);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState([]);
  const [selectionModelSubTable, setSelectionModelSubTable] = useState([]); // selections for  Expend Table

  const selectTenant = useSelector(state => state.users.selectTenant);

  const apiRef = useGridApiRef();

  const onClearTemporaryData = () => {
    onChangeUrlParams("page", 0);
    if (temporaryData) {
      dispatch(
        setDataListsParams({
          page: 0,
          ordering: "",
          search: "",
          filters: {},
          groupBy: { value: "data_type", label: "Data Type" },
          selectConfig: null,
          temporaryData: null,
          orderModel: []
        })
      );
    }
  };

  // get default request
  const getRequest = () => {
    const group = querySearch ? "" : { value: "data_type", label: "Data Type" };
    dispatch(
      setDataListsParams({
        page: queryPage ? queryPage - 1 : 0,
        groupBy: group,
        search: querySearch
      })
    );
  };

  const clear = () => {
    const current = history?.location?.pathname;
    const isActive = () => {
      const currentRoute = current.replaceAll("/", " ");
      const route = routes.dataStructureDatalist.replaceAll("/", " ");
      return currentRoute.includes(route);
    };

    if (!isActive()) {
      onChangeUrlParams("page", 0);
      dispatch(
        updDataListsParams({
          page: 0,
          ordering: "",
          search: "",
          filters: {},
          groupBy: { value: "data_type", label: "Data Type" },
          selectConfig: null,
          temporaryData: null,
          selectTicketConfig: "",
          orderModel: []
        })
      );
    }
  };

  useEffect(() => {
    getRequest();
    dispatch(getTicketsConfig("data-list"));
    return () => clear();
  }, [selectTenant]);

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      let fieldSort = field;

      if (field === "__detail_panel_toggle__") {
        fieldSort = "group_ids";
      }

      if (sort === "asc") {
        dispatch(
          setDataListsParams({
            ordering: fieldSort,
            orderModel: s
          })
        );
      } else {
        dispatch(
          setDataListsParams({
            ordering: `-${fieldSort}`,
            orderModel: s
          })
        );
      }
    } else {
      dispatch(
        setDataListsParams({
          ordering: "",
          orderModel: s
        })
      );
    }
  };

  // filters
  const onChangeSelectedFilterFields = v => {
    const isEmpty = !Object.values(v).some(x => x);
    const filtr = { ...filters, ...v };
    if (isEmpty) {
      Object.keys(v).some(x => {
        delete filtr[x];
        return true;
      });
    }
    dispatch(
      setDataListsParams({
        ordering: "",
        filters: filtr
      })
    );
  };

  // Load temporary data
  const onLoadData = r => {
    onChangeUrlParams("page", 0);
    dispatch(
      setDataListsParams({
        page: 0,
        ordering: "",
        search: "",
        temporaryData: r
      })
    );
  };

  // Handle for select config
  const onSetSelectConfig = v => {
    // onChangeUrlParams("config", v?.value || "");
    dispatch(
      setDataListsParams({
        page: 0,
        selectConfig: v,
        temporaryData: v ? v?.result : null,
        ordering: "",
        search: "",
        filters: {},
        groupBy: null
      })
    );
  };

  // Next page
  const onPageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setDataListsParams({
          page: newPage
        })
      );
    }
  };

  React.useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
    setDetailPanelExpandedRowIds([]); // clear expend row
    setSelectionModelSubTable([]);
  }, [page, data]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    onChangeUrlParams("search", "");
    dispatch(
      setDataListsParams({
        page: 0,
        search: v
      })
    );
  };

  // Actions
  const handleAction = type => {
    const selectionModelSubTableRow = selectionModelSubTable
      ?.map(item => item?.selectionModel)
      ?.flat();

    const ticketConfig_id = selectTicketConfig?.value;
    const groupByValue = groupBy?.value;
    const myMap = apiRef?.current?.getSelectedRows();
    const values = Array.from(myMap.values());
    const ids = groupByValue
      ? [...new Set([...values?.map(item => item?.group_ids).flat(), ...selectionModelSubTableRow])]
      : selectionModel;

    if (type === "export") {
      const fields = display_fields || displayFieldsList?.map(item => item.value);
      dispatch(exportDataList({ displayFields: fields, ids }));
      return;
    }
    if (type === "monitor") {
      let data_type = values?.[0]?.data_type;
      if (selectionModelSubTableRow?.length > 0) {
        const subTable = data?.find(item => item.id === selectionModelSubTable[0].id);
        data_type = subTable?.data_type;
      }
      const valid = () => {
        if (values?.length < 1 || values?.length > 1) {
          if (selectionModelSubTableRow?.length !== 0 && values?.length < 1) {
            return true;
          }
          dispatch(addNotification({ msg: "You need to select just one row", type: "warning" }));
          return false;
        }
        return true;
      };
      if (valid()) {
        const url = display_fields?.length ? `&display_fields=${display_fields?.join(",")}` : "";
        history.push(`${routes.monitorsConfiguration}?data_type=${data_type}${url}`);
      }
      return;
    }

    if (type === "createTicket" && !selectTicketConfig?.value) {
      dispatch(addNotification({ msg: "You need to select ticket config", type: "warning" }));
      return;
    }
    if (!selectionModel?.length && !selectionModelSubTableRow?.length) {
      dispatch(addNotification({ msg: "You must select at least one row", type: "warning" }));
      return;
    }
    switch (type) {
      case "addToGroup":
        dispatch(actionGroupModal({ show: true, data: selectionModel }));
        break;
      case "addToTarget":
        dispatch(actionTargetModal({ show: true, data: selectionModel }));
        break;
      case "createTicket": {
        const url = groupByValue ? `&groupBy=${groupByValue}` : "";

        history.push(
          `${routes.dataStructureDatalistCreateTicket}/${ticketConfig_id}?ids=${ids.join(
            ","
          )}${url}`
        );
        break;
      }
      default:
        break;
    }
  };

  // Select Group By
  const onGroupBy = v => {
    dispatch(
      setDataListsParams({
        ordering: "",
        groupBy: v,
        page: 0
      })
    );
    setSelectionModel([]);
  };

  const onSelectTicketConfig = v => {
    dispatch(updDataListsParams({ selectTicketConfig: v }));
  };

  const setPageSize = v => {
    dispatch(
      setDataListsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (v, e) => {
    const { field, row } = v;
    e.stopPropagation();
    switch (field) {
      // Click on group ids column when it is grouped by dataType
      case "__detail_panel_toggle__":
        if (groupBy?.value !== "data_type") {
          break;
        }
      // eslint-disable-next-line no-fallthrough
      case "data_type":
        dispatch(
          changeGroupByDataType({
            ordering: "",
            groupBy: "",
            page: 0
          })
        );
        dispatch(
          setDataListsParams({
            page: 0,
            ordering: "",
            search: "",
            temporaryData: {
              data_type: row.data_type || ""
            }
          })
        );
        dispatch(
          setPanelState({
            data_type: { value: row.data_type, label: row.data_type },
            asset: "",
            filter_fields: [],
            display_fields: []
          })
        );
        break;
      default:
        break;
    }
    // const id = v?.id;
  };

  // Expend
  const onChangeSelectionModelSubTable = (v, rowId) => {
    const res = {
      id: rowId,
      selectionModel: v
    };
    let newSelection = [];

    if (selectionModelSubTable.find(item => item?.id === rowId)) {
      newSelection = selectionModelSubTable?.map(item => {
        if (item?.id === rowId) {
          return res;
        }
        return item;
      });
    } else {
      newSelection = [...selectionModelSubTable, res];
    }

    if (selectionModel.find(item => item === rowId)) {
      const resetSelectForMainTable = selectionModel?.filter(item => item !== rowId);
      setSelectionModel(resetSelectForMainTable);
    }

    setSelectionModelSubTable(newSelection);
  };

  const [expandHeight, setExpandHeight] = useState(150);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => {
      return (
        <SubTable
          row={row}
          onCellClick={onCellClick}
          selectionModelSubTable={selectionModelSubTable}
          setExpandHeight={setExpandHeight}
          expandHeight={expandHeight}
          display_fields={display_fields}
          display_fields_width={display_fields_width}
          onChangeSelectionModelSubTable={v => onChangeSelectionModelSubTable(v, row?.id)}
        />
      );
    },
    [selectionModelSubTable, expandHeight]
  );

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    newIds => {
      setDetailPanelExpandedRowIds(newIds.slice(-1));
    },
    [apiRef]
  );

  // Expend End

  useEffect(() => {
    return apiRef.current.subscribeEvent("columnHeaderDragEnd", () => {
      const newOrder = visibleGridColumnsSelector(apiRef.current.state)
        .map(col => col.field)
        ?.filter(item => item !== "__check__");

      dispatch(
        updDataListsParams({
          temporaryData: {
            ...temporaryData,
            display_fields: newOrder
          }
        })
      );
    });
  }, [apiRef, temporaryData]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  const timerRef = React.useRef();
  const triggerChangeWidth = widthArrat => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      dispatch(
        updDataListsParams({
          temporaryData: {
            ...temporaryData,
            display_fields_width: widthArrat
          }
        })
      );
    }, 500);
  };

  const onColumnResize = v => {
    const fields = display_fields_width;
    const prevColWidth = fields ?? [];
    const newCol = [...prevColWidth, { field: v?.colDef?.field, width: v?.width }];
    triggerChangeWidth(getUniqueListBy(newCol, "field"));
  };

  const customStyles = {
    height: 710,
    width: 1,
    "& .super-app-theme--header": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #E7E6F8"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      marginBottom: "0px"
    }
  };
  return (
    <div className={styles.container}>
      <Panel
        selectConfig={selectConfig}
        temporaryData={temporaryData}
        setSelectConfig={onSetSelectConfig}
        onChangeSelectedFilterFields={onChangeSelectedFilterFields}
        selectedFilterFields={filters}
        onLoadData={onLoadData}
        onClearTemporaryData={onClearTemporaryData}
      />
      <div className={styles.content}>
        <div className={styles.filters}>
          <Search onSearch={handleSearch} value={search} />

          <div className={styles.filterRow}>
            <div className={styles.selectsGroup}>
              {ticketsConfigList?.length ? (
                <Select
                  containerClass={styles.select}
                  onChange={onSelectTicketConfig}
                  value={selectTicketConfig}
                  placeholder="Ticket Configuration"
                  options={ticketsConfigList}
                  isClearable
                />
              ) : null}

              <Select
                containerClass={styles.select}
                onChange={onGroupBy}
                value={groupBy}
                placeholder="Group by"
                options={groupByList}
                isClearable
              />
            </div>
            <div className={styles.buttonGroup}>
              {groupBy?.value === "data_type" ? null : (
                <>
                  <ActionButton
                    type="addToGroup"
                    onClick={handleAction}
                    className={styles.actionButton}
                  />
                  <ActionButton
                    type="createTicket"
                    onClick={handleAction}
                    className={styles.actionButton}
                  />
                  <ActionButton
                    type="addToTarget"
                    onClick={handleAction}
                    className={styles.actionButton}
                  />
                </>
              )}

              <ActionButton type="export" onClick={handleAction} className={styles.actionButton} />
              <ActionButton type="monitor" onClick={handleAction} className={styles.actionButton} />
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <Table
            apiRef={apiRef}
            onColumnResize={onColumnResize}
            data={isLoading ? [] : data}
            // columns={columns}
            columns={[
              {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                headerName: "Group ids",
                field: "__detail_panel_toggle__",
                width: 120,
                sortable: true,
                // dataId: "column-header",
                renderHeader: () => {
                  if (groupBy?.value === "data_type") {
                    return <span className={styles.columnHeader}>Group ids</span>;
                  }
                  return null;
                },
                renderCell: params => {
                  if (groupBy?.value === "data_type") {
                    return <span>{params?.row?.group_ids_counter ?? 0}</span>;
                  }
                  return (
                    <span>
                      {params?.row?.group_ids_counter || 0}
                      {params?.row?.group_ids_counter > 1 ? (
                        <CustomDetailPanelToggle {...params} />
                      ) : null}
                    </span>
                  );
                }
              },
              ...columns
            ]}
            loading={isLoading}
            // onSelectionModelChange={newSelectionModel => {
            //   setSelectionModel(newSelectionModel);
            // }}
            onSelectionModelChange={newSelectionModel => {
              if (groupBy) {
                const current = newSelectionModel.slice(-1)[0];
                const res = data?.find(item => item?.id === current);
                if (current) {
                  const groupIds = res?.group_ids || [];
                  if (selectionModelSubTable?.find(item => item.id === current)) {
                    const rr = selectionModelSubTable?.map(item => {
                      if (item.id === current) {
                        if (newSelectionModel?.find(i => i === item?.id)) {
                          return { ...item, selectionModel: groupIds };
                        }
                        return;
                      }
                      if (newSelectionModel?.find(i => i !== item?.id)) {
                        return { ...item, selectionModel: [] };
                      }
                      return item;
                    });
                    setSelectionModelSubTable(rr);
                  } else {
                    setSelectionModelSubTable([
                      ...selectionModelSubTable,
                      { id: current, selectionModel: groupIds }
                    ]);
                  }
                } else {
                  setSelectionModelSubTable([]);
                }
              }

              setSelectionModel(newSelectionModel);
            }}
            page={page || 0}
            selectionModel={selectionModel}
            sortModel={orderModel}
            onSortModelChange={model => handleSort(model)}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowCount={total}
            paginationMode="server"
            onPageChange={onPageChange}
            initialState={{ pinnedColumns: { left: ["__check__"] } }}
            onCellClick={(p, e) => onCellClick(p, e)}
            //
            {...(groupBy ? { getDetailPanelHeight: () => expandHeight } : {})}
            {...(groupBy ? { getDetailPanelContent } : {})}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            customStyles={customStyles}
          />
        </div>
      </div>
      <CreateTargetModal />
      <CreateGroupModal />
    </div>
  );
};

DataList.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default DataList;
