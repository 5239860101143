/* eslint-disable no-return-await */

export const REQUEST_METHODS = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE"
};

export const request = async (
  url = "",
  method = REQUEST_METHODS.GET,
  body = null,
  headers = { "Content-Type": "application/json" }
) => {
  return await fetch(url, {
    method,
    headers,
    body
  });
};

export const get = (url, headers) => {
  return request(url, REQUEST_METHODS.GET, null, headers);
};

export const post = (url, data, headers) => {
  return request(url, REQUEST_METHODS.POST, data, headers);
};

export const put = (url, data, headers) => {
  return request(url, REQUEST_METHODS.PUT, data, headers);
};

export const patch = (url, data, headers) => {
  return request(url, REQUEST_METHODS.PATCH, data, headers);
};

export const remove = (url, data, headers) => {
  return request(url, REQUEST_METHODS.DELETE, data, headers);
};
