import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "components/simple";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";

import { actionCreateTargetModal, createTarget } from "store/entities/targets/actions";
import {
  getTargetTagOptionsSelector,
  getTargetTypeOptionsSelector
} from "store/entities/targets/selectors";
import { labelsMiniSelector } from "store/entities/labels/selectors";

const CreateTargetModal = () => {
  const dispatch = useDispatch();
  const { show } = useSelector(state => state.targets?.createTargetModal);
  const targetTypeOptions = useSelector(getTargetTypeOptionsSelector);
  const targetTagOptions = useSelector(getTargetTagOptionsSelector);
  const labelsOptions = useSelector(labelsMiniSelector);

  const targetTypes = useSelector(state => state.targets?.targetTypes);
  const scheduleData = useSelector(state => state.targets?.scheduleData);
  const [currTargetType, setCurrTargetType] = useState();
  const currTargetTypeData = useMemo(() => {
    if (!targetTypes || !currTargetType) {
      return null;
    }

    return targetTypes.find(item => item.id === currTargetType.value);
  }, [targetTypes, currTargetType]);

  const [currStep, setCurrStep] = useState(1);
  useEffect(() => {
    setCurrStep(1);
  }, [show]);

  const handleClose = () => {
    dispatch(actionCreateTargetModal({ show: false }));
  };

  const handleStep1Next = () => {
    setCurrStep(2);
  };

  const handleStep2Back = () => {
    setCurrStep(1);
  };

  const handleStep2Save = data => {
    dispatch(createTarget(data));
  };

  return (
    <Modal
      isOpen={show}
      toggle={handleClose}
      sx={{
        maxHeight: "85vh",
        overflow: "auto"
      }}
    >
      {currStep === 1 ? (
        <Step1
          typeOptions={targetTypeOptions}
          currType={currTargetType}
          onCurrTypeChanged={setCurrTargetType}
          onNext={handleStep1Next}
          onCancel={handleClose}
        />
      ) : (
        <Step2
          currType={currTargetTypeData}
          tagOptions={targetTagOptions}
          labelsOptions={labelsOptions}
          scheduleData={scheduleData}
          onBack={handleStep2Back}
          onSave={handleStep2Save}
        />
      )}
    </Modal>
  );
};

export default CreateTargetModal;
