/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, remove, patch } from "api/base";

const generatorFilterUrl = obj => {
  if (!obj) {
    return "";
  }
  let url = "";
  Object.entries(obj).forEach(([key, val]) => {
    if (key === "group") {
      url += `&group_id=${val?.value || ""}`;
    }
    if (key === "asset") {
      url += `&asset_id=${val?.value || ""}`;
    }
    if (key === "data_type") {
      url += `&data_type=${val?.value || ""}`;
    }
  });
  return url;
};

export const getMonitors = fields => {
  const { page, pageSize, search, filterBy, ordering } = fields;
  let url = "";
  const filt = generatorFilterUrl(filterBy);

  if (search) {
    url += `&search=${search}`;
  }

  return get(
    `${API}/monitors?page=${page + 1 || 1}&page_size=${pageSize}${url}${filt}&ordering=${ordering}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getMonitor = ({ id }) => {
  return get(`${API}/monitors/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getMonitorsMini = () => {
  return get(`${API}/monitors?page_size=2000`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getMonitorConfigTable = fields => {
  const { id, page, pageSize, ordering, search, groupBy, display_fields, data_type } = fields;

  let url = "";

  if (groupBy?.value) {
    url += `&group_by=${groupBy?.value}`;
  }

  if (fields?.groupByValue) {
    url += `&${groupBy?.value}=${fields?.groupByValue}`;
  }

  if (id) {
    url += `&monitor_id=${id}`;
  }
  if (ordering) {
    url += `&ordering=${ordering}`;
  }

  if (display_fields?.length) {
    const t = display_fields?.join("&display_fields=");
    url += `&display_fields=${t}`;
  }

  return get(
    `${API}/monitors/data-list?page=${page + 1 ||
      1}&search=${search}&data_type=${data_type}&page_size=${pageSize}${url}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getMonitorConfigFilters = type => {
  const url = type ? `?data_type=${type}` : "";

  return get(`${API}/monitors/select-options${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

//

export const createMonitor = fields => {
  return post(`${API}/monitors`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const createMonitorRow = fields => {
  return post(`${API}/monitors/custom-datalist`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteMonitorRow = ({ id }) => {
  return remove(
    `${API}/monitors/custom-datalist/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const duplicateMonitor = fields => {
  const { id, ...rest } = fields;

  return post(`${API}/monitors/${id}/duplicate`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editMonitor = fields => {
  const { id, ...rest } = fields;
  return patch(`${API}/monitors/${id}`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteMonitor = id => {
  return remove(
    `${API}/monitors/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const addControlMonitorConfigurationTable = fields => {
  const { id, ...rest } = fields;
  return post(`${API}/monitors/${id}/add-rule`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteControlMonitorConfigurationTable = fields => {
  const { id, ...rest } = fields;
  return post(`${API}/monitors/${id}/delete-rule`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getMonitorAssetsMini = fields => {
  const { label, search, monitor_id } = fields;
  let url = "";

  if (search || label) {
    url += `&search=${search}&label=${label}`;
  }

  return get(`${API}/assets-mini?monitor_id=${monitor_id}${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getMonitorGroups = fields => {
  const { search } = fields;

  return get(`${API}/groups?search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addMonitorAsset = ({ id, data }) => {
  return post(`${API}/monitors/${id}/add-asset`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteMonitorAsset = ({ id, data }) => {
  return post(`${API}/monitors/${id}/delete-asset`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const addMonitorGroup = ({ id, data }) => {
  return post(`${API}/monitors/${id}/add-group`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteMonitorGroup = ({ id, data }) => {
  return post(`${API}/monitors/${id}/delete-group`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getMonitorHistory = fields => {
  const { id, page, pageSize, ordering, search } = fields;

  return get(
    `${API}/monitor-history?monitor_id=${id}&page=${page + 1 ||
      1}&page_size=${pageSize}&ordering=${ordering}&search=${search}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getMonitorHistoryDatalist = ({ id }) => {
  return get(`${API}/monitor-history/${id}/datalist`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getMonitorDatalist = ({ id }) => {
  return get(`${API}/monitors/${id}/conditions`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
