import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Title, Modal, Input } from "components/simple";
import { changePassword, closeChangePasswordModal } from "store/entities/users/actions";
import { createErrorMessageSelector } from "store/entities/error/selector";

import styles from "./styles.module.scss";

const ResetPasswordModal = () => {
  const open = useSelector(st => st.users.resetPasswordModal);
  const err = useSelector(state => createErrorMessageSelector([changePassword.type])(state));
  const [errorMessage, setErrorMessage] = useState(null);

  const [state, setState] = useState({
    old_password: "",
    password1: "",
    password2: ""
  });
  const dispatch = useDispatch();

  const onSave = () => {
    dispatch(
      changePassword({
        old_password: state?.old_password,
        new_password: state?.password1
      })
    );
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (state.password1 !== state.password2) {
      setErrorMessage("this field is required");
      return;
    }
    onSave();
  };

  const handleChange = (val, key) => {
    setState({ ...state, [key]: val?.trim() });
  };

  const close = () => {
    dispatch(closeChangePasswordModal());
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Change password</Title>
      <div className={styles.content}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            type="password"
            placeholder="Old password"
            label="Old password"
            value={state?.old_password}
            onChange={val => handleChange(val.target.value, "old_password")}
            className={styles.input}
            containerClassName={styles.inputContainer}
          />
          <Input
            type="password"
            placeholder="New password"
            label="New password"
            value={state.password1}
            invalid={errorMessage || err}
            onChange={val => handleChange(val.target.value, "password1")}
            className={styles.input}
            containerClassName={styles.inputContainer}
          />
          <Input
            type="password"
            placeholder="Confirm new password"
            label="Confirm new password"
            value={state.password2}
            invalid={errorMessage || err}
            onChange={val => handleChange(val.target.value, "password2")}
            className={styles.input}
            containerClassName={styles.inputContainer}
          />
          <div className={styles.modalFooter}>
            <Button onClick={close} variant="outline" className={styles.modalButton}>
              Back
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={
                state.password1 !== state.password2 ||
                !state.password1.length ||
                !state.password2.length ||
                !state.old_password.length
              }
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;
