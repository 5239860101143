import React, { useMemo } from "react";
import { Divider, Switch } from "@mui/material";

import styles from "./styles.module.scss";
import { DatePicker, Select } from "components/simple";
import { TimeSelect } from "screens/Targets/CreateTargetModal/TimeSelect";
import { WeekDaySelect } from "screens/Targets/CreateTargetModal/WeekDaySelect";
import { useSelector } from "react-redux";

const frequencyOptions = [
  {
    value: "daily",
    label: "Daily",
    entity: "Day"
  },
  {
    value: "weekly",
    label: "Weekly",
    entity: "Week"
  },
  {
    value: "monthly",
    label: "Monthly",
    entity: "Month"
  },
  {
    value: "yearly",
    label: "Yearly",
    entity: "Year"
  }
];

const SoarcastSchedule = ({ schedule, setSchedule }) => {
  const { scheduleData } = useSelector(state => state.targets);

  const repeatOptions = useMemo(() => {
    if (!scheduleData || !scheduleData.repeat_every || !schedule?.frequency?.value) {
      return [];
    }

    return scheduleData.repeat_every.map(item => ({
      value: item,
      label: item === 1 ? schedule?.frequency?.entity : `${item} ${schedule?.frequency?.entity}s`
    }));
  }, [scheduleData, schedule?.frequency?.value]);

  const timezoneOptions = useMemo(() => {
    if (!scheduleData || !scheduleData.timezones) {
      return [];
    }

    return scheduleData.timezones.map(item => ({ value: item, label: item }));
  }, [scheduleData]);

  const repeatByOptions = useMemo(() => {
    const options = Array.from({ length: 28 }, (_, i) => ({ value: i + 1, label: i + 1 }));
    return options;
  }, []);

  const scheduleToggle = () => {
    setSchedule(!schedule.isEnabled, "isEnabled");
  };

  const writeSummary = () => {
    if (!schedule.date || !schedule.frequency || !schedule.repeatEvery || !schedule.start) return;
    const { date, frequency, repeatEvery, start } = schedule;

    const freq = ["", "", "nd", "rd", "th", "th"];

    const summaryFrequency = `${repeatEvery.value !== 1 ? repeatEvery.value : ""}${
      freq[repeatEvery.value]
    } ${frequency.entity}`;

    return (
      <>
        <div className={styles.summaryTitle}>Summary</div>
        <div className={styles.summary}>
          {`Repeats every ${summaryFrequency} at ${start}, starting on ${date}`}
        </div>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.switchBoxHeader}>
        <Switch checked={schedule.isEnabled} onChange={scheduleToggle} />
        <div className={styles.switchBoxLabel}>Schedule</div>
      </div>
      {schedule.isEnabled && (
        <div className={styles.scheduleContainer}>
          <div className={styles.inputsBlock}>
            <Select
              containerClass={styles.blockInput}
              label="Frequency"
              name="frequency"
              options={frequencyOptions}
              value={schedule?.frequency}
              onChange={setSchedule}
              required
            />
            <DatePicker
              className={styles.blockInput}
              inputClassname={styles.dateInput}
              label="Date"
              value={schedule?.date}
              onChange={val => setSchedule(val.toLocaleDateString(), "date")}
              disablePast
            />
            <Select
              containerClass={styles.blockInput}
              label="Timezone"
              name="timezone"
              options={timezoneOptions}
              value={schedule?.timezone}
              onChange={setSchedule}
            />
            <TimeSelect
              className={styles.blockInput}
              label="Start"
              value={schedule?.start}
              onChange={val => setSchedule(val, "start")}
            />
            <Select
              containerClass={styles.blockInput}
              label="Repeat every"
              name="repeatEvery"
              options={repeatOptions}
              value={schedule?.repeatEvery}
              onChange={setSchedule}
            />
            {schedule?.frequency?.value === "monthly" && (
              <Select
                containerClass={styles.blockInput}
                label="Repeat by day of month"
                name="repeatBy"
                options={repeatByOptions}
                value={schedule?.repeatBy}
                onChange={setSchedule}
              />
            )}
          </div>
          <div className={styles.weekDayContainer}>
            {schedule?.frequency?.value === "weekly" && (
              <WeekDaySelect
                label="Repeat on"
                value={schedule?.repeatOn || []}
                onValueChange={val => setSchedule(val, "repeatOn")}
              />
            )}
          </div>
          {writeSummary()}
          <Divider />
        </div>
      )}
    </div>
  );
};

export default SoarcastSchedule;
