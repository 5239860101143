import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from "@mui/x-data-grid-pro";
import { Table } from "components/complex";
import {
  ActionButton,
  CustomDetailPanelToggle,
  DateRangePicker,
  Icon,
  Search,
  Select,
  SelectAsync,
  Title
} from "components/simple";
import { formatDate } from "helper/DateLib";
import React, { memo, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "router";
import { CreateGroupModal } from "screens/Vulnerabilities/CreateGroupModal";
import CreateTagModal from "screens/Vulnerabilities/CreateTagModal";
import { CreateTargetModal } from "screens/Vulnerabilities/CreateTargetModal";
import EditStatusModal from "screens/Vulnerabilities/EditStatusModal";
import { getFindingsByAsset, getTicketsConfigs } from "store/entities/assets/actions";
import { getFindingsByAssetSelector } from "store/entities/assets/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import { addNotification } from "store/entities/notifications/actions";
import {
  actionGroupModal,
  actionTagModal,
  actionTargetModal,
  exportVulnerability,
  getFindingStatuses,
  getVulnerabilityConfigsFiltersData,
  ignoreFinding
} from "store/entities/vulnerabilities/actions";
import {
  createList,
  vulnerabilityConfigsFiltersDataSelector
} from "store/entities/vulnerabilities/selectors";
import { getDynamicSelect } from "store/entities/vulnerabilities/service";

import styles from "./styles.module.scss";
import SubTable from "./SubTable";

const actionButtons = [
  "edit",
  "addToGroup",
  "addTag",
  "addToTarget",
  "createTicket",
  "export",
  "ignoreFinding"
];

export const defaultDisplayFieldsList = [
  "last_seen",
  "vulnerability__name",
  "status",
  "tickets",
  "ignored",
  "vulnerability__data_type",
  "vulnerability__exploit_available",
  "asset__title"
];

const groupByList = [
  { value: "status", label: "Status" },
  { value: "vulnerability__data_type", label: "Data Type" },
  { value: "vulnerability__name", label: "Finding" }
];

const filter_fields = [
  { label: "Risk Factor", field: "risk_factor" },
  { label: "Ignored", field: "ignored" },
  { label: "Status", field: "status" },
  { label: "Tickets", field: "has_tickets" },
  { label: "Exploit Available", field: "exploit_available" }
];

const customStyles = {
  height: 300,
  width: 1,
  "& .super-app-theme--header": {
    backgroundColor: "#fff",
    borderBottom: "1px solid #E7E6F8"
  },
  "& .super-app-theme--row": {
    backgroundColor: "#fff",
    marginBottom: "0px"
  }
};

const columns = [
  {
    headerName: "Last seen",
    field: "last_seen",
    resizable: true,
    minWidth: 120,
    renderCell: params => <div>{formatDate(params.value)}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Risk Factor",
    field: "vulnerability__origin_risk_factor",
    resizable: true,
    minWidth: 120,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Finding",
    field: "vulnerability__name",
    resizable: true,
    minWidth: 280,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Status",
    field: "status",
    resizable: true,
    minWidth: 100,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Ignored",
    field: "ignored",
    resizable: true,
    minWidth: 100,
    renderCell: params => <div>{params.value ? "True" : "False"}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Exploit Available",
    field: "vulnerability__exploit_available",
    resizable: true,
    minWidth: 120,
    renderCell: params => <div>{params.value ? "True" : "False"}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Tickets",
    field: "tickets",
    resizable: true,
    minWidth: 100,
    renderCell: params => <div>{params.value.length || 0}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Data Type",
    field: "vulnerability__data_type",
    resizable: true,
    minWidth: 120,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  }
];

const Findings = ({ detailName }) => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const { id: assetId } = useParams();
  const history = useHistory();

  const [tableState, setTableState] = useState({
    page: 0,
    pageSize: 20,
    ordering: "",
    search: "",
    groupBy: null,
    filters: {}
  });

  const [expandHeight, setExpandHeight] = useState(150);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState([]);
  const [selectionModelSubTable, setSelectionModelSubTable] = useState([]);

  const [selectionModel, setSelectionModel] = useState([]);
  const [filterByDataType, setFilterByDataType] = useState(null);
  const [showEditStatusModal, onShowEditStatusModal] = useState(null);
  const [ticketConfig, setTicketConfig] = useState(null);

  const { data_types_list } = useSelector(vulnerabilityConfigsFiltersDataSelector);
  const { count, data, ticketsConfigs: ticketConfigList } = useSelector(state =>
    getFindingsByAssetSelector(state)
  );
  const isLoading = useSelector(state => createLoadingSelector([getFindingsByAsset.type])(state));

  const goToVulnerabilitiesPage = () =>
    history.push(
      `${routes.vulnerabilities}?&asset=${JSON.stringify({
        label: detailName,
        value: assetId
      })}`
    );

  const onFilterChange = field => value => {
    setTableState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [field]: value
      }
    }));
  };

  const handleSearch = v => {
    setTableState(prevState => ({
      ...prevState,
      search: v
    }));
  };

  const setPageSize = v => {
    setTableState(prevState => ({
      ...prevState,
      pageSize: v
    }));
  };

  const onPageChange = newPage => {
    setTableState(prevState => ({
      ...prevState,
      page: newPage
    }));
  };

  // Select Group By
  const onGroupBy = v => {
    setTableState(prevState => ({
      ...prevState,
      groupBy: v,
      ordering: "",
      page: 0
    }));
    setSelectionModel([]);
  };

  const handleAction = type => {
    const selectionModelSubTableRow = selectionModelSubTable
      ?.map(item => item?.selectionModel)
      ?.flat();

    const groupByValue = tableState.groupBy?.value;
    const myMap = apiRef?.current?.getSelectedRows();
    const values = Array.from(myMap.values());
    const ids = groupByValue
      ? [...new Set([...values?.map(item => item?.group_ids).flat(), ...selectionModelSubTableRow])]
      : selectionModel;

    if (type === "createTicket" && !ticketConfig?.value) {
      dispatch(addNotification({ msg: "You need to select ticket config", type: "warning" }));
      return;
    }
    if (!selectionModel?.length) {
      dispatch(addNotification({ msg: "You must select at least one row", type: "warning" }));
      return;
    }

    switch (type) {
      case "edit":
        onShowEditStatusModal(ids);
        break;
      case "export":
        dispatch(exportVulnerability({ displayFields: defaultDisplayFieldsList, ids }));
        break;
      case "addToGroup":
        dispatch(actionGroupModal({ show: true, data: ids }));
        break;
      case "addTag":
        dispatch(actionTagModal({ show: true, data: ids }));
        break;
      case "addToTarget":
        dispatch(actionTargetModal({ show: true, data: ids }));
        break;
      case "createTicket": {
        history.push(
          `${routes.vulnerabilitiesCreateTicket}/${ticketConfig?.value}?ids=${ids.join(",")}`
        );
        break;
      }
      case "ignoreFinding":
        dispatch(ignoreFinding(ids));
        break;
      default:
        break;
    }
  };

  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];

      setTableState(prevState => ({
        ...prevState,
        ordering: sort === "asc" ? field : `-${field}`
      }));
    } else {
      setTableState(prevState => ({
        ...prevState,
        ordering: ""
      }));
    }
  };

  const request = async (search, page, item) => {
    try {
      const res = await getDynamicSelect({ field: item, search, page });
      const listOptions = createList(res?.results);
      return { options: listOptions, next: res?.next };
    } catch (e) {
      // return console.log("err", e);
    }
  };

  const loadOptions = item => async (search, prevOptions, { page }) => {
    const { options, next } = await request(search, page, item);
    const hasMore = !search ? Boolean(next) : false;
    const modifiedOptions = options.map(opt =>
      typeof opt.label === "boolean" ? { ...opt, label: opt.label.toString() } : opt
    );

    return {
      options: modifiedOptions,
      hasMore,
      additional: {
        page: page + 1
      }
    };
  };

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    newIds => {
      setDetailPanelExpandedRowIds(newIds.slice(-1));
    },
    [apiRef]
  );

  // Expend
  const onChangeSelectionModelSubTable = (v, rowId) => {
    const res = {
      id: rowId,
      selectionModel: v
    };
    let newSelection = [];

    if (selectionModelSubTable.find(item => item?.id === rowId)) {
      newSelection = selectionModelSubTable?.map(item => {
        if (item?.id === rowId) {
          return res;
        }
        return item;
      });
    } else {
      newSelection = [...selectionModelSubTable, res];
    }

    if (selectionModel.find(item => item === rowId)) {
      const resetSelectForMainTable = selectionModel?.filter(item => item !== rowId);
      setSelectionModel(resetSelectForMainTable);
    }

    setSelectionModelSubTable(newSelection);
  };

  const display_fields = useMemo(() => {
    return columns.map(item => item.field);
  }, [columns]);

  const display_field_width = useMemo(() => {
    return columns.map(item => ({ field: item.field, width: item.minWidth }));
  }, []);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => {
      return (
        <SubTable
          row={row}
          assetId={assetId}
          tableState={tableState}
          selectionModelSubTable={selectionModelSubTable}
          setExpandHeight={setExpandHeight}
          expandHeight={expandHeight}
          display_fields={display_fields}
          display_fields_width={display_field_width}
          onChangeSelectionModelSubTable={v => onChangeSelectionModelSubTable(v, row?.id)}
        />
      );
    },
    [selectionModelSubTable, expandHeight, tableState]
  );

  useEffect(() => {
    dispatch(getTicketsConfigs("finding"));
    dispatch(getFindingStatuses());
    dispatch(getVulnerabilityConfigsFiltersData());
  }, []);

  useEffect(() => {
    dispatch(
      getFindingsByAsset({
        assetId,
        data_type: filterByDataType?.value,
        ...tableState
      })
    );
  }, [assetId, tableState, filterByDataType?.value]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.filters}>
          <div className={styles.titleContainer}>
            <Title className={styles.title}>Findings</Title>
            <div className={styles.btn} onClick={goToVulnerabilitiesPage}>
              <div className={styles.iconBtn}>
                <Icon.ArrowBack color="#2B3858" />
              </div>
            </div>
          </div>
          <Search onSearch={handleSearch} value={null} />
          <div className={styles.filterRow}>
            <div className={styles.selectsGroup}>
              <Select
                containerClass={styles.select}
                onChange={setTicketConfig}
                value={ticketConfig}
                placeholder="Ticket Configuration"
                options={ticketConfigList}
                isClearable
              />
              <Select
                containerClass={styles.select}
                onChange={onGroupBy}
                value={tableState.groupBy}
                placeholder="Group by"
                options={groupByList}
                isClearable
              />
              <Select
                containerClass={styles.select}
                onChange={setFilterByDataType}
                value={filterByDataType}
                placeholder="Data Type"
                options={data_types_list}
                isClearable
              />
              {filter_fields.map(item => (
                <SelectAsync
                  key={item.field}
                  loadOptionsOnMenuOpen
                  onChange={onFilterChange(item.field)}
                  value={tableState.filters[item.field]}
                  placeholder={item.label}
                  isClearable
                  cacheOptions
                  loadOptions={loadOptions(item.field)}
                  onInputChange={() => null}
                  additional={{
                    page: 1
                  }}
                />
              ))}
              <DateRangePicker
                placeholder="Last seen"
                onChange={onFilterChange("last_seen")}
                value={tableState.filters.last_seen}
              />
            </div>
            <div className={styles.buttonGroup}>
              {actionButtons.map(btn => (
                <ActionButton
                  key={btn}
                  type={btn}
                  onClick={handleAction}
                  className={styles.actionButton}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <Table
            apiRef={apiRef}
            disableVirtualization
            data={isLoading ? [] : data}
            columns={[
              {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                headerName: "Group ids",
                field: "__detail_panel_toggle__",
                width: 120,
                sortable: true,
                hide: !tableState.groupBy?.value,
                renderCell: params => {
                  return (
                    <span>
                      {params?.row?.group_ids?.length || 0}
                      {params?.row?.group_ids?.length > 1 ? (
                        <CustomDetailPanelToggle {...params} />
                      ) : null}
                    </span>
                  );
                }
              },
              ...columns
            ]}
            loading={isLoading}
            onSelectionModelChange={newSelectionModel => {
              if (tableState?.groupBy?.value) {
                const current = newSelectionModel.slice(-1)[0];
                const res = data?.find(item => item?.id === current);
                if (current) {
                  const groupIds = res?.group_ids || [];
                  if (selectionModelSubTable?.find(item => item.id === current)) {
                    const rr = selectionModelSubTable?.map(item => {
                      if (item.id === current) {
                        if (newSelectionModel?.find(i => i === item?.id)) {
                          return { ...item, selectionModel: groupIds };
                        }
                        return;
                      }
                      if (newSelectionModel?.find(i => i !== item?.id)) {
                        return { ...item, selectionModel: [] };
                      }
                      return item;
                    });
                    setSelectionModelSubTable(rr);
                  } else {
                    setSelectionModelSubTable([
                      ...selectionModelSubTable,
                      { id: current, selectionModel: groupIds }
                    ]);
                  }
                } else {
                  setSelectionModelSubTable([]);
                }
              }

              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            onSortModelChange={model => handleSort(model)}
            page={tableState.page || 0}
            pageSize={tableState.pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowCount={count || 0}
            paginationMode="server"
            onPageChange={onPageChange}
            getDetailPanelHeight={() => expandHeight}
            getDetailPanelContent={getDetailPanelContent}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            customStyles={customStyles}
          />
        </div>
      </div>
      <CreateTargetModal />
      <CreateGroupModal />
      <CreateTagModal />
      <EditStatusModal
        isOpen={Boolean(showEditStatusModal)}
        data={showEditStatusModal}
        onClose={() => onShowEditStatusModal(null)}
      />
    </div>
  );
};

export default memo(Findings);
