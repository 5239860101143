import React from "react";
import Chip from "@mui/material/Chip";
import { FormLabel } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import styles from "./styles.module.scss";

const MultyInput = ({ containerClassName, className, label, required, values, onChange }) => {
  const customStyle = {
    "& .MuiFormControl-root": {
      background: "#fff",
      borderRadius: "10px"
    },
    "& .MuiFilledInput-root": {
      padding: 0,
      background: "#fff",
      borderRadius: "10px",
      color: "#2b3858",
      "&:hover": {
        background: "#fff"
      },

      "& .MuiFilledInput-input": {
        width: "auto",
        color: "#2b3858"
      }
    }
  };

  return (
    <div className={`${styles.container} ${containerClassName} ${required ? styles.required : ""}`}>
      {label && (
        <div className={styles.labelCont}>
          <FormLabel className={styles.label}>{label}</FormLabel>
        </div>
      )}
      <Autocomplete
        multiple
        sx={customStyle}
        id="tags-filled"
        options={[]}
        onChange={(event, value) => onChange(value)} // prints the selected value
        className={`${styles.input} ${className}`}
        defaultValue={values}
        freeSolo
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ));
        }}
        renderInput={params => <TextField variant="filled" {...params} />}
      />
    </div>
  );
};

export default MultyInput;
