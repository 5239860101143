import React from "react";

import { ReactComponent as ExpandIcon } from "assets/images/ExpandArrow.svg";

import styles from "./styles.module.scss";

const ExpandBtn = ({ label, expanded, onClick }) => {
  const expandIconStyle = {
    transform: expanded ? "rotate(180deg)" : "none",
    transition: "transform 0.5s"
  };

  return (
    <div className={styles.expandBtnBody} onClick={onClick}>
      <div className={styles.expandBtnLabel}>{label}</div>
      <ExpandIcon style={expandIconStyle} />
    </div>
  );
};

const ExpandBox = ({ className, label, expanded, onToggleExpand, children }) => {
  const expandContStyle = {
    maxHeight: expanded ? "2000px" : "0"
  };

  return (
    <div className={className}>
      <ExpandBtn label={label} expanded={expanded} onClick={onToggleExpand} />
      <div className={styles.expandContainer} style={expandContStyle}>
        <div className={styles.contentBody}>{children}</div>
      </div>
    </div>
  );
};

export default ExpandBox;
