export const SET_CLOUD_PARAMS = "SET_CLOUD_PARAMS";

export const GET_CLOUD_ACCOUNTS = "GET_CLOUD_ACCOUNTS";
export const GET_CLOUD_ACCOUNTS_SUCCESS = "GET_CLOUD_ACCOUNTS_SUCCESS";
export const GET_CLOUD_ACCOUNTS_FAILED = "GET_CLOUD_ACCOUNTS_FAILED";

export const GET_AWS_REGIONS = "GET_AWS_REGIONS";
export const GET_AWS_REGIONS_SUCCESS = "GET_AWS_REGIONS_SUCCESS";
export const GET_AWS_REGIONS_FAILED = "GET_AWS_REGIONS_FAILED";

export const ADD_AZURE_ACCOUNT = "ADD_AZURE_ACCOUNT";
export const ADD_AZURE_ACCOUNT_SUCCESS = "ADD_AZURE_ACCOUNT_SUCCESS";
export const ADD_AZURE_ACCOUNT_FAILED = "ADD_AZURE_ACCOUNT_FAILED";

export const GET_AZURE_ACCOUNT = "GET_AZURE_ACCOUNT_REQUEST";
export const GET_AZURE_ACCOUNT_SUCCESS = "GET_AZURE_ACCOUNT_SUCCESS";
export const GET_AZURE_ACCOUNT_FAILED = "GET_AZURE_ACCOUNT_FAILED";

export const ADD_GOOGLE_CLOUD_ACCOUNT = "ADD_GOOGLE_CLOUD_ACCOUNT";
export const ADD_GOOGLE_CLOUD_ACCOUNT_SUCCESS = "ADD_GOOGLE_CLOUD_ACCOUNT_SUCCESS";
export const ADD_GOOGLE_CLOUD_ACCOUNT_FAILED = "ADD_GOOGLE_CLOUD_ACCOUNT_FAILED";

export const GET_GCP_ACCOUNT = "GET_GCP_ACCOUNT_REQUEST";
export const GET_GCP_ACCOUNT_SUCCESS = "GET_GCP_ACCOUNT_SUCCESS";
export const GET_GCP_ACCOUNT_FAILED = "GET_GCP_ACCOUNT_FAILED";

export const ADD_AWS_CLOUD_MANUAL_SETUP = "ADD_AWS_CLOUD_MANUAL_SETUP";
export const ADD_AWS_CLOUD_MANUAL_SETUP_SUCCESS = "ADD_AWS_CLOUD_MANUAL_SETUP_SUCCESS";
export const ADD_AWS_CLOUD_MANUAL_SETUP_FAILED = "ADD_AWS_CLOUD_MANUAL_SETUP_FAILED";

export const GET_AWS_MANUAL_ACCOUNT = "GET_AWS_MANUAL_ACCOUNT_REQUEST";
export const GET_AWS_MANUAL_ACCOUNT_SUCCESS = "GET_AWS_MANUAL_ACCOUNT_SUCCESS";
export const GET_AWS_MANUAL_ACCOUNT_FAILED = "GET_AWS_MANUAL_ACCOUNT_FAILED";

export const ADD_AWS_CLOUD_ACCESS_KEY = "ADD_AWS_CLOUD_ACCESS_KEY";
export const ADD_AWS_CLOUD_ACCESS_KEY_SUCCESS = "ADD_AWS_CLOUD_ACCESS_KEY_SUCCESS";
export const ADD_AWS_CLOUD_ACCESS_KEY_FAILED = "ADD_AWS_CLOUD_ACCESS_KEY_FAILED";

export const GET_AWS_ACCESS_ACCOUNT = "GET_AWS_ACCESS_ACCOUNT_REQUEST";
export const GET_AWS_ACCESS_ACCOUNT_SUCCESS = "GET_AWS_ACCESS_ACCOUNT_SUCCESS";
export const GET_AWS_ACCESS_ACCOUNT_FAILED = "GET_AWS_ACCESS_ACCOUNT_FAILED";

export const CHANGE_ENABLED_STATUS = "CHANGE_ENABLED_STATUS";
export const CHANGE_ENABLED_STATUS_SUCCESS = "CHANGE_ENABLED_STATUS_SUCCESS";
export const CHANGE_ENABLED_STATUS_FAILED = "CHANGE_ENABLED_STATUS_FAILED";

export const RUN_ACCOUNT_SCAN = "RUN_ACCOUNT_SCAN";
export const RUN_ACCOUNT_SCAN_SUCCESS = "RUN_ACCOUNT_SCAN_SUCCESS";
export const RUN_ACCOUNT_SCAN_FAILED = "RUN_ACCOUNT_SCAN_FAILED";

export const DELETE_CLOUD_ACCOUNT = "DELETE_CLOUD_ACCOUNT";
export const DELETE_CLOUD_ACCOUNT_SUCCESS = "DELETE_CLOUD_ACCOUNT_SUCCESS";
export const DELETE_CLOUD_ACCOUNT_FAILED = "DELETE_CLOUD_ACCOUNT_FAILED";

export const UPDATE_CONNECTION = "UPDATE_CONNECTION";
export const UPDATE_CONNECTION_SUCCESS = "UPDATE_CONNECTION_SUCCESS";
export const UPDATE_CONNECTION_FAILED = "UPDATE_CONNECTION_FAILED";

export const ACTION_STATUS_MODAL = "ACTION_STATUS_MODAL";

export const ACTION_DELETE_MODAL = "ACTION_DELETE_MODAL";
