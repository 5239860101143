import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activate } from "store/entities/users/actions";
import { validateToken } from "store/entities/users/service";

import { createErrorMessageSelector } from "store/entities/error/selector";

import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { routes } from "router/router";

import { styled, Link } from "@mui/material";

import { Button, Input, Loader } from "components/simple";

import { ReactComponent as DivyReactangleSvg } from "assets/images/Reactangle.svg";
import { ReactComponent as DivyLogo } from "assets/images/DivyRedLetterLogo.svg";

import Icon from "assets/images";

export const DivyReactangleImg = styled(DivyReactangleSvg)(({ theme }) => {
  return {
    position: "absolute",
    height: "100%",
    width: "100%",
    right: 0,
    top: 0,
    "& rect": {
      fill: theme.palette.primary.main
    }
  };
});

export const DivyLogoImg = styled(DivyLogo)(({ theme }) => {
  return {
    position: "absolute",

    "& g rect": {
      fill: theme.palette.primary.main
    }
  };
});

export const FormLink = styled(Link)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
  textAlign: "right"
}));

const ResetPassword = ({ history }) => {
  const dispatch = useDispatch();
  const err = useSelector(state => createErrorMessageSelector([activate.type])(state));

  const [isLoading, setLoading] = useState(true);
  const [validTokent, setValidTokent] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const params = useParams();

  const uid = params?.uid;
  const user_token = params?.user_token;

  const [state, setState] = useState({
    password1: "",
    password2: ""
  });

  useEffect(() => {
    const valid = () => {
      validateToken({ uid, token: user_token }).then(res => {
        const response = res;
        const responsePromise = res.json();
        setLoading(false);
        responsePromise.then(data => {
          if (response.status === 400) {
            setValidTokent(data);
          } else {
            setValidTokent(false);
          }
        });
      });
    };
    valid();
  }, [uid, user_token]);

  const handleSubmit = e => {
    e.preventDefault();

    if (state.password1 !== state.password2) {
      setErrorMessage("this field is required");
      return;
    }

    const { password1 } = state;

    dispatch(activate({ uid, token: user_token, password: password1 }));
  };

  const handleChange = (val, type) => {
    setErrorMessage(null);
    setState({
      ...state,
      [type]: val?.trim()
    });
  };

  if (!isLoading && validTokent) {
    return (
      <div className={styles.container}>
        <div className={styles.error}>
          <div className={styles.title}>Create new password</div>
          <div className={styles.description}>{validTokent}</div>
          <FormLink
            onClick={() => {
              history.push(routes.forgotPassword);
            }}
          >
            Forgot password?
          </FormLink>
        </div>
        <div className={styles.bg}>
          <div className={styles.cards}>
            <div className={`${styles.logo}`}>
              <DivyLogoImg />
            </div>
            <img src={Icon.previewCards} alt="" />
          </div>
          <DivyReactangleImg />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={styles.title}>Create new password</div>
            <div className={styles.description}>
              Your new password must be different from previously used password
            </div>

            <div className={styles.content}>
              <div>
                <Input
                  label="New Password"
                  value={state.password1}
                  type="password"
                  onChange={val => handleChange(val.target.value, "password1")}
                  invalid={errorMessage || err}
                  containerClassName={styles.input}
                  className={`${styles.inputcode}`}
                />
              </div>
              <div>
                <Input
                  label="Confirm Password"
                  value={state.password2}
                  type="password"
                  onChange={val => handleChange(val.target.value, "password2")}
                  invalid={errorMessage || err}
                  containerClassName={styles.input}
                  className={`${styles.inputcode}`}
                />
              </div>
              <Button
                className={styles.button}
                type="submit"
                disabled={
                  state.password1 !== state.password2 ||
                  !state.password1.length ||
                  !state.password2.length
                }
              >
                Verify
              </Button>
            </div>
          </>
        )}
      </form>

      <div className={styles.bg}>
        <div className={styles.cards}>
          <div className={`${styles.logo}`}>
            <DivyLogoImg />
          </div>
          <img src={Icon.previewCards} alt="" />
        </div>
        <DivyReactangleImg />
      </div>
    </div>
  );
};

export default ResetPassword;
