/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { onChangeUrlParams, useQuery } from "helper/history";
import { routes } from "router";
import { baselineColumns } from "./tableColumns";

import { getTableSelector } from "store/entities/baseline/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import { labelsMiniSelector } from "store/entities/labels/selectors";

import { getLabelsMini } from "store/entities/labels/actions";
import { getBaselines, setBaselineParams, deleteBaseline } from "store/entities/baseline/actions";
import { addNotification } from "store/entities/notifications/actions";

import { Table } from "components/complex";
import { ContentContainer, Search, Button, Icon, Select } from "components/simple";

import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";

const filterList = [
  { label: "Pass", value: "pass_baselines", key: "PASSED" },
  { label: "Fail", value: "fail_baselines", key: "FAILED" }
];

const Baselines = ({ history }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = React.useRef(selectionModel);

  const query = useQuery();
  const queryPage = query.get("page");
  const queryFilter = query.get("filter");
  const queryLabel = query.get("label_id");
  const queryAssetId = query.get("asset_id");

  const dispatch = useDispatch();

  const selectTenant = useSelector(state => state.users.selectTenant);
  const { page, pageSize, filter, search, label } = useSelector(state => state.baseline);
  const { data, total } = useSelector(state => getTableSelector(state.baseline.baselines));
  const labels = useSelector(labelsMiniSelector);
  const isLoading = useSelector(state => createLoadingSelector([getBaselines.type])(state));

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  const { state } = useLocation();

  // get default request
  const getRequest = () => {
    const flt = filterList?.find(item => item.value === queryFilter) || "";
    const lbl = labels?.find(item => item.value === queryLabel) || "";
    dispatch(
      setBaselineParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: "",
        label: state?.value ? state : lbl || "",
        filter: flt,
        asset_id: queryAssetId || ""
      })
    );
  };

  useEffect(() => {
    dispatch(getLabelsMini());
  }, [selectTenant]);

  useEffect(() => {
    if (labels.length > 0) getRequest();
  }, [labels, selectTenant]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setBaselineParams({
        page: 0,
        search: v
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setBaselineParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setBaselineParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setBaselineParams({
          ordering: ""
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setBaselineParams({
        pageSize: v
      })
    );
  };

  // Next page
  const onPageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setBaselineParams({
          page: newPage
        })
      );
    }
  };

  const onCellClick = v => {
    const { field, id } = v;
    if (field === "failed_assets") {
      history.push(`${routes.failedBaseline}/${id}`);
    } else {
      history.push(`${routes.baselineConfiguration}/${id}`);
    }
  };

  const addBaseline = () => {
    history.push(`${routes.createBaseline}`);
  };

  const deleteBaselineHandler = () => {
    dispatch(deleteBaseline({ ids: selectionModel }));
  };

  const compareBaseline = () => {
    if (selectionModel?.length <= 1) {
      dispatch(addNotification({ msg: "You must select at least 2 rows", type: "warning" }));
      return;
    }
    history.push(`${routes.compareBaselines}/${selectionModel}`);
  };

  const onChangeFilter = v => {
    onChangeUrlParams("filter", v?.value || "");
    dispatch(
      setBaselineParams({
        filter: v
      })
    );
  };

  const onChangeLabel = v => {
    onChangeUrlParams("label_id", v?.value || "");
    dispatch(
      setBaselineParams({
        label: v
      })
    );
  };

  return (
    <ContentContainer className={styles.container}>
      <div className={styles.controls}>
        <Search onSearch={handleSearch} value={search} />
        <div className={styles.selectOptions}>
          <Select
            placeholder="Filter by Pass or Fail"
            onChange={v => onChangeFilter(v, "filter")}
            value={filter}
            options={filterList}
            containerClass={styles.select}
            isClearable
          />
          <Select
            placeholder="Filter by Label"
            onChange={v => onChangeLabel(v, "label_id")}
            value={label}
            options={labels}
            containerClass={styles.select}
            isClearable
          />
        </div>
        <div className={styles.btns}>
          <Button className={`${styles.btn} ${styles.create}`} onClick={compareBaseline}>
            Compare baselines
            <Icon.Arrows color="#ffff" />
          </Button>
          <Button icon="plus" className={styles.btn} onClick={addBaseline}>
            Add baseline
          </Button>
          <Button
            className={`${styles.btn} ${styles.delete}`}
            onClick={deleteBaselineHandler}
            disabled={!selectionModel?.length}
          >
            Delete
            <Icon.Delete color="#ffff" />
          </Button>
        </div>
      </div>
      <Table
        data={isLoading ? [] : data}
        columns={baselineColumns}
        loading={isLoading}
        onSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel);
        }}
        page={page || 0}
        selectionModel={selectionModel}
        onSortModelChange={model => handleSort(model)}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowCount={total}
        paginationMode="server"
        onPageChange={onPageChange}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
        onCellClick={e => onCellClick(e)}
      />
    </ContentContainer>
  );
};

Baselines.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Baselines;
