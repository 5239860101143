/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getReport } from "store/entities/reports/actions";

import { reportsHistorySelector } from "store/entities/reports/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import { Table } from "components/complex";
import { ContentContainer, Search, Title } from "components/simple";

import styles from "./styles.module.scss";
import { formatDate } from "helper/DateLib";

export const API = `${process.env.REACT_APP_BACKEND_URL}`;

const History = ({ id }) => {
  const { data, columns, name } = useSelector(reportsHistorySelector);
  const [historyList, setHistoryList] = useState([]);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getReport.type])(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getReport(id));
    }
  }, [selectTenant, id]);

  useEffect(() => {
    setHistoryList(data);
  }, [data]);

  // Search

  const handleSearch = text => {
    if (text === "") setHistoryList(data);
    const newList = data.filter(({ email, file, date }) => {
      const searchStr = `${email} ${formatDate(date)} ${file}`;
      return searchStr.toLowerCase().includes(text.toLowerCase());
    });
    setHistoryList(newList);
  };

  const onCellClick = v => {
    const {
      field,
      row: { path }
    } = v;
    if (field === "view") {
      window.open(`${API}${path}`);
    }
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      borderRadius: "10px",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important"
      }
    }
  };

  const tableConfig = {
    checkboxVisible: false,
    paginationVisible: false,
    hideFooter: true,
    sortingMode: false
  };

  return (
    <ContentContainer className={styles.container}>
      <Title className={styles.title}>{name}</Title>
      <Search onSearch={handleSearch} />
      <Table
        data={isLoading ? [] : historyList}
        columns={columns}
        loading={isLoading}
        paginationMode="server"
        onCellClick={e => onCellClick(e)}
        customStyles={customStyles}
        {...tableConfig}
      />
    </ContentContainer>
  );
};

History.propTypes = {};

export default History;
