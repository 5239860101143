/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, Loader } from "components/simple";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "helper/history";

import {
  getMonitor,
  getMonitorConfigFilters,
  clearMonitorConfigParams,
  duplicateMonitor,
  editMonitor,
  createMonitor
} from "store/entities/monitors/actions";

import { Data, AssignedGroups, AssignedHosts, History } from "./Tabs";
import Panel from "./Panel";

import styles from "./styles.module.scss";

const MonitorsConfiguration = () => {
  const params = useParams();
  const query = useQuery();
  const type = query.get("data_type");
  const display_fields = query.get("display_fields");

  const dispatch = useDispatch();
  const selectTenant = useSelector(state => state.users.selectTenant);
  const id = params?.id;
  const [activeTab, setActiveTab] = useState(0);

  const isLoading = useSelector(state => {
    return state.monitors.isLoading;
  });

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (id) {
      dispatch(getMonitor({ id }));
    } else {
      dispatch(getMonitorConfigFilters(type));
    }
    return () => dispatch(clearMonitorConfigParams());
  }, [selectTenant, id]);

  const tabs = [
    {
      id: 0,
      label: `Data`,
      content: <Data id={id} data_type={type} displayFields={display_fields} />
    },
    ...(id
      ? [
          {
            id: 1,
            label: `Assigned Hosts`,
            content: <AssignedHosts id={id} />
          },
          {
            id: 2,
            label: `Assigned Groups`,
            content: <AssignedGroups id={id} />
          },
          {
            id: 3,
            label: `History`,
            content: <History id={id} />
          }
        ]
      : [])
  ];

  const onSave = data => {
    if (id) {
      dispatch(editMonitor({ ...data, id }));
    } else {
      dispatch(createMonitor({ ...data, data_type: type }));
    }
  };

  const onDublicate = data => {
    dispatch(duplicateMonitor(data));
  };

  return (
    <div className={styles.container}>
      <Panel onSave={onSave} onDublicate={onDublicate} type={type} id={id} />
      {isLoading ? <Loader /> : <Tabs tabs={tabs} value={activeTab} handleChange={handleChange} />}
    </div>
  );
};

MonitorsConfiguration.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default MonitorsConfiguration;
