import { Button, Select } from "components/simple";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { labelsMiniSelector } from "store/entities/labels/selectors";
import {
  getExpectedVariables,
  runPlaybookConfig,
  savePlaybookConfig,
  updatePlaybookConfig
} from "store/entities/targets/actions";
import {
  getMinionsSelector,
  getPlaybooksSelector,
  getVariableOptionsSelector,
  getVariablesSelector
} from "store/entities/targets/selectors";
import SoarcastSchedule from "./SoarcastSchedule";

import styles from "./styles.module.scss";

const SoarcastParams = ({ tagParams, tagId, setTagParams }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const playbooksOptions = useSelector(getPlaybooksSelector);
  const minionsOptions = useSelector(getMinionsSelector);
  const labelsOptions = useSelector(labelsMiniSelector);
  const parameters = useSelector(getVariablesSelector);
  const variableOptions = useSelector(getVariableOptionsSelector);

  const changeHandler = (value, name) => {
    setTagParams(prevParams => ({
      ...prevParams,
      [tagId]: {
        ...tagParams,
        [name]: value
      }
    }));
  };

  const variablesHandler = paramId => value => {
    setTagParams(prevParams => ({
      ...prevParams,
      [tagId]: {
        ...tagParams,
        parameters: {
          ...tagParams.parameters,
          [paramId]: value
        }
      }
    }));
  };

  const setSchedule = (value, name) => {
    if (name === "frequency") {
      setTagParams(prevParams => ({
        ...prevParams,
        [tagId]: {
          ...tagParams,
          schedule: {
            isEnabled: true,
            [name]: value
          }
        }
      }));
      return;
    }

    setTagParams(prevParams => ({
      ...prevParams,
      [tagId]: {
        ...tagParams,
        schedule: {
          ...tagParams.schedule,
          [name]: value
        }
      }
    }));
  };

  const saveConfig = e => {
    e.preventDefault();
    if (tagParams.configId) {
      dispatch(updatePlaybookConfig(tagParams));
      return;
    }
    dispatch(savePlaybookConfig({ tagParams, tagId, target: params.id }));
  };

  const onRunHandler = () => {
    dispatch(runPlaybookConfig(tagParams.configId));
  };

  useEffect(() => {
    if (tagParams?.playbook?.value) {
      dispatch(getExpectedVariables({ playbook_id: tagParams?.playbook?.value }));
    }
  }, [tagParams?.playbook?.value]);

  return (
    <form onSubmit={saveConfig}>
      <SoarcastSchedule schedule={tagParams.schedule} setSchedule={setSchedule} />
      <div className={styles.soarcastTitle}>SoarCast</div>
      <div className={styles.selectFieldsContainer}>
        <Select
          placeholder="Playbook"
          name="playbook"
          onChange={changeHandler}
          value={tagParams.playbook}
          options={playbooksOptions}
          containerClass={styles.select}
          isClearable
          required
        />
        <Select
          placeholder="Minion"
          name="minion"
          onChange={changeHandler}
          value={tagParams.minion}
          options={minionsOptions}
          containerClass={styles.select}
          isClearable
          required
        />
        <Select
          placeholder="Label"
          name="label"
          onChange={changeHandler}
          value={tagParams.label}
          options={labelsOptions}
          containerClass={styles.select}
          isClearable
          required
        />
      </div>
      {parameters.length > 0 && (
        <>
          <div className={styles.sectionTitle}>Parameters</div>
          <div className={styles.parametersContainer}>
            {parameters.map(parameter => (
              <Select
                key={parameter.id}
                placeholder={parameter.full_name}
                options={variableOptions}
                value={tagParams?.parameters?.[parameter.id]}
                onChange={variablesHandler(parameter.id)}
                containerClass={styles.variableInput}
                required
              />
            ))}
          </div>
        </>
      )}
      <div className={styles.buttonsGroup}>
        <Button className={styles.buttonsGroupItem} type="submit">
          Save
        </Button>
        {tagParams.configId && (
          <Button className={styles.buttonsGroupItem} variant="outline" onClick={onRunHandler}>
            Run
          </Button>
        )}
      </div>
    </form>
  );
};

export default SoarcastParams;
