import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { removeNotification } from "store/entities/notifications/actions";
import { toastsSelector } from "store/entities/notifications/selectors";

import { useSelector, useDispatch } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = () => {
  const toasts = useSelector(toastsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toasts?.length > 0) {
      setTimeout(() => {
        dispatch(removeNotification(toasts[toasts?.length - 1].id));
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toasts]);

  const open = toasts?.length > 0;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(removeNotification(toasts[toasts?.length - 1].id));
  };
  // error success

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {toasts.map(item => (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ zIndex: 100000 }}
          key={item.id}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={item?.type} sx={{ width: "100%" }}>
            {item.msg}
          </Alert>
        </Snackbar>
      ))}
    </Stack>
  );
};

Notification.propTypes = {};

Notification.defaultProps = {};

export default Notification;
