import React from "react";

import { Input, Icon, ActionButton } from "components/simple";

import styles from "./styles.module.scss";

const AddBtn = ({ label, onClick }) => (
  <div className={styles.addBtnBody} onClick={onClick}>
    <div className={styles.addBtnIcon}>
      <Icon.Plus />
    </div>
    <div className={styles.addBtnLabel}>{label}</div>
  </div>
);

const KeyValueEdit = ({ className, keyValList, keyValCtrl }) => {
  let bodyClassName = styles.keyValueEditBody;
  if (className) {
    bodyClassName += ` ${className}`;
  }

  const handleKeyEdit = (id, key) => {
    if (keyValCtrl) {
      keyValCtrl.setItemKey(id, key);
    }
  };

  const handleValueEdit = (id, value) => {
    if (keyValCtrl) {
      keyValCtrl.setItemValue(id, value);
    }
  };

  const handleAdd = () => {
    if (keyValCtrl) {
      keyValCtrl.addItem();
    }
  };

  const handleDelete = id => {
    if (keyValCtrl) {
      keyValCtrl.removeItem(id);
    }
  };
  return (
    <div className={bodyClassName}>
      {keyValList &&
        keyValList.map((item, idx) => (
          <div key={`KeyVal_${idx}`} className={styles.pairBody}>
            <Input
              containerClassName={styles.pairInput}
              label={idx === 0 ? "Key" : undefined}
              value={item.key}
              onChange={v => handleKeyEdit(idx, v.target.value)}
            />
            <Input
              containerClassName={styles.pairInput}
              label={idx === 0 ? "Value" : undefined}
              value={item.value}
              onChange={v => handleValueEdit(idx, v.target.value)}
            />
            <ActionButton type="delete" onClick={() => handleDelete(idx)} />
          </div>
        ))}
      <div>
        <AddBtn label="Add input" onClick={handleAdd} />
      </div>
    </div>
  );
};

export default KeyValueEdit;
