import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Title,
  Input,
  Textarea,
  Select,
  Tags,
  Button,
  Loader,
  Icon,
  ActionButton,
  MultyInput
} from "components/simple";
import { KeyValueEdit } from "../CreateTargetModal/KeyValueEdit";
import { ExpandBox } from "../CreateTargetModal/ExpandBox";
import { DeleteTargetModal } from "../DeleteTargetModal";
import Assets from "./Assets";
import { createLoadingSelector } from "store/entities/loading/selector";

import { useDynamicFieldsDetails } from "../CreateTargetModal/hooks/DynamicFieldsHook";
import { useSchedule } from "../CreateTargetModal/hooks/ScheduleHook";
import { useKeyValueList } from "../CreateTargetModal/hooks/KeyValueHook";
import {
  convertOptionsList,
  valueToOption,
  multivalueParse,
  valueToJSON,
  convertDataList
} from "../CreateTargetModal/Step2";

import {
  actionDeleteTargetModal,
  editTarget,
  getAssets,
  getScheduleData,
  getTarget,
  getTargetTags,
  getTargetTypes
} from "store/entities/targets/actions";
import {
  getTargetAssetsOptionsSelector,
  getTargetTagOptionsSelector,
  getTargetTypeOptionsSelector
} from "store/entities/targets/selectors";

import { labelsMiniSelector } from "store/entities/labels/selectors";

import styles from "./styles.module.scss";
import Soarcast from "./Soarcast";

const DynamicField = ({ field, valueMap, onChange }) => {
  switch (field.field_type) {
    case "textarea":
      return (
        <Textarea
          className={styles.dynControl}
          label={field.description}
          required={field.required}
          value={valueMap.get(field.name)}
          onChange={v => onChange(field.name, v)}
        />
      );

    case "select":
      return (
        <Select
          containerClass={styles.dynControl}
          label={field.description}
          required={field.required}
          options={convertOptionsList(field.select_options)}
          value={valueToOption(valueMap, field.name)}
          onChange={v => onChange(field.name, v.value)}
        />
      );

    case "select_multiple": {
      return (
        <Tags
          className={styles.dynControl}
          label={field.description}
          required={field.required}
          list={convertOptionsList(field.select_options)}
          values={multivalueParse(valueMap, field.name)}
          onChange={v => onChange(field.name, valueToJSON(v))}
        />
      );
    }

    case "array":
      return (
        <MultyInput
          className={styles.formInput}
          label={field.description}
          required={field.required}
          values={valueMap.get(field.name)}
          onChange={v => onChange(field.name, v)}
        />
      );

    default:
      return (
        <Input
          containerClassName={styles.dynControl}
          label={field.description}
          required={field.required}
          value={valueMap.get(field.name)}
          onChange={v => onChange(field.name, v.target.value)}
        />
      );
  }
};

const Details = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: targetId } = useParams();

  const { targetTypes, target, scheduleData } = useSelector(state => state.targets);

  const [targetType, setTargetType] = useState();
  const currTargetType = useMemo(
    () => (targetType ? targetTypes.find(item => item.id === targetType.value) : undefined),
    [targetTypes, targetType]
  );

  const isLoading = useSelector(state => createLoadingSelector([getTarget.type])(state));
  const tagOptions = useSelector(getTargetTagOptionsSelector);
  const targetTypeOptions = useSelector(getTargetTypeOptionsSelector);
  const labelsOptions = useSelector(labelsMiniSelector);

  const [name, setName] = useState("");
  const [note, setNote] = useState("");

  const [dynamicFieldsDetails, dynamicFieldsCtrlDetails] = useDynamicFieldsDetails(
    currTargetType?.fields
  );
  const [tags, setTags] = useState([]);
  const [assets, setAssets] = useState([]);
  const [label, setLabel] = useState("");

  const [scheduleEnabled, setScheduleEnabled] = useState(false);
  const [schedule, scheduleCtrl] = useSchedule();

  const [miscExpanded, setMiscExpanded] = useState(false);
  const [miscData, miscDataCtrl] = useKeyValueList();

  const [assetSearch, setAssetSearch] = useState("");
  useEffect(() => {
    dispatch(getAssets(assetSearch));
  }, [assetSearch]);
  const assetOptions = useSelector(getTargetAssetsOptionsSelector);

  useEffect(() => {
    if (target) {
      setName(target.name || "");
      setNote(target.note || "");

      const typeData = targetTypes.find(item => item.id === target?.target_type);
      dynamicFieldsCtrlDetails.updValues(typeData?.fields, target?.data_list);

      if (target.tags && tagOptions) {
        const tagValues = target.tags.map(tagId => tagOptions.find(item => item.value === tagId));
        setTags(tagValues);
      } else {
        setTags([]);
      }

      if (target.label && labelsOptions) {
        const labelValue = labelsOptions?.find(item => item.value === target.label);
        setLabel(labelValue);
      } else {
        setLabel("");
      }

      if (target.assetData) {
        const assetValues = target.assetData.map(item => ({ label: item.title, value: item.id }));
        setAssets(assetValues);
      } else {
        setAssets([]);
      }

      setScheduleEnabled(target.schedule);
      scheduleCtrl.resetSchedule(target?.schedule);

      setMiscExpanded(target.misc);
      miscDataCtrl.init(target.misc);

      if (targetTypeOptions) {
        const typeOption = targetTypeOptions.find(item => item.value === target.target_type);
        setTargetType(typeOption);
      } else {
        setTargetType(undefined);
      }
    }
  }, [target, tagOptions, targetTypes, labelsOptions]);

  useEffect(() => {
    dispatch(getTargetTypes());
    dispatch(getTargetTags());
    dispatch(getScheduleData());
    dispatch(getTarget(targetId));
  }, [targetId]);

  const { fields } = useMemo(() => {
    const res = [];
    if (currTargetType) {
      currTargetType.fields.forEach(item => {
        res.push(item);
      });
    }

    return { fields: res };
  }, [currTargetType]);

  const validate = () => {
    if (!targetType || !name) {
      return false;
    }

    if (!dynamicFieldsCtrlDetails.checkRequiredFields()) {
      return false;
    }

    return true;
  };

  const handleTargetTypeChange = val => {
    setTargetType(val);
    dynamicFieldsCtrlDetails.resetValues(currTargetType?.fields);
  };

  const handleSave = () => {
    if (validate()) {
      const dataList = dynamicFieldsDetails?.map(item => {
        return convertDataList(currTargetType.fields, item);
      });

      let targetData = {
        name,
        note,
        data_list: dataList,
        misc: miscData,
        target_type: currTargetType.id,
        tags: tags.map(tag => tag.value),
        assets: assets.map(item => item.value),
        label: label?.value ?? ""
      };

      if (scheduleEnabled) {
        const { startTime } = schedule;
        targetData = {
          ...targetData,
          schedule
        };
        if (startTime) {
          const timeParts = startTime.split(":");
          const val = new Date(new Date().setHours(timeParts[0], timeParts[1], 0));
          targetData.schedule.startTime = val;
        }
      }
      dispatch(
        editTarget({ id: targetId, data: targetData, onSuccess: () => history.push("/targets") })
      );
    }
  };

  const handleDelete = () => {
    dispatch(actionDeleteTargetModal({ show: true, deleteList: [target], navigateBack: true }));
  };

  const onAddDynamicFieldsDetails = () => {
    dynamicFieldsCtrlDetails.onAddDynamicFieldsDetails(currTargetType?.fields);
  };

  const handleDeleteDynamicFieldsDetails = i => {
    dynamicFieldsCtrlDetails.handleDeleteDynamicFieldsDetails(i);
  };

  return (
    <div className={styles.pageBody}>
      {isLoading ? <Loader /> : null}
      <Title className={styles.pageTitle}>Target details</Title>
      <div className={styles.dynControlsBox}>
        <Input
          containerClassName={styles.dynControl}
          label="Name"
          required
          value={name}
          onChange={evt => setName(evt.target.value)}
        />
        <Input
          containerClassName={styles.dynControl}
          label="Note"
          value={note}
          onChange={evt => setNote(evt.target.value)}
        />
      </div>

      {fields?.length ? (
        <div className={styles.dynControlBox2}>
          {dynamicFieldsDetails?.map((fld, index) => {
            return (
              <div key={index} className={styles.row}>
                {fields.map((field, idx) => (
                  <DynamicField
                    key={`DynFieldLg_${idx}`}
                    field={field}
                    valueMap={fld}
                    onChange={(n, v) => dynamicFieldsCtrlDetails.setValue(n, v, index)}
                  />
                ))}
                {dynamicFieldsDetails?.length > 1 ? (
                  <ActionButton
                    type="delete"
                    onClick={() => handleDeleteDynamicFieldsDetails(index)}
                  />
                ) : null}
              </div>
            );
          })}
          <div className={styles.addBtnBody} onClick={onAddDynamicFieldsDetails}>
            <div className={styles.addBtnIcon}>
              <Icon.Plus />
            </div>
            <div className={styles.addBtnLabel}>Add</div>
          </div>
        </div>
      ) : null}
      <Soarcast />
      {/* <SwitchBox
        className={styles.scheduleBox}
        label="Schedule"
        expanded={scheduleEnabled}
        onExpand={setScheduleEnabled}
      >
        <ScheduleEdit scheduleData={scheduleData} schedule={schedule} scheduleCtrl={scheduleCtrl} />
      </SwitchBox> */}
      <ExpandBox
        className={styles.scheduleBox}
        label="Misc Values"
        expanded={miscExpanded}
        onToggleExpand={() => setMiscExpanded(!miscExpanded)}
      >
        <KeyValueEdit keyValList={miscData} keyValCtrl={miscDataCtrl} />
      </ExpandBox>
      <div className={styles.tagsGroupBox}>
        <Tags
          className={styles.tagsGroupItem}
          label="Tags"
          list={tagOptions}
          values={tags?.filter(it => it !== undefined)}
          onChange={setTags}
        />
        <Select
          containerClass={styles.tagsGroupItem}
          label="Label"
          options={labelsOptions}
          value={label}
          onChange={v => setLabel(v)}
        />
      </div>

      <div className={styles.targetTypeGroupBox}>
        <Select
          containerClass={styles.targetTypeGroupItem}
          label="Target type"
          required
          options={targetTypeOptions}
          value={targetType}
          onChange={handleTargetTypeChange}
        />
        <Assets
          className={styles.targetTypeGroupItem}
          label="Assets"
          values={assets}
          onChange={setAssets}
          list={assetOptions}
          onSearchChange={setAssetSearch}
        />
      </div>
      <div className={styles.buttonsGroup}>
        <Button className={styles.buttonsGroupItem} disabled={!validate()} onClick={handleSave}>
          Save
        </Button>
        <Button className={styles.buttonsGroupItem} variant="outline" onClick={handleDelete}>
          Delete
        </Button>
      </div>
      <DeleteTargetModal />
    </div>
  );
};

export default Details;
