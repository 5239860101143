import { withTheme } from "@mui/styles";
import { ContentContainer } from "components/simple";
import { onChangeUrlParams } from "helper/history";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { routes } from "router";
import { setAssetsParams } from "store/entities/assets/actions";

import styles from "../styles.module.scss";

const DashboardChart = withTheme(({ data, name, containerWidth, theme }) => {
  // making svg chart resizible
  const CHARTS_QUANTITY = 3;
  const CHARTS_PADDING = 42;
  const chartWidth = containerWidth / CHARTS_QUANTITY - CHARTS_PADDING;

  const dispatch = useDispatch();
  const history = useHistory();

  const renderLegend = () => {
    return <div className={styles.chartLegend}>{name}</div>;
  };

  const renderXAxisCustomTick = props => {
    const { x, y, payload } = props;
    const [year, month, day] = String(payload.value).split("-");
    const shortenedTick = `${month}/${day}/${year.slice(2)}`;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={30} y={0} dy={16} fontSize="12px" textAnchor="end" fill="#959eb5">
          {shortenedTick}
        </text>
      </g>
    );
  };

  const renderYAxisCustomTick = props => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={-10} dy={16} fontSize="12px" textAnchor="end" fill="#959eb5">
          {payload.value}
        </text>
      </g>
    );
  };

  const onBarClick = e => {
    switch (name) {
      case "Findings by Date":
        history.push(`${routes.vulnerabilities}`);
        onChangeUrlParams(
          "filters",
          JSON.stringify({
            present_on_date: [e.activeLabel, e.activeLabel]
          })
        );
        break;
      case "Asset Types by Date":
        dispatch(
          setAssetsParams({
            filters: {
              present_on_date: [e.activeLabel, e.activeLabel]
            },
            page: 0,
            search: "",
            ordering: "obj_type"
          })
        );
        break;
      case "Asset Count by Scan":
        dispatch(
          setAssetsParams({
            filters: {
              present_on_date: [e.activeLabel, e.activeLabel]
            },
            page: 0,
            search: "",
            ordering: ""
          })
        );
        break;
      default:
    }
  };

  return (
    <ContentContainer>
      <ResponsiveContainer width={chartWidth} height={184}>
        <BarChart
          onClick={onBarClick}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <XAxis dataKey="date" interval={data.length - 2} tick={renderXAxisCustomTick} />
          <YAxis width={20} tick={renderYAxisCustomTick} dataKey="count" />
          <Tooltip />
          <Legend verticalAlign="top" align="left" content={renderLegend} />
          <Bar name={name} dataKey="count" fill={theme.palette.primary.main} />
        </BarChart>
      </ResponsiveContainer>
    </ContentContainer>
  );
});

export default memo(DashboardChart);
