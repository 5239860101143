/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { createLoadingSelector } from "store/entities/loading/selector";
import {
  updateProfile,
  getUserProfile,
  removeMFA,
  emailNotificationSubscriptions
} from "store/entities/users/actions";
import {
  getUserSelector,
  emailNotificationSubscriptionsSelector
} from "store/entities/users/selectors";
import { changeTheme } from "store/entities/theme/actions";

import { ContentContainer, Title, Select, Input, Button } from "components/simple";
import styles from "./styles.module.scss";

const colorsList = [
  {
    value: "Theme1",
    label: "Color 1",
    color: "#d83e3c"
  },
  {
    value: "Theme2",
    label: "Color 2",
    color: "#379EFF"
  },
  {
    value: "Theme3",
    label: "Color 3",
    color: "#1F3155"
  },
  {
    value: "Theme4",
    label: "Color 4",
    color: "#1D1F29"
  },
  {
    value: "Theme5",
    label: "Color 5",
    color: "#172A5B"
  },
  {
    value: "Theme6",
    label: "Color 6",
    color: "#44A7A7"
  },
  {
    value: "Theme7",
    label: "Color 7",
    color: "#3AB195"
  },
  {
    value: "Theme8",
    label: "Color 8",
    color: "#6190C7"
  },
  {
    value: "Theme9",
    label: "Color 9",
    color: "#253664"
  }
];

const Profile = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(s => createLoadingSelector([getUserProfile.type])(s));

  const [state, setState] = useState({
    email: "",
    first_name: "",
    last_name: "",
    email_notification_types: "",
    notification_types: "",
    mfa_device: "",
    color_schema: ""
  });

  const editData = useSelector(getUserSelector);
  const emailNotificationSubscriptionsList = useSelector(emailNotificationSubscriptionsSelector);

  useEffect(() => {
    if (editData) {
      setState(editData);
    }
  }, [editData]);

  useEffect(() => {
    dispatch(emailNotificationSubscriptions());
  }, []);

  const onRemove = id => {
    dispatch(removeMFA(id));
  };
  const handleSubmit = e => {
    e.preventDefault();

    const res = {
      id: state?.id,
      email: state?.email,
      first_name: state?.first_name,
      last_name: state?.last_name,
      user_profile: {
        email_notification_types: state?.email_notification_types?.map(item => item?.value),
        notification_types: state?.notification_types?.map(item => item?.value),
        color_schema: state?.color_schema
      }
    };
    dispatch(updateProfile(res));
    dispatch(changeTheme(state?.color_schema));
  };

  const inputChangeHandler = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  const changeColorScheme = v => {
    setState({
      ...state,
      color_schema: v?.value
    });
  };

  const square = (color = "transparent") => {
    return {
      alignItems: "center",
      display: "flex",

      ":before": {
        backgroundColor: color,
        borderRadius: 3,
        content: '" "',
        display: "block",
        marginRight: 10,
        height: 16,
        width: 16,
        flexShrink: 0
      }
    };
  };

  const colourStyles = {
    option: (provided, { isDisabled, isFocused, isSelected, ...r }) => {
      const color = r?.data?.color;
      return {
        ...provided,
        // height: "34px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        backgroundColor: isFocused || isSelected ? "#f4f5fa" : "#ffffff",
        color: "#2b3858",
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: isSelected ? "600" : "normal",
        borderRadius: "7px",
        paddingLeft: "10px",
        marginBottom: "1px",
        ...square(color),
        ":active": {
          backgroundColor: "#f4f5fa"
        }
      };
    },
    input: (st, { selectProps }) => {
      const color = selectProps?.value?.color;
      return {
        ...st,
        ...square(color)
      };
    },
    singleValue: st => ({
      ...st,
      paddingLeft: "20px"
    })
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>My Profile</Title>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputsCont}>
          <Input
            value={state.email}
            onChange={e => inputChangeHandler(e.target.value, "email")}
            label="Email"
            type="email"
            required
          />
          <Select
            containerClass={styles.inputContainer}
            onChange={v => changeColorScheme(v)}
            value={colorsList.find(item => item.value === state.color_schema)}
            placeholder="Color scheme"
            label="Color scheme"
            options={colorsList}
            style={colourStyles}
          />
        </div>

        <div className={styles.inputsCont}>
          <Input
            value={state.first_name}
            onChange={e => inputChangeHandler(e.target.value, "first_name")}
            label="First name"
            required
          />
          <Input
            value={state.last_name}
            onChange={e => inputChangeHandler(e.target.value, "last_name")}
            label="Last name"
            required
          />
        </div>
        <div className={styles.inputsCont}>
          <Select
            value={state.email_notification_types}
            onChange={e => inputChangeHandler(e, "email_notification_types")}
            label="Email Notification Subscriptions"
            options={emailNotificationSubscriptionsList}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable={false}
            allowSelectAll
          />
          <Select
            value={state.notification_types}
            onChange={e => inputChangeHandler(e, "notification_types")}
            label="Notification Subscriptions"
            options={emailNotificationSubscriptionsList}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable={false}
            allowSelectAll
          />
        </div>

        <div className={styles.bottom}>
          <div className={styles.sub_title}>Secirity credentials</div>
          <div className={styles.inputsCont}>
            <Input value={state.mfa_device} readOnly label="Assigned MFA Device" />
            {state?.mfa_id ? (
              <div className={styles.btnRemove}>
                <Button variant="outline" onClick={() => onRemove(state?.mfa_id)}>
                  Remove
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.buttonFooter}>
          <Button type="submit" className={styles.btn} isLoading={isLoading}>
            Save
          </Button>
        </div>
      </form>
    </ContentContainer>
  );
};

export default Profile;
