import React, { useMemo } from "react";

import { Select } from "components/simple";
import { DateSelect } from "../DateSelect";
import { TimeSelect } from "../TimeSelect";
import { WeekDaySelect } from "../WeekDaySelect";

import styles from "./styles.module.scss";

const ScheduleEdit = ({ className, scheduleData, schedule, scheduleCtrl }) => {
  let bodyClass = styles.scheduleEditBody;
  if (className) {
    bodyClass += ` ${className}`;
  }

  const freqOptions = useMemo(() => {
    if (!scheduleData || !scheduleData.frequency) {
      return [];
    }

    return scheduleData.frequency.map(item => ({ value: item[0], label: item[1] }));
  }, [scheduleData]);

  const timezoneOptions = useMemo(() => {
    if (!scheduleData || !scheduleData.timezones) {
      return [];
    }

    return scheduleData.timezones.map(item => ({ value: item, label: item }));
  }, [scheduleData]);

  const repeatOptions = useMemo(() => {
    if (!scheduleData || !scheduleData.repeat_every) {
      return [];
    }

    return scheduleData.repeat_every.map(item => ({ value: item, label: item }));
  }, [scheduleData]);

  return (
    <div className={bodyClass}>
      <div className={styles.inputsBlock}>
        <Select
          containerClass={styles.blockInput}
          label="Frequency"
          options={freqOptions}
          value={schedule.frequency}
          onChange={scheduleCtrl.setFrequency}
        />
        <DateSelect
          containerClass={styles.blockInput}
          label="Date"
          value={schedule.date}
          onChange={scheduleCtrl.setDate}
        />
        <Select
          containerClass={styles.blockInput}
          label="Timezone"
          options={timezoneOptions}
          value={schedule.timeZone}
          onChange={scheduleCtrl.setTimeZone}
        />
        <TimeSelect
          containerClass={styles.blockInput}
          label="Start"
          value={schedule.startTime}
          onChange={scheduleCtrl.setStartTime}
        />
        <Select
          containerClass={styles.blockInput}
          label="Repeat every"
          options={repeatOptions}
          value={schedule.repeat}
          onChange={scheduleCtrl.setRepeat}
        />
      </div>
      <WeekDaySelect
        label="Repeat on"
        value={schedule.repeatDays}
        onValueChange={scheduleCtrl.setRepeatDays}
      />
      {/* <div className={styles.summaryTitle}>Summary</div>
      <div className={styles.summary}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus nibh magna curabitur
        accumsan aliquam ornare habitant viverra. Ipsum.
      </div> */}
    </div>
  );
};

export default ScheduleEdit;
