import React from "react";

import { Button, Input, Title, Modal } from "components/simple";

import styles from "./styles.module.scss";

const AddLabelModal = ({ isOpen, state, onChange, onSubmit, onClose, isLoading }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <Title className={styles.title}>Add label</Title>
      <form onSubmit={onSubmit}>
        <Input
          label="Name"
          value={state.name}
          onChange={e => onChange(e.target.value, "name")}
          containerClassName={styles.input}
          required
        />
        <Input
          label="Filename Prefix"
          value={state.aws_prefix}
          onChange={e => onChange(e.target.value, "aws_prefix")}
          containerClassName={styles.input}
          required
        />
        <div className={styles.footer}>
          <Button variant="outline" onClick={onClose} className={styles.btn}>
            Back
          </Button>
          <Button type="submit" isLoading={isLoading} className={styles.btn}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddLabelModal;
