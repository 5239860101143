/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { routes } from "router";

import { labelsTableColumns } from "../../tableColumns";
import { labelsTableDataSelector } from "store/entities/settings/selectors";
import {
  deleteSettingsLabel,
  setLabelsParams,
  getSettingsLabels
} from "store/entities/settings/actions";

import { createLoadingSelector } from "store/entities/loading/selector";

import { Table } from "components/complex";
import { ContentContainer, Title, Button, Icon } from "components/simple";

import styles from "./styles.module.scss";

const Labels = ({ history }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);
  const { page, pageSize } = useSelector(state => state.settings.labelsParams);
  const { data, total } = useSelector(labelsTableDataSelector);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getSettingsLabels.type])(state));
  const dispatch = useDispatch();

  // get default request
  const getRequest = () => {
    dispatch(
      setLabelsParams({
        page: 0,
        ordering: "",
        search: ""
      })
    );
  };

  // Next page
  const onPageChange = newPage => {
    prevSelectionModel.current = selectionModel;
    dispatch(
      setLabelsParams({
        page: newPage
      })
    );
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setLabelsParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setLabelsParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setLabelsParams({
          ordering: ""
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setLabelsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = v => {
    const { id } = v;
    history.push(`${routes.settingsLabelsDetails}/${id}`);
  };

  const addLabelHandler = () => {
    history.push(routes.settingsLabelsDetails);
  };

  const deleteLabelHandler = () => {
    if (selectionModel.length) {
      dispatch(deleteSettingsLabel({ ids: selectionModel }));
    }
  };

  const customStyles = {
    height: 710,
    width: 1,
    padding: "30px",
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  const tableConfig = {
    hideSelectedRowCount: true
  };

  return (
    <ContentContainer>
      <div className={styles.controls}>
        <Title>Labels</Title>
        <div className={styles.btnsContainer}>
          <Button className={styles.btn} onClick={addLabelHandler} icon="plus">
            Add Label
          </Button>
          <Button
            className={`${styles.btn} ${styles.delete}`}
            onClick={deleteLabelHandler}
            disabled={!selectionModel?.length}
          >
            Delete
            <Icon.Delete color="#ffff" />
          </Button>
        </div>
      </div>
      <Table
        data={isLoading ? [] : data}
        columns={labelsTableColumns}
        loading={isLoading}
        onSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel);
        }}
        page={page || 0}
        rowCount={total}
        selectionModel={selectionModel}
        onSortModelChange={model => handleSort(model)}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        paginationMode="server"
        onPageChange={onPageChange}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
        onCellClick={e => onCellClick(e)}
        customStyles={customStyles}
        {...tableConfig}
      />
    </ContentContainer>
  );
};

Labels.propTypes = {};

export default Labels;
