/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Title, Search, Button, Icon, Select } from "components/simple";
import { Table } from "components/complex";
import { CreateTargetModal } from "./CreateTargetModal";
import { AddToGroupModal } from "./AddToGroupModal";
import { DeleteTargetModal } from "./DeleteTargetModal";

import { onChangeUrlParams, useQuery } from "helper/history";
import {
  setTargetsParams,
  getTargets,
  getTargetTypes,
  actionCreateTargetModal,
  actionAddToGroupModal,
  actionDeleteTargetModal,
  getTargetTags,
  getScheduleData,
  getTargetGroups
} from "store/entities/targets/actions";
import { getTagsMini } from "store/entities/tags/actions";
import { tagsMiniSelector } from "store/entities/tags/selectors";
import { getLabelsMini } from "store/entities/labels/actions";

import { createLoadingSelector } from "store/entities/loading/selector";
import { getTargetsSelector, getTargetTypeOptionsSelector } from "store/entities/targets/selectors";
import { routes } from "router";

import styles from "./styles.module.scss";

const Targets = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");

  const { page, pageSize, type, tag } = useSelector(state => state.targets);

  const { columns, data, total } = useSelector(state => getTargetsSelector(state));
  const isLoading = useSelector(state => createLoadingSelector([getTargets.type])(state));

  const selectTenant = useSelector(state => state.users.selectTenant);

  const targetTypeList = useSelector(getTargetTypeOptionsSelector);
  const tagsMiniList = useSelector(tagsMiniSelector);

  const [selectionModel, setSelectionModel] = useState([]);
  const showActions = selectionModel.length > 0;

  // get default request
  const getRequest = () => {
    dispatch(
      setTargetsParams({
        page: queryPage ? queryPage - 1 : 0,
        search: "",
        filterBy: "",
        type: "",
        tag: "",
        ordering: ""
      })
    );
  };

  useEffect(() => {
    dispatch(getTargetTypes());
    dispatch(getTargetTags());
    dispatch(getScheduleData());
    dispatch(getTargetGroups());
    dispatch(getTagsMini());
    dispatch(getLabelsMini());

    getRequest();
  }, [selectTenant]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setTargetsParams({
        page: 0,
        search: v
      })
    );
  };

  // Target type
  const handleTargetTypeChange = v => {
    dispatch(
      setTargetsParams({
        page: 0,
        type: v
      })
    );
  };

  // Target type
  const handleTagChange = v => {
    dispatch(
      setTargetsParams({
        page: 0,
        tag: v
      })
    );
  };

  // Page change
  const handlePageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    dispatch(setTargetsParams({ page: newPage }));
  };

  // Page size change
  const handlePageSizeChange = newSize => {
    dispatch(setTargetsParams({ pageSize: newSize }));
  };

  // Create target
  const handleCreateTarget = () => {
    dispatch(actionCreateTargetModal({ show: true }));
  };

  // Add to group
  const handleAddToGroup = () => {
    const selectList = selectionModel.map(item => data.find(dataItem => dataItem.id === item));
    dispatch(actionAddToGroupModal({ show: true, selectList, newGroup: false }));
  };

  // Delete target
  const handleDeleteTarget = () => {
    const deleteList = selectionModel.map(item => data.find(dataItem => dataItem.id === item));
    dispatch(actionDeleteTargetModal({ show: true, deleteList, navigateBack: false }));
  };

  const handleCellClick = t => {
    const id = t?.id;
    history.push(`${routes.targetsDetails}/${id}`);
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setTargetsParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setTargetsParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setTargetsParams({
          ordering: ""
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.filters}>
          <Title>Targets</Title>
          <div className={styles.searchRow}>
            <Search onSearch={handleSearch} />

            <div className={styles.searchActions}>
              <Button icon="plus" onClick={handleCreateTarget}>
                Create target
              </Button>
              {showActions && (
                <>
                  <Button
                    className={styles.addToGroupBtn}
                    icon="plus"
                    variant="outlined"
                    onClick={handleAddToGroup}
                  >
                    Add to Group
                  </Button>
                  <Button className={styles.deleteBtn} onClick={handleDeleteTarget}>
                    Delete
                    <Icon.Delete color="#ffff" />
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className={styles.searchRow}>
            <div className={styles.selectsGroup}>
              {/* <Select containerClass={styles.select} placeholder="Filter by" isClearable /> */}
              <Select
                containerClass={styles.select}
                placeholder="Type"
                options={targetTypeList}
                isClearable
                value={type}
                onChange={handleTargetTypeChange}
              />
              <Select
                containerClass={styles.select}
                placeholder="Tag"
                options={tagsMiniList}
                isClearable
                value={tag}
                onChange={handleTagChange}
              />
            </div>
          </div>
        </div>
        <Table
          data={isLoading ? [] : data}
          columns={columns}
          loading={isLoading}
          page={page || 0}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onSortModelChange={model => handleSort(model)}
          rowCount={total}
          paginationMode="server"
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          onCellClick={handleCellClick}
        />
      </div>
      <CreateTargetModal />
      <AddToGroupModal />
      <DeleteTargetModal />
    </div>
  );
};

Targets.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Targets;
