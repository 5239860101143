import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { Icon } from "components/simple";
import { FormLabel } from "@mui/material";
import QuestionTooltip from "../QuestionTooltip";

import styles from "./styles.module.scss";

const Tags = ({ label, values, list, onChange, className, required, tooltip, isDisabled }) => {
  const [isOpen, setOpen] = useState(false);
  const selectedList = values?.filter(item => item) ?? [];
  const containerRef = useRef(null);
  const suggestionsList =
    list
      .filter(item => item)
      .filter(item => !selectedList?.some(it => it.value.toString() === item?.value)) ?? [];

  const onOpenMenu = () => {
    setOpen(!isOpen);
  };

  const onAddTag = id => {
    const target = suggestionsList.find(tag => tag.value === id);
    onChange([...selectedList, target]);
  };

  const onRemoveTag = id => {
    const newSelectedList = selectedList.filter(tag => tag.value !== id);
    onChange(newSelectedList);
  };

  useEffect(() => {
    // Close menu on outside click
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.wrapper} ${className} ${required ? styles.required : ""}`}>
      {label && (
        <div className={styles.labelCont}>
          <FormLabel className={styles.label}>{label}</FormLabel>
          {tooltip && <QuestionTooltip text={tooltip} />}
        </div>
      )}
      <div ref={containerRef}>
        <div className={styles.container}>
          {selectedList?.map(tag => (
            <div
              key={tag.value}
              onClick={() => onRemoveTag(tag.value)}
              className={`${styles.tag} ${styles.selected}`}
            >
              {tag.label}
              <Icon.Close className={styles.removeBtn} color="#d83e3c" width="18" height="18" />
            </div>
          ))}
          <div
            onClick={!isDisabled ? onOpenMenu : () => null}
            aria-disabled={isDisabled}
            className={styles.addBtn}
          >
            <Icon.Plus color="#d83e3c" />
          </div>
        </div>

        {isOpen && (
          <div className={`${styles.modal} ${styles.container}`}>
            {suggestionsList.length > 0 ? (
              suggestionsList.map(tag => (
                <div
                  key={tag.value}
                  onClick={() => onAddTag(tag.value)}
                  className={`${styles.tag} ${styles.lol}`}
                >
                  {tag.label}
                </div>
              ))
            ) : (
              <div className={styles.noOptions}>No Options</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Tags.propTypes = {
  label: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  isDisabled: PropTypes.bool
};

Tags.defaultProps = {
  label: "",
  onChange: () => {},
  className: "",
  tooltip: "",
  isDisabled: false
};

export default Tags;
