import { useState } from "react";

const buildDynamicFields = fieldList => {
  const res = new Map();
  if (fieldList) {
    fieldList.forEach(field => {
      res.set(field.name, "");
    });
  }

  return res;
};

export const useDynamicFieldsDetails = fieldList => {
  const init = buildDynamicFields(fieldList);
  const [data, setData] = useState([init]);
  const ctrlObj = {
    setValue: (name, value, index) => {
      const res = data?.map((item, i) => {
        if (i === index) {
          item.set(name, value);
        }
        return item;
      });
      setData(res);
    },
    onAddDynamicFieldsDetails: newFieldList => {
      const newData = buildDynamicFields(newFieldList);
      setData([...data, newData]);
    },
    handleDeleteDynamicFieldsDetails: index => {
      const res = data?.filter((_, i) => i !== index);
      setData(res);
    },

    resetValues: newFieldList => {
      const newData = buildDynamicFields(newFieldList);
      setData([newData]);
    },

    updValues: (newFieldList, newInitData) => {
      if (!newFieldList) {
        setData([]);
        return;
      }
      if (newInitData) {
        const res = newInitData?.map(it => {
          const newData = buildDynamicFields(newFieldList);

          const keyList = Array.from(newData.keys());

          keyList.forEach(key => {
            const fieldType = newFieldList.find(item => item.name === key);
            const value = it[key];

            if (fieldType && value) {
              if (fieldType.field_type === "select_multiple") {
                const valueArr = value.map(item => ({ label: item, value: item }));
                newData.set(key, JSON.stringify(valueArr));
              } else {
                newData.set(key, value);
              }
            }
          });
          return newData;
        });
        setData(res);
      }
    },

    checkRequiredFields: () => {
      let valid = true;
      if (!fieldList) {
        return true;
      }

      const fieldCount = fieldList.length;
      // eslint-disable-next-line no-plusplus
      for (let fieldIdx = 0; fieldIdx < fieldCount; fieldIdx++) {
        const field = fieldList[fieldIdx];
        if (field.required) {
          const fieldData = data?.filter(item => {
            return item.get(field.name);
          });
          if (fieldData?.length > 0) {
            valid = false;
            return;
          }
        }
      }
      return valid;
    }
  };

  return [data, ctrlObj];
};
