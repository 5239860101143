import React from "react";

import { Button, Select, Title } from "components/simple";

import styles from "./styles.module.scss";

export const Step1 = ({ typeOptions, currType, onCurrTypeChanged, onNext, onCancel }) => {
  return (
    <div>
      <div className={styles.titleBox}>
        <Title>Create target</Title>
        <div className={styles.stepTitle}>Step 1</div>
      </div>
      <Select
        containerClass={styles.select}
        name="target_type"
        label="Type of target"
        options={typeOptions}
        value={currType}
        onChange={onCurrTypeChanged}
        style={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
      <div className={styles.modalFooter}>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onNext} disabled={currType === null || currType === undefined}>
          Save
        </Button>
      </div>
    </div>
  );
};
