/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post } from "api/base";
import { formatDate } from "helper/DateLib";

const generatorFilterUrl = obj => {
  if (!obj) {
    return "";
  }
  let url = "";

  Object.entries(obj).forEach(([key, val]) => {
    if (key === "label") {
      url += `&label_id=${val?.value || ""}`;
    }
    if (key === "target") {
      url += `&target_id=${val?.value || ""}`;
    }
    if (key === "group") {
      url += `&group_id=${val?.value || ""}`;
    }
    if (key === "asset_type") {
      url += `&obj_type=${val?.value || ""}`;
    }
    if (key === "data_types") {
      url += `&data_type=${val?.value || ""}`;
    }
    if (key === "present_on_date") {
      url += `&present_on_date_after=${val[0] || ""}&present_on_date_before=${val[1]}`;
    }
  });
  return url;
};

export const getAssets = ({ page, pageSize, search, ordering, assetId, filters }) => {
  const filt = generatorFilterUrl(filters);

  return get(
    `${API}/assets?page=${page + 1 || 1}&page_size=${pageSize ??
      ""}&search=${search}&ordering=${ordering ?? ""}&asset_id=${assetId ?? ""}${filt}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getAssetsMini = fields => {
  const { page, pageSize, search, data_type } = fields;
  let url = "";

  if (data_type) {
    url += `&data_type=${data_type}`;
  }
  return get(
    `${API}/assets-mini?page=${page + 1 || 1}&page_size=${pageSize}${url}&search=${search ?? ""}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getAsset = ({ id }) => {
  return get(`${API}/assets/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const setAssetTag = ({ id, data }) => {
  return post(`${API}/assets/${id}/add-tags`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getTags = ({ page, search }) => {
  return get(`${API}/tags?page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const setAssetBaseline = ({ id, data }) => {
  return post(`${API}/assets/${id}/update-baselines`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getBaselines = ({ page, search }) => {
  return get(`${API}/baselines?page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getGroups = ({ page, search, id }) => {
  return get(`${API}/groups-mini?asset_id=${id}&page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getDataLists = id => {
  return get(`${API}/assets/${id}/data-list-types`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getAssetDataListType = ({ id, type }) => {
  return get(`${API}/assets/${id}/data-list?data_type=${type}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getDataListByAsset = fields => {
  const { assetId, page, pageSize, ordering, search, data_type } = fields;
  return get(
    `${API}/data-lists?page=${page + 1 || 1}&ordering=${ordering}&search=${search ??
      ""}&page_size=${pageSize}&asset_id=${assetId}&data_type=${data_type || ""}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getFindingsByAsset = fields => {
  const { assetId, page, pageSize, ordering, search, data_type, filters, groupBy } = fields;

  const generatedFilters = Object.entries(filters)
    .filter(([key, val]) => val?.value !== undefined)
    .map(([key, value]) => ({ [key]: value?.value }))
    .reduce((acc, item) => ({ ...acc, ...item }), {});

  const paramsObj = {
    page: page + 1 || 1,
    ordering,
    search: search ?? "",
    page_size: pageSize,
    asset_id: assetId,
    data_type: data_type ?? "",
    group_by: groupBy?.value ?? "",
    ...generatedFilters,
    last_seen_before: filters?.last_seen?.[1] ? formatDate(filters?.lastSeen?.[1]) : "",
    last_seen_after: filters?.last_seen?.[0] ? formatDate(filters?.lastSeen?.[0]) : ""
  };

  const searchParams = new URLSearchParams(paramsObj);

  return get(`${API}/findings?${searchParams.toString()}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTicketsConfig = data_type => {
  const url = data_type ? `?data_type=${data_type}` : "";
  return get(`${API}/tickets-config-mini${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTickets = ({ page, search, id }) => {
  return get(`${API}/tickets-mini?asset_id=${id}&page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getFindings = ({ page, search, id }) => {
  return get(`${API}/findings-mini?asset_id=${id}&page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getAssetFilterChoices = () => {
  return get(`${API}/assets/filter-choices`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getMonitors = ({ page, search, assetId }) => {
  return get(`${API}/monitors?asset_id=${assetId}&page=${page + 1}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addGroup = fields => {
  const { search, ...rest } = fields;
  const url = search ? `?search=${search}` : "";

  return post(`${API}/assets/add-to-group${url}`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getAssetTypesCount = () => {
  return get(`${API}/assets/asset-types-count`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getScansChartData = ({ dateTo, dateFrom }) => {
  const url = dateTo && dateFrom ? `?date_to=${dateTo}&date_from=${dateFrom}` : "";
  return get(`${API}/scans/scans-chart-data${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getAssetsChartData = ({ dateTo, dateFrom }) => {
  const url = dateTo && dateFrom ? `?date_to=${dateTo}&date_from=${dateFrom}` : "";
  return get(`${API}/assets/assets-chart-data${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getFindingsChartData = ({ dateTo, dateFrom }) => {
  const url = dateTo && dateFrom ? `?date_to=${dateTo}&date_from=${dateFrom}` : "";
  return get(`${API}/findings/findings-chart-data${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
