import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Title, Modal, Icon } from "components/simple";

import { MFASuccessModal } from "store/entities/users/actions";
import styles from "./styles.module.scss";

const MFAModalSuccess = () => {
  const open = useSelector(state => state?.users?.MFASuccess);
  const dispatch = useDispatch();
  const close = () => {
    dispatch(MFASuccessModal(false));
  };
  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Set up virtual MFA device</Title>
      <div className={styles.content}>
        <div className={styles.block}>
          <div className={styles.icon}>
            <Icon.Check />
          </div>
          <div className={styles.cont}>
            <div className={styles.title}>You have successfully assigned virtual MFA</div>
            <div className={styles.description}>
              This virtual MFA will be required during sign-in.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button variant="outline" onClick={close}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default MFAModalSuccess;
