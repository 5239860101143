import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { AccessControl, permissions } from "components/permissions";
import DashboardContent from "./DashboardContent";
import styles from "./dashboard.module.scss";

import { getTenants } from "store/entities/users/actions";
import ResetPasswordModal from "screens/Profile/ResetPasswordModal";
import PermissionsModal from "screens/Settings/PermissionsModal";
import SetUpMFAModal from "screens/Profile/SetUpMFAModal";
import MFAModalSuccess from "screens/Profile/MFAModalSuccess";

import { Sidebar, Header } from "components/complex";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTenants());
  }, []);

  return (
    <div className={styles["dashboard-container"]}>
      <Sidebar />
      <div className={styles["dashboard-wrapper"]}>
        <Header />
        <div className={styles["dashboard-content"]}>
          <DashboardContent />
          <ResetPasswordModal />
          <PermissionsModal />
          <SetUpMFAModal />
          <MFAModalSuccess />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
