import React from "react";
import PropTypes from "prop-types";

// import styles from "./styles.module.scss";
import { styled } from "@mui/material/styles";

import { LicenseInfo, DataGridPro } from "@mui/x-data-grid-pro";

import Box from "@mui/material/Box";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

LicenseInfo.setLicenseKey(
  "7e8da8b8d095a5329e941734eac201c9T1JERVI6MzU3MzEsRVhQSVJZPTE2NzM1MzYxMTcwMDAsS0VZVkVSU0lPTj0x"
);

function TableStyles() {
  return {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#fff",
      borderTop: "1px solid #E7E6F8",
      borderBottom: "1px solid #E7E6F8"
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "#fff",
      width: "100%",
      borderRadius: "0 0 15px 15px"
    },
    "& .MuiTablePagination-selectLabel": {
      order: 1,
      marginLeft: "auto"
    },
    "& .MuiInputBase-root": {
      order: 1
    },
    "& .MuiTablePagination-spacer": {
      display: "none"
    },
    "&  .MuiTablePagination-root": {
      width: "100%"
    },
    "& .MuiTablePagination-toolbar": {
      width: "100%"
    },
    "& .MuiDataGrid-selectedRowCount": {
      whiteSpace: "nowrap"
    },
    "& .MuiDataGrid-cellCheckbox ": {
      border: "none"
    }
  };
}

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  ...TableStyles(theme),
  border: "none",
  borderBottom: "10px"
}));

export default function Table({
  columns,
  data,
  pagination,
  paginationServer,
  handlePageChange,
  initialState = {},
  checkboxVisible,
  paginationVisible,
  hideSelectedRowCount,
  hideFooter,
  customStyles,
  sortingMode,
  onCellClick,
  ...rest
}) {
  const onCellClickHandler = (p, e) => {
    if (p?.field !== "__check__") {
      onCellClick(p, e);
    }
  };
  return (
    <Box
      sx={
        !customStyles
          ? {
              height: 710,
              width: 1,
              "& .super-app-theme--header": {
                backgroundColor: "#fff",
                borderBottom: "1px solid #E7E6F8"
              },
              "& .super-app-theme--row": {
                backgroundColor: "#fff",
                marginBottom: "5px"
              }
            }
          : customStyles
      }
    >
      <StyledDataGrid
        rows={data}
        columns={columns}
        initialState={initialState}
        getRowClassName={() => `super-app-theme--row`}
        checkboxSelection={checkboxVisible}
        disableColumnMenu
        pagination={paginationVisible}
        hideFooterSelectedRowCount={hideSelectedRowCount}
        hideFooter={hideFooter}
        paginationServer
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          ColumnUnsortedIcon: ({ sortingOrder, ...other }) => <ArrowDropUp {...other} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          ColumnSortedAscendingIcon: ({ sortingOrder, ...other }) => <ArrowDropUp {...other} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          ColumnSortedDescendingIcon: ({ sortingOrder, ...other }) => <ArrowDropDown {...other} />
        }}
        onCellClick={onCellClickHandler}
        {...(sortingMode ? { sortingMode: "server" } : {})}
        {...rest}
      />
    </Box>
  );
}

Table.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  loading: PropTypes.bool,
  pagination: PropTypes.bool,
  paginationServer: PropTypes.bool,
  handlePageChange: PropTypes.func,
  onCellClick: PropTypes.func,
  checkboxVisible: PropTypes.bool,
  paginationVisible: PropTypes.bool,
  hideSelectedRowCount: PropTypes.bool,
  hideFooter: PropTypes.bool,
  sortingMode: PropTypes.bool
};

Table.defaultProps = {
  data: [],
  columns: [],
  handlePageChange: () => {},
  onCellClick: () => {},
  loading: false,
  pagination: false,
  paginationServer: false,
  checkboxVisible: true,
  paginationVisible: true,
  hideSelectedRowCount: true,
  hideFooter: false,
  sortingMode: true
};
