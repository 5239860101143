import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "router";
import {
  getAWSAccessAccount,
  getAwsRegions,
  updateConnection
} from "store/entities/cloudAccounts/actions";
import { getAccountInfo, getAwsRegionsSelector } from "store/entities/cloudAccounts/selectors";
import { getLabelsMini } from "store/entities/labels/actions";
import { labelsMiniSelector } from "store/entities/labels/selectors";
import { getTagsMini } from "store/entities/tags/actions";
import { tagsMiniSelector } from "store/entities/tags/selectors";
import { Button, Checkbox, Input, Select } from "components/simple";

import styles from "../styles.module.scss";

export const accountType = {
  value: "Amazon Web Service (AWS)",
  label: "Amazon Web Service (AWS)"
};

const connectionMethod = {
  value: "Access Keys",
  label: "Access Keys"
};

const EditAWSAccess = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const accountInfo = useSelector(getAccountInfo);
  const labels = useSelector(labelsMiniSelector);
  const tags = useSelector(tagsMiniSelector);
  const awsRegions = useSelector(getAwsRegionsSelector);

  const [state, setState] = useState({
    secret_access_key: "",
    access_key_id: "",
    region: []
  });
  const [label, setLabel] = useState(null);
  const [tag, setTag] = useState(null);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  const onCancelHandler = () => {
    history.push(routes.cloudAccounts);
  };

  const onRegionChange = region => value => {
    if (value) {
      setState(prevSt => ({ ...prevSt, region: [...prevSt.region, region] }));
      return;
    }
    setState(prevSt => ({ ...prevSt, region: [...prevSt.region.filter(reg => reg !== region)] }));
  };

  const onSelectAll = () => {
    if (state.region.length === awsRegions.length) {
      setState(prev => ({ ...prev, region: [] }));
      return;
    }
    setState(prev => ({ ...prev, region: awsRegions.map(reg => reg.value) }));
  };

  const onSubmit = e => {
    e.preventDefault();
    const data = {
      secret_access_key: state.secret_access_key,
      access_key_id: state.access_key_id,
      region: state.region,
      label: label.value,
      tag: tag?.value
    };
    dispatch(updateConnection({ accountType: "aws", data, id: params.id }));
  };

  useEffect(() => {
    if (!isEmpty(accountInfo)) {
      setState({
        secret_access_key: accountInfo.secret_access_key,
        access_key_id: accountInfo.access_key_id,
        region: accountInfo.region
      });
    }
  }, [accountInfo]);

  useEffect(() => {
    if (labels.length && accountInfo.label) {
      const labelValue = labels.find(item => item.value === accountInfo.label);
      setLabel(labelValue);
    }
  }, [labels, accountInfo.label]);

  useEffect(() => {
    if (tags.length && accountInfo.tag) {
      const tagValue = tags.find(item => item.value === accountInfo.tag);
      setTag(tagValue);
    }
  }, [tags, accountInfo.tag]);

  useEffect(() => {
    dispatch(getAWSAccessAccount(params.id));
    dispatch(getLabelsMini());
    dispatch(getTagsMini());
    dispatch(getAwsRegions());
  }, []);

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={styles.fieldContainer}>
        <Select label="Cloud Account Type" value={accountType} isDisabled />
        <Select label="Connection Method" value={connectionMethod} isDisabled />
      </div>
      <div className={styles.fieldContainer}>
        <Select
          label="Label"
          placeholder="Select the label"
          options={labels}
          value={label}
          onChange={val => setLabel(val)}
          required
        />
        <Select
          label="Tag"
          placeholder="Select the tag"
          options={tags}
          value={tag}
          onChange={val => setTag(val)}
        />
      </div>
      <div className={styles.fieldContainer}>
        <Input
          type="text"
          label="Access Key Id"
          value={state.access_key_id}
          onChange={val => handleChange(val.target.value, "access_key_id")}
          required
        />
        <Input
          type="text"
          label="Secret Access Key"
          value={state.secret_access_key}
          onChange={val => handleChange(val.target.value, "secret_access_key")}
          required
        />
      </div>
      <div className={styles.regions}>
        <div className={styles.regionButtons}>
          <div>Region</div>
          <div className={styles.select} onClick={onSelectAll}>
            {state.region.length === awsRegions.length ? "Unselect All" : "Select All"}
          </div>
        </div>
        <div className={styles.regionsContainer}>
          {awsRegions.map(region => (
            <Checkbox
              key={region.value}
              label={region.label}
              onChange={onRegionChange(region.label)}
              value={state.region.includes(region.label)}
            />
          ))}
        </div>
      </div>
      <div className={styles.btnContainer}>
        <Button variant="outline" onClick={onCancelHandler}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

export default EditAWSAccess;
