import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const tenant = sessionStorage.getItem("tenant") || localStorage.getItem("tenant");
const opt = JSON.parse(tenant);

const initialState = {
  userProfile: {},
  getUserSuccess: false,
  getTenantSuccess: false,
  tenants: [],
  selectTenant: opt,
  resetPasswordModal: false,
  emailNotificationSubscriptions: [],
  showMfaModal: false,
  MFASuccess: false
};

const reducer = createReducer(
  initialState,
  {
    [actions.getUserProfileSuccess.type]: (state, action) => ({
      ...state,
      userProfile: action.payload,
      getUserSuccess: true
    }),
    [actions.getTenantsSuccess.type]: (state, action) => ({
      ...state,
      tenants: action.payload,
      getTenantSuccess: true
    }),
    [actions.setTenant.type]: (state, action) => ({
      ...state,
      selectTenant: action.payload
    }),

    [actions.openChangePasswordModal.type]: state => ({
      ...state,
      resetPasswordModal: true
    }),
    [actions.closeChangePasswordModal.type]: state => ({
      ...state,
      resetPasswordModal: false
    }),

    [actions.getUserProfileFailed.type]: state => ({
      ...state,
      getUserSuccess: false
    }),

    [actions.emailNotificationSubscriptionsSuccess.type]: (state, action) => ({
      ...state,
      emailNotificationSubscriptions: action.payload
    }),
    [actions.mfaModal.type]: (state, action) => ({
      ...state,
      showMfaModal: action.payload
    }),
    [actions.MFASuccessModal.type]: (state, action) => ({
      ...state,
      MFASuccess: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
