import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "router";
import { getGCPAccount, updateConnection } from "store/entities/cloudAccounts/actions";
import { getAccountInfo } from "store/entities/cloudAccounts/selectors";
import { getLabelsMini } from "store/entities/labels/actions";
import { labelsMiniSelector } from "store/entities/labels/selectors";
import { getTagsMini } from "store/entities/tags/actions";
import { tagsMiniSelector } from "store/entities/tags/selectors";
import { Button, Input, Select, Textarea } from "components/simple";

import styles from "../styles.module.scss";

const accountType = {
  value: "Google Cloud Platform (GCP)",
  label: "Google Cloud Platform (GCP)"
};

const EditGCPAccount = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const accountInfo = useSelector(getAccountInfo);
  const labels = useSelector(labelsMiniSelector);
  const tags = useSelector(tagsMiniSelector);

  const [state, setState] = useState({
    projectId: "",
    clientEmail: "",
    privateKey: ""
  });
  const [label, setLabel] = useState(null);
  const [tag, setTag] = useState(null);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  const onCancelHandler = () => {
    history.push(routes.cloudAccounts);
  };

  const onSubmit = e => {
    e.preventDefault();
    const data = {
      project_id: state.projectId,
      client_email: state.clientEmail,
      private_key: state.privateKey,
      label: label.value,
      tag: tag?.value
    };
    dispatch(updateConnection({ accountType: "gcp", data, id: params.id }));
  };

  useEffect(() => {
    if (!isEmpty(accountInfo)) {
      setState({
        projectId: accountInfo.project_id,
        clientEmail: accountInfo.client_email,
        private_key: accountInfo.privateKey
      });
    }
  }, [accountInfo]);

  useEffect(() => {
    if (labels.length && accountInfo.label) {
      const labelValue = labels.find(item => item.value === accountInfo.label);
      setLabel(labelValue);
    }
  }, [labels, accountInfo.label]);

  useEffect(() => {
    if (tags.length && accountInfo.tag) {
      const tagValue = tags.find(item => item.value === accountInfo.tag);
      setTag(tagValue);
    }
  }, [tags, accountInfo.tag]);

  useEffect(() => {
    dispatch(getGCPAccount(params.id));
    dispatch(getLabelsMini());
    dispatch(getTagsMini());
  }, []);

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={styles.fieldContainer}>
        <Select label="Cloud Account Type" value={accountType} isDisabled />
      </div>
      <div className={styles.fieldContainer}>
        <Select
          label="Label"
          placeholder="Select the label"
          options={labels}
          value={label}
          onChange={val => setLabel(val)}
          required
        />
        <Select
          label="Tag"
          placeholder="Select the tag"
          options={tags}
          value={tag}
          onChange={val => setTag(val)}
        />
      </div>
      <div className={styles.fieldContainer}>
        <Input
          type="text"
          label="Project ID"
          value={state.projectId}
          onChange={val => handleChange(val.target.value, "projectId")}
          required
        />
        <Input
          type="email"
          label="Client Email"
          value={state.clientEmail}
          onChange={val => handleChange(val.target.value, "clientEmail")}
          required
        />
      </div>
      <Textarea
        label="Private Key"
        placeholder="Enter Your Private Key"
        value={state.privateKey}
        onChange={val => handleChange(val, "privateKey")}
        required
      />
      <div className={styles.btnContainer}>
        <Button variant="outline" onClick={onCancelHandler}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

export default EditGCPAccount;
