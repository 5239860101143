/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { onChangeUrlParams, useQuery } from "helper/history";

import {
  setDataStructureLabelsParams,
  getLabels,
  createLabel,
  deleteLabel
} from "store/entities/labels/actions";

import {
  dataStructureLabelsSelector,
  dataStructureLabelsTableColumns
} from "store/entities/labels/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Title, Search, Button } from "components/simple";

import { Table } from "components/complex";
import AddLabelModal from "./AddLabelModal";

import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";

const Labels = () => {
  const initialModalState = {
    name: "",
    aws_prefix: ""
  };
  const [modalState, setModalState] = useState({ ...initialModalState });
  const [isModalShown, setModalShown] = useState(false);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getLabels.type])(state));
  const isSaveLoading = useSelector(state => createLoadingSelector([createLabel.type])(state));
  const isDeleteLoading = useSelector(state => createLoadingSelector([deleteLabel.type])(state));

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const { page, pageSize, search } = useSelector(state => state.labels.dataStructureLabelsParams);
  const { data, total } = useSelector(dataStructureLabelsSelector);

  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");

  const { state } = useLocation();

  const inputChangeHandler = (val, name) => {
    setModalState({
      ...modalState,
      [name]: val
    });
  };

  // get default request
  const getRequest = () => {
    dispatch(
      setDataStructureLabelsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: state?.title ?? ""
      })
    );
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setDataStructureLabelsParams({
        page: 0,
        search: v
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setDataStructureLabelsParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setDataStructureLabelsParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setDataStructureLabelsParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = newPage => {
    prevSelectionModel.current = selectionModel;
    onChangeUrlParams("page", newPage + 1);

    if (page !== -1) {
      dispatch(
        setDataStructureLabelsParams({
          page: newPage
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setDataStructureLabelsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
  };

  const closeModalHandler = () => {
    setModalShown(false);
    setModalState({ ...initialModalState });
  };

  const addLabelHandler = e => {
    e.preventDefault();
    dispatch(createLabel({ data: modalState, callback: closeModalHandler }));
  };

  const deleteLabelHandler = () => {
    dispatch(deleteLabel({ ids: selectionModel }));
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Labels</Title>
      <div className={styles.searchRow}>
        <Search value={search} onSearch={handleSearch} />
        <div className={styles.btnsContainer}>
          <Button icon="plus" className={styles.btn} onClick={() => setModalShown(true)}>
            Add labels
          </Button>
          <Button
            className={styles.btn}
            onClick={deleteLabelHandler}
            isLoading={isDeleteLoading}
            disabled={!selectionModel.length}
          >
            Delete
          </Button>
        </div>
      </div>
      <div className={styles.table}>
        <Table
          data={isLoading ? [] : data}
          columns={dataStructureLabelsTableColumns}
          loading={isLoading}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          page={page || 0}
          selectionModel={selectionModel}
          onSortModelChange={model => handleSort(model)}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          onPageChange={onPageChange}
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
        />
      </div>
      <AddLabelModal
        isOpen={isModalShown}
        state={modalState}
        onChange={inputChangeHandler}
        onSubmit={addLabelHandler}
        onClose={closeModalHandler}
        isLoading={isSaveLoading}
      />
    </ContentContainer>
  );
};

Labels.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Labels;
