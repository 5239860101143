import React from "react";
import { Switch } from "@mui/material";

import styles from "./styles.module.scss";

const SwitchBox = ({ className, label, expanded, onExpand, children }) => {
  const handleExpand = evt => {
    if (onExpand) {
      onExpand(evt.target.checked);
    }
  };

  let bodyClass = styles.switchBoxBody;
  if (className) {
    bodyClass += ` ${className}`;
  }

  return (
    <div className={bodyClass}>
      <div className={styles.switchBoxHeader}>
        <div className={styles.switchBoxLabel}>{label}</div>
        <Switch checked={expanded} onChange={handleExpand} />
      </div>
      <div className={styles.switchBoxContent}>{expanded && children}</div>
    </div>
  );
};

export default SwitchBox;
