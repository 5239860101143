import React, { useState, useEffect } from "react";

import { useLocation, Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import { useBasePath } from "helper/history";

import { Icon } from "components/simple";

import styles from "./styles.module.scss";

const BreadcrumbsComponent = ({ className }) => {
  const [crumbs, setCrumbs] = useState([]);
  const location = useLocation();
  const basePath = useBasePath();

  useEffect(() => {
    const currentPath = basePath.split("/");
    const parts = [{ text: "home", link: "/" }];
    if (currentPath !== "/") {
      currentPath.shift();
      currentPath.forEach((part, i) => {
        const link = `/${currentPath.slice(0, i + 1).join("/")}`;
        if (i === currentPath?.length - 1) {
          parts.push({ text: part, link: location.pathname });
        } else {
          parts.push({ text: part, link });
        }
      });
    }
    setCrumbs(parts);
  }, [location.pathname]);

  const renderBreadcrumbs = () =>
    crumbs.map((crumb, index) => (
      <Link
        key={index}
        to={crumb.link}
        className={`${location.pathname === crumb.link ? styles.current : ""} ${styles.link}`}
      >
        {crumb.text}
      </Link>
    ));

  return (
    <Stack spacing={2} className={className}>
      <Breadcrumbs
        separator={<Icon.DropDownArrow color="#A1A9BE" className={styles.separator} />}
        aria-label="breadcrumb"
        className={styles.linksContainer}
      >
        {renderBreadcrumbs()}
      </Breadcrumbs>
    </Stack>
  );
};

export default BreadcrumbsComponent;
