/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";

import { routes } from "router";
import { onChangeUrlParams, useQuery } from "helper/history";

import {
  getAssets,
  setAssetsParams,
  getAssetFilterChoices,
  actionGroupModal
} from "store/entities/assets/actions";
import { assetsSelector, assetFilterChoicesSelector } from "store/entities/assets/selectors";

import { getLabelList } from "store/entities/labels/actions";
import { getLabelListSelector } from "store/entities/labels/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";

import {
  ContentContainer,
  Title,
  Select,
  Search,
  ActionButton,
  DateRangePicker
} from "components/simple";

import { Table } from "components/complex";
import { CreateGroupModal } from "./CreateGroupModal";

import { TargetsSelect, GroupSelect } from "helper/AsyncSelects";

import styles from "./styles.module.scss";
import VisualDashboard from "./VisualDashboard";

const Assets = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");
  const queryFilter = query.get("filter");

  const [selectionModel, setSelectionModel] = useState([]);
  const [datePicker, setDatePicker] = useState([new Date(Date.now() - 12096e5), new Date()]);
  const prevSelectionModel = useRef(selectionModel);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getAssets.type])(state));
  // filters
  const listLabel = useSelector(getLabelListSelector);

  const { data_types, obj_type } = useSelector(assetFilterChoicesSelector);

  const { page, pageSize, filters } = useSelector(state => state.assets.params);
  const { columns, data, total } = useSelector(state => assetsSelector(state));

  // get default request
  const getRequest = () => {
    const asset_type = queryFilter ? { label: queryFilter, value: queryFilter } : "";

    dispatch(
      setAssetsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: "",
        filters: {
          ...(asset_type ? { asset_type } : {})
        }
      })
    );
  };

  useEffect(() => {
    getRequest();
    dispatch(getLabelList());
    dispatch(getAssetFilterChoices());
  }, [selectTenant]);

  // Search
  const handleSearch = text => {
    onChangeUrlParams("page", 0);
    dispatch(
      setAssetsParams({
        page: 0,
        search: text
      })
    );
  };

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setAssetsParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setAssetsParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setAssetsParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    prevSelectionModel.current = selectionModel;

    if (page !== -1) {
      dispatch(
        setAssetsParams({
          page: newPage
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setAssetsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
    dispatch(
      setAssetsParams({
        page: 0
      })
    );
    history.push(`${routes.dataStructureAssetsDetails}/${p?.id}`);
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  const hangleChangeFilter = (val, key) => {
    onChangeUrlParams("filter", "");
    const res = {
      ...filters,
      [key]: val
    };
    dispatch(
      setAssetsParams({
        filters: res,
        page: 0,
        search: "",
        ordering: ""
      })
    );
  };

  const hangleChangeDate = v => {
    setDatePicker(v);
  };

  const onAddToGroup = () => {
    const ids = selectionModel;
    dispatch(actionGroupModal({ show: true, data: ids }));
  };

  return (
    <>
      <ContentContainer className={styles.container}>
        <Title>Assets</Title>
        <DateRangePicker
          className={styles.datePicker}
          placeholder="Filter by date"
          onChange={v => hangleChangeDate(v)}
          value={datePicker || ""}
          clearable={false}
        />
      </ContentContainer>
      <VisualDashboard date={datePicker} />
      <ContentContainer className={styles.tableContainer}>
        <div className={styles.searchRow}>
          <Search onSearch={handleSearch} />
          <div className={styles.filters}>
            <Select
              containerClass={styles.select}
              onChange={v => hangleChangeFilter(v, "label")}
              value={filters?.label || ""}
              placeholder="Filter by label"
              options={listLabel}
              isClearable
            />
            <TargetsSelect
              containerClass={styles.select}
              onChange={v => hangleChangeFilter(v, "target")}
              value={filters?.target || ""}
              placeholder="Filter by target"
              isClearable
            />
            <GroupSelect
              containerClass={styles.select}
              onChange={v => hangleChangeFilter(v, "group")}
              value={filters?.group || ""}
              placeholder="Filter by group"
              isClearable
            />
            <Select
              containerClass={styles.select}
              onChange={v => hangleChangeFilter(v, "asset_type")}
              value={filters?.asset_type || ""}
              placeholder="Filter by asset type"
              options={obj_type}
              isClearable
            />
            <Select
              containerClass={styles.select}
              onChange={v => hangleChangeFilter(v, "data_types")}
              value={filters?.data_types || ""}
              placeholder="Filter by data type"
              options={data_types}
              isClearable
            />
          </div>
          <ActionButton type="addToGroup" onClick={onAddToGroup} className={styles.actionButton} />
        </div>
        <div className={styles.table}>
          <Table
            data={isLoading ? [] : data}
            columns={columns}
            loading={isLoading}
            page={page || 0}
            onSortModelChange={model => handleSort(model)}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            initialState={{ pinnedColumns: { left: ["__check__"] } }}
            rowCount={total}
            paginationMode="server"
            onPageChange={onPageChange}
            onCellClick={(p, e) => onCellClick(p, e)}
            customStyles={customStyles}
          />
        </div>
        <CreateGroupModal />
      </ContentContainer>
    </>
  );
};

Assets.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Assets;
