import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const useStyles = makeStyles(() => ({
  flexContainer: {
    width: "100%",
    justifyContent: "space-between"
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 0 }}>{children}</Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

TabPanel.defaultProps = {
  children: <div></div>
};

const tabStyle = {
  fontSize: "14px",
  fontFamily: "PoppinsMedium",
  textTransform: "capitalize"
};

const BasicTabs = ({ tabs, handleChange, value, spaceBetween }) => {
  const classes = useStyles();
  return (
    <Box sx={{ width: "100%", padding: 0 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "7px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          variant="scrollable"
          scrollButtons="auto"
          classes={{ flexContainer: spaceBetween ? classes.flexContainer : null }}
        >
          {tabs.map((tab, index) => (
            <Tab
              sx={{ ...tabStyle }}
              key={tab?.id}
              label={tab.label}
              disabled={tab.disabled}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={tab?.id} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default BasicTabs;
