import { Button, Checkbox, Input, Select } from "components/simple";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAwsCloudAccessKey, getAwsRegions } from "store/entities/cloudAccounts/actions";
import { getAwsRegionsSelector } from "store/entities/cloudAccounts/selectors";
import StepsToGet from "../StepsToGet";

import styles from "./styles.module.scss";

const steps = {
  secretAccessKey: {
    title: "Access Key",
    steps: (
      <div>
        <div className={styles.stepTitle}>Via console:</div>
        <p className={styles.step}>
          1. Use your AWS account ID or account alias, your IAM user name, and your password to sign
          in to the{" "}
          <a
            target="_blank"
            href="https://us-east-1.console.aws.amazon.com/iamv2/home#/home"
            rel="noreferrer"
          >
            IAM console
          </a>
          .
        </p>
        <p className={styles.step}>
          2. In the navigation bar on the upper right, choose your user name, and then choose
          <b> Security credentials</b>.
        </p>
        <p className={styles.step}>
          3. Expand the <b>Access keys (access key ID and secret access key)</b> section.
        </p>
        <p className={styles.step}>
          4. Do any of the following: To create an access key, choose <b>Create New Access Key</b>.
        </p>
        <div className={styles.stepTitle}>Via AWS CLI:</div>
        <div className={styles.step}>To create an access key:</div>
        <div className={styles.command}>aws iam create-access-key </div>
        <div className={styles.stepTitle}>Permissions:</div>
        <div className={styles.step}>
          Grant the <span className={styles.readOnly}>ReadOnlyAccess</span> policy to your user or
          role.
        </div>
      </div>
    )
  }
};

const AccessKeys = ({ onBackHandler, onCancelHandler, label, tag }) => {
  const dispatch = useDispatch();
  const awsRegions = useSelector(getAwsRegionsSelector);
  const [getSteps, setGetSteps] = useState("");
  const [state, setState] = useState({
    accessKeyId: "",
    secretAccessKey: "",
    region: []
  });

  const onSelectAll = () => {
    if (state.region.length === awsRegions.length) {
      setState(prev => ({ ...prev, region: [] }));
      return;
    }
    setState(prev => ({ ...prev, region: awsRegions.map(reg => reg.value) }));
  };

  const onRegionChange = region => value => {
    if (value) {
      setState(prevSt => ({ ...prevSt, region: [...prevSt.region, region] }));
      return;
    }
    setState(prevSt => ({ ...prevSt, region: [...prevSt.region.filter(reg => reg !== region)] }));
  };

  const onSave = e => {
    e.preventDefault();
    const data = {
      secret_access_key: state.secretAccessKey,
      access_key_id: state.accessKeyId,
      region: state.region,
      label: label.value,
      tag: tag?.value || null
    };
    dispatch(addAwsCloudAccessKey(data));
  };

  const closeSteps = useCallback(() => {
    setGetSteps("");
  }, [setGetSteps]);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  useEffect(() => {
    dispatch(getAwsRegions());
  }, []);

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSave}>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Access Key ID*</span>
          </div>
          <Input
            type="text"
            placeholder="Enter the Access Key ID*"
            value={state.accessKeyId}
            onChange={val => handleChange(val.target.value, "accessKeyId")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Secret Access Key*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("secretAccessKey")}>
              Show steps
            </span>
          </div>
          <Input
            type="text"
            placeholder="Enter the Secret Access Key"
            value={state.secretAccessKey}
            onChange={val => handleChange(val.target.value, "secretAccessKey")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Region*</span>
            <div className={styles.select} onClick={onSelectAll}>
              {state.region.length === awsRegions.length ? "Unselect All" : "Select All"}
            </div>
          </div>
          <div className={styles.regionsContainer}>
            {awsRegions.map(region => (
              <Checkbox
                key={region.value}
                label={region.label}
                onChange={onRegionChange(region.label)}
                value={state.region.includes(region.label)}
              />
            ))}
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button variant="outline" onClick={onBackHandler}>
            Back
          </Button>
          <Button variant="outline" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Connect</Button>
        </div>
      </form>
      {getSteps && (
        <div className={styles.stepsContainer}>
          <StepsToGet steps={steps[getSteps]} onClose={closeSteps} />
        </div>
      )}
    </div>
  );
};

export default AccessKeys;
