import React, { useCallback, useEffect, useState } from "react";
import { Button, ContentContainer, Select } from "components/simple";
import Stepper from "components/simple/Stepper";

import styles from "./styles.module.scss";
import { Divider } from "@mui/material";
import { TabPanel } from "components/simple/Tabs/Tabs";
import { useHistory } from "react-router-dom";
import { routes } from "router";
import AzureForm from "./AzureForm";
import GoogleCloudForm from "./GoogleCloudForm";
import { AccessKeys, ManualSetup } from "./AWSForm";
import { useDispatch, useSelector } from "react-redux";
import { getTagsMini } from "store/entities/tags/actions";
import { getLabelsMini } from "store/entities/labels/actions";
import { labelsMiniSelector } from "store/entities/labels/selectors";
import { tagsMiniSelector } from "store/entities/tags/selectors";

const steps = ["Cloud Account Details", "Label & Tag", "Set Up Connectivity"];
const cloudTypeOptions = [
  {
    value: "Microsoft Azure",
    label: "Microsoft Azure"
  },
  {
    value: "Google Cloud Platform (GCP)",
    label: "Google Cloud Platform (GCP)"
  },
  {
    value: "Amazon Web Service (AWS)",
    label: "Amazon Web Service (AWS)"
  }
];
const connectionMethodOptions = [
  {
    value: "Manual Setup",
    label: "Manual Setup"
  },
  {
    value: "Access Keys",
    label: "Access Keys"
  }
];

const AddAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState({
    cloudAccountType: null,
    connectionMethod: null,
    labelSelected: null,
    tagSelected: null
  });

  const labels = useSelector(labelsMiniSelector);
  const tags = useSelector(tagsMiniSelector);

  const fieldsHandler = (val, field) => {
    setState({ ...state, [field]: val });
  };

  const onCancelHandler = useCallback(() => {
    history.push(routes.cloudAccounts);
  }, []);

  const onNextHandler = e => {
    e.preventDefault();
    setActiveStep(st => st + 1);
  };

  const onBackHandler = useCallback(() => {
    setActiveStep(st => st - 1);
  }, [setActiveStep]);

  useEffect(() => {
    dispatch(getLabelsMini());
    dispatch(getTagsMini());
  }, []);

  const renderForm = () => {
    switch (state.cloudAccountType?.value) {
      case "Microsoft Azure":
        return (
          <AzureForm
            onBackHandler={onBackHandler}
            onCancelHandler={onCancelHandler}
            label={state.labelSelected}
            tag={state.tagSelected}
          />
        );
      case "Google Cloud Platform (GCP)":
        return (
          <GoogleCloudForm
            onBackHandler={onBackHandler}
            onCancelHandler={onCancelHandler}
            label={state.labelSelected}
            tag={state.tagSelected}
          />
        );
      case "Amazon Web Service (AWS)":
        if (state.connectionMethod?.value === "Manual Setup") {
          return (
            <ManualSetup
              onBackHandler={onBackHandler}
              onCancelHandler={onCancelHandler}
              label={state.labelSelected}
              tag={state.tagSelected}
            />
          );
        }
        if (state.connectionMethod?.value === "Access Keys") {
          return (
            <AccessKeys
              onBackHandler={onBackHandler}
              onCancelHandler={onCancelHandler}
              label={state.labelSelected}
              tag={state.tagSelected}
            />
          );
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <div className={styles.stepperWrapper}>
        <Stepper steps={steps} activeStep={activeStep} className={styles.stepper} />
      </div>
      <Divider />
      <TabPanel value={activeStep} index={0}>
        <div className={styles.tabPanel}>
          <form onSubmit={onNextHandler} className={styles.selectTypePage}>
            <Select
              label="Cloud Account Type"
              placeholder="Select the Cloud Account Type"
              options={cloudTypeOptions}
              value={state.cloudAccountType}
              onChange={val => fieldsHandler(val, "cloudAccountType")}
              required
            />
            {state.cloudAccountType?.value === "Amazon Web Service (AWS)" && (
              <Select
                label="Connection Method"
                placeholder="Select the Connection Method"
                options={connectionMethodOptions}
                value={state.connectionMethod}
                onChange={val => fieldsHandler(val, "connectionMethod")}
                required
              />
            )}
            <div className={styles.btnContainer}>
              <Button variant="outline" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </div>
          </form>
        </div>
      </TabPanel>
      <TabPanel value={activeStep} index={1} style={{ width: "100%" }}>
        <div className={styles.tabPanel}>
          <form onSubmit={onNextHandler} className={styles.selectTypePage}>
            <Select
              label="Label"
              placeholder="Select the label"
              options={labels}
              value={state.labelSelected}
              onChange={val => fieldsHandler(val, "labelSelected")}
              required
            />
            <Select
              label="Tag"
              placeholder="Select the tag"
              options={tags}
              value={state.tagSelected}
              onChange={val => fieldsHandler(val, "tagSelected")}
            />
            <div className={styles.btnContainer}>
              <Button variant="outline" onClick={onBackHandler}>
                Back
              </Button>
              <Button variant="outline" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </div>
          </form>
        </div>
      </TabPanel>
      <TabPanel value={activeStep} index={2} style={{ width: "100%" }}>
        <div className={styles.tabPanel}>{renderForm()}</div>
      </TabPanel>
    </ContentContainer>
  );
};

export default AddAccount;
