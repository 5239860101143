import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { routes } from "router";
import { navigate } from "helper/history";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "../notifications/actions";

const getFilterConfigParams = state => {
  return {
    id: state.baseline.baselineConfigParams.id,
    page: state.baseline.baselineConfigParams.page,
    pageSize: state.baseline.baselineConfigParams.pageSize,
    ordering: state.baseline.baselineConfigParams.ordering,
    search: state.baseline.baselineConfigParams.search,
    filters: state.baseline.baselineConfigParams.filters,
    groupBy: state.baseline.baselineConfigParams.groupBy,
    baselineConfigTemporaryDataFilter:
      state.baseline.baselineConfigParams.baselineConfigTemporaryData?.filters,
    baselineConfigTemporaryData: state.baseline.baselineConfigParams.baselineConfigTemporaryData
  };
};

const getFilterHistoryParams = state => {
  return {
    id: state.baseline.baselineHistoryParams.id,
    page: state.baseline.baselineHistoryParams.page,
    pageSize: state.baseline.baselineHistoryParams.pageSize,
    ordering: state.baseline.baselineHistoryParams.ordering,
    search: state.baseline.baselineHistoryParams.search
  };
};

const getFailedAssetsParams = state => {
  return {
    page: state.baseline.baselineFailedAssetsParams.page,
    pageSize: state.baseline.baselineFailedAssetsParams.pageSize,
    ordering: state.baseline.baselineFailedAssetsParams.ordering,
    search: state.baseline.baselineFailedAssetsParams.search
  };
};

const getAssignHostsParams = state => {
  return {
    label: state.baseline.assignHostsParams.label,
    search: state.baseline.assignHostsParams.search,
    baseline_id: state.baseline.assignHostsParams.baseline_id
  };
};

const getAssignGroupsParams = state => {
  return {
    search: state.baseline.assignGroupsParams.search
  };
};

const getFilter = state => {
  return {
    page: state.baseline.page,
    pageSize: state.baseline.pageSize,
    ordering: state.baseline.ordering,
    search: state.baseline.search,
    filter: state.baseline.filter,
    label: state.baseline.label,
    asset_id: state.baseline.asset_id
  };
};

const getAuditChoiceFilter = state => {
  return {
    page: state.baseline.auditChoicesParams.page,
    pageSize: state.baseline.auditChoicesParams.pageSize,
    ordering: state.baseline.auditChoicesParams.ordering,
    search: state.baseline.auditChoicesParams.search,
    label: state.baseline.auditChoicesParams.label,
    source: state.baseline.auditChoicesParams.source,
    audit_file: state.baseline.auditChoicesParams.audit_file,
    filter: state.baseline.auditChoicesParams.filter
  };
};

// Watchers //

// Set Baseline Params
function* setBaselineParamsWorker() {
  const { page, pageSize, ordering, search, filter, label, asset_id } = yield select(getFilter);
  yield put(
    actions.getBaselines({
      page,
      pageSize,
      ordering,
      search,
      filter,
      label,
      asset_id
    })
  );
}

// Get Baselines
function* getBaselinesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselines), payload);
    yield put(actions.getBaselinesSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getBaselinesFailed(error));
  }
}

// Get Baseline
function* getBaselineWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaseline), payload);
    yield put(actions.getBaselineSuccess(response));
  } catch (error) {
    yield put(actions.getBaselineFailed(error));
  }
}

// Set Baseline Assets Params
function* setBaselineAssetsParamsWorker({ payload }) {
  const { id } = payload;
  const { page, pageSize, ordering, search } = yield select(getFailedAssetsParams);
  yield put(
    actions.getBaselineAssets({
      page,
      pageSize,
      ordering,
      search,
      id
    })
  );
}

// Get Baseline Assets
function* getBaselineAssetsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselineAssets), payload);
    yield put(actions.getBaselineAssetsSuccess(response));
  } catch (error) {
    yield put(actions.getBaselineAssetsFailed(error));
  }
}

// Get baseline analysis mini
function* getBaselineAnalysisMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselineAnalysisMini), payload);

    yield put(actions.getBaselineAnalysisMiniSuccess(response));
  } catch (error) {
    yield put(actions.getBaselineAnalysisMiniFailed(error));
  }
}

// Add Baseline Asset
function* addBaselineAssetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addBaselineAsset), payload);
    yield put(actions.addBaselineAssetSuccess(response));
    yield put(actions.getBaselineSuccess(response));
    yield put(
      addNotification({
        msg: `${payload?.data?.ids?.length > 1 ? "Assets" : "Asset"} added successfully`
      })
    );
  } catch (error) {
    yield put(actions.addBaselineAssetFailed(error));
    yield put(addNotification({ msg: error, type: "error" }));
  }
}

// Delete Baseline Asset
function* deleteBaselineAssetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteBaselineAsset), payload);
    yield put(actions.deleteBaselineAssetSuccess(response));
    yield put(actions.getBaselineSuccess(response));
    yield put(addNotification({ msg: "Asset deleted successfully" }));
  } catch (error) {
    yield put(actions.deleteBaselineAssetFailed(error));
    yield put(addNotification({ msg: error, type: "error" }));
  }
}

// Add Baseline Group
function* addBaselineGroupWorker({ payload }) {
  const { id, ids } = payload;
  try {
    const response = yield all(
      ids.map(group => call(makeRequest(service.addBaselineGroup), { id, data: { group } }))
    );
    yield put(actions.addBaselineGroupSuccess(response));
    yield put(actions.getBaseline({ id }));
    yield put(
      addNotification({ msg: `${ids?.length > 1 ? "Groups" : "Group"} added successfully` })
    );
  } catch (error) {
    yield put(actions.addBaselineGroupFailed(error));
    yield put(addNotification({ msg: error, type: "error" }));
  }
}

// Delete Baseline Group
function* deleteBaselineGroupWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteBaselineGroup), payload);
    yield put(actions.deleteBaselineGroupSuccess(response));
    yield put(actions.getBaselineSuccess(response));
    yield put(addNotification({ msg: "Group deleted successfully" }));
  } catch (error) {
    yield put(actions.deleteBaselineGroupFailed(error));
    yield put(addNotification({ msg: error, type: "error" }));
  }
}

// Get Baseline Control
function* getBaselineControlWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselineControl), payload);
    yield put(actions.getBaselineControlSuccess(response));
  } catch (error) {
    yield put(actions.getBaselineControlFailed(error));
  }
}

// Get Baseline Tag Data
function* getBaselineTagDataWorker({ payload }) {
  try {
    const data = yield select(state => state.baseline.baselineConfigTable);
    const baselineTagData = data?.results.find(item => item.id === payload.id);
    const controlResponse = yield call(makeRequest(service.getBaselineControl), payload);

    yield all([
      put(actions.getBaselineControlSuccess(controlResponse)),
      put(actions.getBaselineTagByIdSuccess(baselineTagData))
    ]);
  } catch (error) {
    yield put(actions.getBaselineControlFailed(error));
    yield put(actions.getBaselineTagByIdFailed(error));
  }
}

// Edit Baseline
function* editBaselineWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editBaseline), payload);
    yield put(actions.editBaselineSuccess(response));
    yield put(addNotification({ msg: "Baseline updated successfully" }));
    navigate(`${routes.baselineConfiguration}/${response?.id}`);
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.editBaselineFailed(error));
  }
}

function* editBaselineFieldsWorker() {
  try {
    const { id, baselineConfigTemporaryData } = yield select(getFilterConfigParams);
    const res = {
      id,
      display_fields: baselineConfigTemporaryData?.display_fields || [],
      filter_fields: baselineConfigTemporaryData?.filter_fields || [],
      table_properties: {
        display_fields_width: baselineConfigTemporaryData?.display_fields_width || []
      }
    };
    const response = yield call(makeRequest(service.editBaseline), res);
    yield put(actions.editBaselineFieldsSuccess(response));
    yield put(addNotification({ msg: "Baseline fields updated successfully" }));

    yield put(actions.setBaselineConfigParams());
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.editBaselineFieldsFailed(error));
  }
}

// Create Baseline
function* createBaselineWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.createBaseline), payload);
    yield put(actions.createBaselineSuccess(response));
    yield put(addNotification({ msg: "Baseline created successfully" }));
    navigate(`${routes.baselineConfiguration}/${response?.id}`);
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.createBaselineFailed(error));
  }
}

// Delete Baseline
function* deleteBaselineWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteBaseline), id)));
    yield put(actions.deleteBaselineSuccess(response));
    yield put(actions.setBaselineParams());
    yield put(
      addNotification({ msg: `${ids?.length > 1 ? "Baselines" : "Baseline"} deleted successfully` })
    );
  } catch (error) {
    yield put(actions.deleteBaselineFailed(error));
    yield put(addNotification({ msg: "Failed to detele baseline", type: "warning" }));
  }
}

// Set Assign-hosts Params
function* setAssignHostsParamsWorker() {
  const { label, search, baseline_id } = yield select(getAssignHostsParams);
  yield put(
    actions.getBaselineAssetsMini({
      label,
      search,
      baseline_id
    })
  );
}

// Set Assign-groups Params
function* setAssignGroupsParamsWorker({ payload }) {
  const { search } = yield select(getAssignGroupsParams);
  if (payload.isMiniRequest) {
    yield put(actions.getBaselineGroupsMini({ search }));
    return;
  }

  yield put(
    actions.getBaselineGroups({
      search
    })
  );
}

// Get Assets-mini
function* getBaselineAssetsMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselineAssetsMini), payload);
    yield put(actions.getBaselineAssetsMiniSuccess(response));
  } catch (error) {
    yield put(actions.getBaselineAssetsMiniFailed(error));
  }
}

// Get groups
function* getBaselineGroupsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselineGroups), payload);
    yield put(actions.getBaselineGroupsSuccess(response));
  } catch (error) {
    yield put(actions.getBaselineGroupsFailed(error));
  }
}

// Get groups mini
function* getBaselineGroupsMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselineGroupsMini), payload);
    yield put(actions.getBaselineGroupsMiniSuccess(response));
  } catch (error) {
    yield put(actions.getBaselineGroupsMiniFailed(error));
  }
}

// Post tag/tags
function* postTagsWorker({ payload }) {
  const { data, successCallback } = payload;
  const { search, filters } = yield select(getFilterConfigParams);
  const servicePayload = {
    data,
    fields: {
      search,
      filters
    }
  };
  try {
    const response = yield call(makeRequest(service.postBaselineTags), servicePayload);
    yield put(actions.postTagsSuccess(response));
    yield put(addNotification({ msg: "Tag updated successfully" }));
    yield put(actions.setBaselineConfigParams());
    successCallback();
  } catch (error) {
    yield put(actions.postTagsFailed(error));
    yield put(addNotification({ msg: error, type: "warning" }));
  }
}

// Get audit choices
function* getAuditChoicesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAuditChoices), payload);
    yield put(actions.getAuditChoicesSuccess(response));
  } catch (error) {
    yield put(actions.getAuditChoicesFailed(error));
  }
}

// Get audit choices mini
function* getAuditChoicesMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAuditChoicesMini), payload);
    yield put(actions.getAuditChoicesMiniSuccess(response));
  } catch (error) {
    yield put(actions.getAuditChoicesMiniFailed(error));
  }
}

function* setAuditChoicesWorker({ payload }) {
  const { page, pageSize, ordering, search, label, source, audit_file } = yield select(
    getAuditChoiceFilter
  );

  if (payload.isMiniRequest) {
    yield put(
      actions.getAuditChoicesMini({
        page,
        pageSize,
        ordering,
        search,
        source,
        audit_file,
        label
      })
    );
    return;
  }

  yield put(
    actions.getAuditChoices({
      page,
      pageSize,
      ordering,
      search,
      source,
      audit_file,
      label
    })
  );
}

// Get sources
function* getBaselineSourcesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselineSources), payload);
    yield put(actions.getBaselineSourcesSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getBaselineSourcesFailed(error));
  }
}

// Set Baseline Config Params
function* setBaselineConfigParamsWorker() {
  const {
    id,
    page,
    pageSize,
    ordering,
    search,
    filters,
    groupBy,
    baselineConfigTemporaryDataFilter
  } = yield select(getFilterConfigParams);
  yield put(
    actions.getBaselineConfigTable({
      id,
      page,
      pageSize,
      ordering,
      search,
      filters,
      groupBy,
      baselineConfigTemporaryDataFilter
    })
  );
}

function* getBaselineConfigFiltersWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselineConfigFilters), payload);
    yield put(actions.getBaselineConfigFiltersSuccess(response));
  } catch (error) {
    yield put(actions.getBaselineConfigFiltersFailed(error));
  }
}

function* getBaselineConfigTableWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getBaselineConfigTable), payload);
    yield put(actions.getBaselineConfigTableSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getBaselineConfigTableFailed(error));
  }
}

// Set Baseline History Params
function* setBaselineHistoryParamsWorker({ payload }) {
  const { id, page, pageSize, ordering, search } = yield select(getFilterHistoryParams);

  if (payload.isMiniRequest) {
    yield put(
      actions.getBaselineAnalysisMini({
        id,
        page,
        pageSize,
        ordering,
        search
      })
    );
    return;
  }

  yield put(
    actions.getBaselineAssets({
      id,
      page,
      pageSize,
      ordering,
      search
    })
  );
}

// EXPORT
const handleDownload = response => {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = "export.xls";
  link.href = URL.createObjectURL(response);
  link.click();
};

function* exportBaselineConfigurationTableWorker({ payload }) {
  try {
    const response = yield call(service.exportBaselineConfigurationTable, payload);
    handleDownload(response);
    yield put(actions.exportBaselineConfigurationTableSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.exportBaselineConfigurationTableFailed(error));
  }
}

// add-control
function* addControlBaselineConfigurationTableWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addControlBaselineConfigurationTable), payload);
    yield put(addNotification({ msg: "You have successfully added control" }));
    yield put(actions.setBaselineConfigParams());
    yield put(actions.addControlBaselineConfigurationTableSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addControlBaselineConfigurationTableFailed(error));
  }
}

// delete-control
function* deleteControlBaselineConfigurationTableWorker({ payload }) {
  try {
    const response = yield call(
      makeRequest(service.deleteControlBaselineConfigurationTable),
      payload
    );
    yield put(addNotification({ msg: "You have successfully deleted control" }));
    yield put(actions.setBaselineConfigParams());
    yield put(actions.deleteControlBaselineConfigurationTableSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.deleteControlBaselineConfigurationTableFailed(error));
  }
}

// Get Compare Baselines
function* getCompareBaselinesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getCompareBaselines), payload);
    yield put(actions.getCompareBaselinesSuccess(response));
  } catch (error) {
    yield put(actions.getCompareBaselinesFailed(error));
  }
}

// Dublicate Baseline
function* duplicateBaselineWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.duplicateBaseline), payload);
    yield put(actions.duplicateBaselineSuccess(response));
    yield put(addNotification({ msg: "Baseline duplicated successfully" }));
    navigate(`${routes.baselineConfiguration}/${response?.id}`);
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.duplicateBaselineFailed(error));
  }
}

function* getLabelsListWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getLabelsList), payload);
    yield put(actions.getLabelListSuccess(response));
  } catch (error) {
    yield put(actions.getLabelListFailed(error));
  }
}

// Get Labels List
function* watchGetLabelsListWorkerWorker() {
  yield takeEvery(actions.getLabelList, getLabelsListWorker);
}

// Watchers End//

// Set Baseline Params
function* watchSetBaselineParamsWorker() {
  yield takeEvery(actions.setBaselineParams, setBaselineParamsWorker);
}

// Get Baselines
function* watchGetBaselinesWorker() {
  yield takeEvery(actions.getBaselines, getBaselinesWorker);
}

// Get Baseline
function* watchGetBaselineWorker() {
  yield takeEvery(actions.getBaseline, getBaselineWorker);
}

// Delete Baseline
function* watchDeleteBaselineWorker() {
  yield takeEvery(actions.deleteBaseline, deleteBaselineWorker);
}

// Edit Baseline
function* watchEditBaselineWorker() {
  yield takeEvery(actions.editBaseline, editBaselineWorker);
}

// Edit Baseline Fields
function* watchEditBaselineFieldsWorker() {
  yield takeEvery(actions.editBaselineFields, editBaselineFieldsWorker);
}

// Create Baseline
function* watchCreateBaselineWorker() {
  yield takeEvery(actions.createBaseline, createBaselineWorker);
}

// Set Baseline Assign-Hosts Params
function* watchSetAssignHostsParamsWorker() {
  yield takeEvery(actions.setAssignHostsParams, setAssignHostsParamsWorker);
}

// Set Baseline Assign-Groups Params
function* watchSetAssignGroupsParamsWorker() {
  yield takeEvery(actions.setAssignGroupsParams, setAssignGroupsParamsWorker);
}

// Get Assets-mini
function* watchGetBaselineAssetsMiniWorker() {
  yield takeEvery(actions.getBaselineAssetsMini, getBaselineAssetsMiniWorker);
}

// Get Groups
function* watchGetBaselineGroupsWorker() {
  yield takeEvery(actions.getBaselineGroups, getBaselineGroupsWorker);
}

// Get Groups mini
function* watchGetBaselineGroupsMiniWorker() {
  yield takeEvery(actions.getBaselineGroupsMini, getBaselineGroupsMiniWorker);
}

// Post tags
function* watchPostTagsWorker() {
  yield takeEvery(actions.postTags, postTagsWorker);
}

// Get audit choices
function* watchGetAuditChoicesWorker() {
  yield takeEvery(actions.getAuditChoices, getAuditChoicesWorker);
}

// Get audit choices mini
function* watchGetAuditChoicesMiniWorker() {
  yield takeEvery(actions.getAuditChoicesMini, getAuditChoicesMiniWorker);
}

// Set audit choices params
function* watchSetAuditChoicesWorker() {
  yield takeEvery(actions.setAuditChoicesParams, setAuditChoicesWorker);
}

// Get sources
function* watchGetBaselineSourcesWorker() {
  yield takeEvery(actions.getBaselineSources, getBaselineSourcesWorker);
}

// Set Baseline assets
function* watchSetBaselineAssetsParamsWorker() {
  yield takeEvery(actions.setBaselineAssetsParams, setBaselineAssetsParamsWorker);
}

// Get Baseline assets
function* watchGetBaselineAssetsWorker() {
  yield takeEvery(actions.getBaselineAssets, getBaselineAssetsWorker);
}

// Get baseline analysis mini
function* watchGetBaselineAnalysisMiniWorker() {
  yield takeEvery(actions.getBaselineAnalysisMini, getBaselineAnalysisMiniWorker);
}

// Add Baseline asset
function* watchAddBaselineAssetWorker() {
  yield takeEvery(actions.addBaselineAsset, addBaselineAssetWorker);
}

// Delete Baseline asset
function* watchDeleteBaselineAssetWorker() {
  yield takeEvery(actions.deleteBaselineAsset, deleteBaselineAssetWorker);
}

// Add Baseline group
function* watchAddBaselineGroupWorker() {
  yield takeEvery(actions.addBaselineGroup, addBaselineGroupWorker);
}

// Delete Baseline group
function* watchDeleteBaselineGroupWorker() {
  yield takeEvery(actions.deleteBaselineGroup, deleteBaselineGroupWorker);
}

// Get Baseline control
function* watchGetBaselineControlWorker() {
  yield takeEvery(actions.getBaselineControl, getBaselineControlWorker);
}

function* watchSetBaselineConfigParamsWorker() {
  yield takeEvery(actions.setBaselineConfigParams, setBaselineConfigParamsWorker);
}

function* watchGetBaselineConfigFiltersWorker() {
  yield takeEvery(actions.getBaselineConfigFilters, getBaselineConfigFiltersWorker);
}

function* watchGetBaselineConfigTableWorker() {
  yield takeEvery(actions.getBaselineConfigTable, getBaselineConfigTableWorker);
}

function* watchExportBaselineConfigurationTableWorker() {
  yield takeEvery(actions.exportBaselineConfigurationTable, exportBaselineConfigurationTableWorker);
}

function* watchSetBaselineHistoryParamsWorker() {
  yield takeEvery(actions.setBaselineHistoryParams, setBaselineHistoryParamsWorker);
}

function* watchAddControlBaselineConfigurationTableWorker() {
  yield takeEvery(
    actions.addControlBaselineConfigurationTable,
    addControlBaselineConfigurationTableWorker
  );
}

function* watchDeleteControlBaselineConfigurationTableWorker() {
  yield takeEvery(
    actions.deleteControlBaselineConfigurationTable,
    deleteControlBaselineConfigurationTableWorker
  );
}

// Get Compare Baselines
function* watchGetCompareBaselinesWorker() {
  yield takeEvery(actions.getCompareBaselines, getCompareBaselinesWorker);
}

// Duplicate Baseline
function* watchDuplicateBaselineWorker() {
  yield takeEvery(actions.duplicateBaseline, duplicateBaselineWorker);
}

// Duplicate Baseline
function* watchBaselineTagDataWorker() {
  yield takeEvery(actions.getBaselineTagById, getBaselineTagDataWorker);
}

// Set Baseline Config Params
export default function* rootSaga() {
  yield all([
    fork(watchGetBaselineWorker),
    fork(watchGetBaselinesWorker),
    fork(watchSetBaselineParamsWorker),
    fork(watchGetBaselineAssetsWorker),
    fork(watchGetBaselineAnalysisMiniWorker),
    fork(watchSetBaselineAssetsParamsWorker),
    fork(watchAddBaselineAssetWorker),
    fork(watchDeleteBaselineAssetWorker),
    fork(watchAddBaselineGroupWorker),
    fork(watchDeleteBaselineGroupWorker),
    fork(watchGetBaselineControlWorker),
    fork(watchCreateBaselineWorker),
    fork(watchEditBaselineWorker),
    fork(watchEditBaselineFieldsWorker),
    fork(watchDeleteBaselineWorker),
    fork(watchGetAuditChoicesWorker),
    fork(watchGetAuditChoicesMiniWorker),
    fork(watchSetAssignHostsParamsWorker),
    fork(watchSetAssignGroupsParamsWorker),
    fork(watchGetBaselineAssetsMiniWorker),
    fork(watchGetBaselineGroupsWorker),
    fork(watchGetBaselineGroupsMiniWorker),
    fork(watchPostTagsWorker),
    fork(watchGetBaselineSourcesWorker),
    fork(watchSetBaselineConfigParamsWorker),
    fork(watchGetBaselineConfigFiltersWorker),
    fork(watchGetBaselineConfigTableWorker),
    fork(watchExportBaselineConfigurationTableWorker),
    fork(watchSetBaselineHistoryParamsWorker),
    fork(watchAddControlBaselineConfigurationTableWorker),
    fork(watchDeleteControlBaselineConfigurationTableWorker),
    fork(watchGetCompareBaselinesWorker),
    fork(watchDuplicateBaselineWorker),
    fork(watchGetLabelsListWorkerWorker),
    fork(watchBaselineTagDataWorker),
    fork(watchSetAuditChoicesWorker)
  ]);
}
