import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, Title, Select, Input } from "components/simple";

import { actionAddToGroupModal, addToGroup, addToNewGroup } from "store/entities/targets/actions";

import styles from "./styles.module.scss";

const ExistingGroup = ({ dispatch, selectList }) => {
  const groups = useSelector(state => state.targets?.groups);
  const groupOptions = useMemo(() => {
    if (!groups || !groups.results) {
      return [];
    }

    return groups.results.map(item => ({ value: item.id, label: item.name }));
  }, [groups]);

  const [currGroup, setCurrGroup] = useState();

  const handleAddToGroup = () => {
    if (!groups || !groups.results) {
      return;
    }

    const groupData = groups.results.find(item => item.id === currGroup.value);
    dispatch(addToGroup({ selectList, group: groupData }));
  };

  const handleNewGroup = () => {
    dispatch(actionAddToGroupModal({ show: true, selectList, newGroup: true }));
  };

  return (
    <>
      <div className={styles.headerBox}>
        <Title>Add to Group</Title>
        <div className={styles.headerBtn} onClick={handleNewGroup}>
          + Add to a new group
        </div>
      </div>
      <Select
        containerClass={styles.groupSelect}
        label="Group"
        options={groupOptions}
        value={currGroup}
        onChange={setCurrGroup}
      />
      <div className={styles.buttonBlock}>
        <Button onClick={handleAddToGroup}>Save</Button>
      </div>
    </>
  );
};

const NewGroup = ({ dispatch, selectList }) => {
  const [groupName, setGroupName] = useState("");

  const handleBack = () => {
    dispatch(actionAddToGroupModal({ show: true, selectList, newGroup: false }));
  };

  const handleSave = () => {
    dispatch(addToNewGroup({ selectList, groupName }));
  };

  return (
    <>
      <Title>Add to a new group</Title>
      <Input
        containerClassName={styles.groupNameInput}
        label="Name"
        value={groupName}
        onChange={evt => setGroupName(evt.target.value)}
      />
      <div className={styles.spreadButtonBlock}>
        <Button variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button disabled={!groupName || groupName === ""} onClick={handleSave}>
          Save
        </Button>
      </div>
    </>
  );
};

const AddToGroupModal = () => {
  const dispatch = useDispatch();
  const { show, selectList, newGroup } = useSelector(state => state.targets?.addToGroupModal);

  const handleClose = () => {
    dispatch(actionAddToGroupModal({ show: false, selectList: [], newGroup: false }));
  };

  return (
    <Modal isOpen={show} toggle={handleClose}>
      {newGroup ? (
        <NewGroup dispatch={dispatch} selectList={selectList} />
      ) : (
        <ExistingGroup dispatch={dispatch} selectList={selectList} />
      )}
    </Modal>
  );
};

export default AddToGroupModal;
