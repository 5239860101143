import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";

import { Button, Title, Modal, Input } from "components/simple";

import { mfaModal, addMFA } from "store/entities/users/actions";
import { getQRMFA } from "store/entities/users/service";

import styles from "./styles.module.scss";

const ManageMFAModal = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const open = useSelector(state => state?.users?.showMfaModal);
  const mfa_id = useSelector(state => state?.users?.userProfile?.mfa?.id);
  const user_id = useSelector(state => state?.users?.userProfile?.id);
  const [QRValue, setQRValue] = useState(null);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [secretKey, setSecretKey] = useState(null);

  const getQR = async () => {
    const res = await getQRMFA({ user_id }).then(r => r.json());
    setQRValue(res?.url);
    setSecretKey(res?.secret_key);
  };

  const onSubmit = e => {
    e.preventDefault();
    dispatch(addMFA({ token: code }));
  };

  const close = () => {
    if (mfa_id) {
      dispatch(mfaModal(false));
    }
  };

  const clear = () => {
    setQRValue(null);
    setCode("");
    setSecretKey(null);
  };

  useEffect(() => {
    if (open) {
      getQR();
    }
    return () => clear();
  }, [open]);

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Set up virtual MFA device</Title>
      <form onSubmit={onSubmit}>
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.step}>
              1. Install a google authenticator app on your mobile device or computer
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.step}>
              2. Use your virtual MFA app and your device`s camera to scan the QR code
            </div>
            {QRValue ? (
              <div className={styles.qr_box}>
                <div className={styles.qr}>
                  <QRCode value={QRValue} size={160} />
                </div>
                <div className={styles.note}>
                  Alternatively, you can type the secret key.
                  <span className={styles.link} onClick={() => setShowSecretKey(!showSecretKey)}>
                    Show secret key
                  </span>
                  {showSecretKey ? <div className={styles.secret}>{secretKey}</div> : null}
                </div>
              </div>
            ) : null}
          </div>

          <div className={styles.row}>
            <div className={styles.step}>3. Type MFA code below </div>
            <Input
              value={code}
              containerClassName={styles.input}
              onChange={e => setCode(e.target.value?.trim())}
              label="MFA code"
              required
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button variant="outline" onClick={close}>
            Close
          </Button>
          <Button type="submit">Assign MFA</Button>
        </div>
      </form>
    </Modal>
  );
};

export default ManageMFAModal;
