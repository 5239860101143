/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Title, Input, ContentContainer, Loader } from "components/simple";
import { getS3ProfileSelector } from "store/entities/settings/selectors";

import { editS3Profile, getS3Profile } from "store/entities/settings/actions";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const DataSourceEdit = () => {
  const dispatch = useDispatch();
  const selectTenant = useSelector(st => st.users.selectTenant);

  const data = useSelector(getS3ProfileSelector);
  const id = data?.id;

  const [state, setState] = useState({
    aws_s3_access_key: "",
    aws_s3_bucket_name: "",
    secret: ""
  });

  useEffect(() => {
    if (data?.id) {
      setState({
        aws_s3_access_key: data?.aws_s3_access_key,
        aws_s3_bucket_name: data?.aws_s3_bucket_name,
        secret: ""
      });
    }
  }, [data]);

  useEffect(() => {
    dispatch(getS3Profile());
  }, [selectTenant]);

  const onSave = e => {
    e.preventDefault();
    dispatch(editS3Profile(state));
  };

  const inputChangeHandler = (val, key) => {
    setState({ ...state, [key]: val });
  };

  if (!id) {
    return (
      <ContentContainer className={styles.container}>
        <Title>Edit</Title>
        <Loader />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer className={styles.container}>
      <Title>Edit</Title>
      <form className={styles.form} onSubmit={onSave}>
        <div className={styles.content}>
          <Input
            value={state.aws_s3_bucket_name}
            containerClassName={styles.inputContainer}
            label="s3 bucket name:"
            required
            onChange={val => inputChangeHandler(val.target.value, "aws_s3_bucket_name")}
          />
          <Input
            value={state.aws_s3_access_key}
            containerClassName={styles.inputContainer}
            label="s3 access key:"
            type="text"
            required
            autoComplete="off"
            onChange={val => inputChangeHandler(val.target.value, "aws_s3_access_key")}
          />
          <Input
            value={state.secret}
            containerClassName={styles.inputContainer}
            label="Secret"
            type="password"
            autocomplete="new-password"
            required
            onChange={val => inputChangeHandler(val.target.value, "secret")}
          />
        </div>
        <div className={styles.footer}>
          <Button type="submit" className={styles.button}>
            Save
          </Button>
        </div>
      </form>
    </ContentContainer>
  );
};

DataSourceEdit.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default DataSourceEdit;
