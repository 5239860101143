/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { routes } from "router";

import { getAsset, getAssetDataListTypes } from "store/entities/assets/actions";

import { assetDetailsSelector } from "store/entities/assets/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";

import { Title, ContentContainer, PieChart, Loader } from "components/simple";

import DetailsBox from "./DetailsBox";

import styles from "./styles.module.scss";
import Explorer from "./Explorer";
import Findings from "./Findings";
import AssetDetailsModal from "./AssetDetailsModal";

const AssetsDetails = ({ history }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const id = params?.id;

  const [modalSort, setModalSort] = useState(null);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getAsset.type])(state));

  const { vulnerabilities, details, compliances } = useSelector(assetDetailsSelector);

  const onComplianceChartClick = v => {
    const paramsObj = {
      filter: compliances[v]?.key,
      asset_id: id
    };
    const searchParams = new URLSearchParams(paramsObj);

    history.push(`${routes.baselines}?${searchParams.toString()}`);
  };

  useEffect(() => {
    dispatch(getAsset({ id }));
    dispatch(getAssetDataListTypes(id));
  }, [id, selectTenant]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.grid}>
            <DetailsBox data={details} openModal={setModalSort} />

            <ContentContainer className={styles.wrapper}>
              <div>
                <Title>Vulnerabilities</Title>
                <br />
                <PieChart
                  data={vulnerabilities}
                  className={styles.chart}
                  onClick={v => {
                    const val = vulnerabilities[v]?.key;
                    const res = { risk_factor: { value: val, label: val } };
                    history.push(
                      `${routes.vulnerabilities}?filters=${JSON.stringify(
                        res
                      )}&asset=${JSON.stringify({ label: details?.name, value: id })}`
                    );
                  }}
                />
              </div>
              <div>
                <Title>Compliance</Title>
                <br />
                <PieChart
                  data={compliances}
                  className={styles.chart}
                  onClick={onComplianceChartClick}
                />
              </div>
            </ContentContainer>
          </div>
          <Explorer />
          <Findings detailName={details?.name} />
          <AssetDetailsModal
            open={!!modalSort}
            close={() => setModalSort(null)}
            modalName={modalSort}
            assetId={id}
          />
        </>
      )}
    </div>
  );
};

AssetsDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default AssetsDetails;
