import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Modal, Title } from "components/simple";

import { actionDeleteTargetModal, deleteTargets } from "store/entities/targets/actions";

import styles from "./styles.module.scss";

const DeleteTargetModal = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const { show, deleteList, navigateBack } = useSelector(state => state.targets?.deleteTargetModal);

  const handleClose = () => {
    dispatch(actionDeleteTargetModal({ show: false, deleteList: [] }));
  };

  const handleDelete = () => {
    const idList = deleteList.map(item => item.id);

    if (navigateBack) {
      dispatch(deleteTargets({ items: idList, onSuccess: () => history.push("/targets") }));
    } else {
      dispatch(deleteTargets({ items: idList }));
    }
  };

  return (
    <Modal isOpen={show} toggle={handleClose}>
      <Title>{deleteList.length > 1 ? "Delete targets?" : "Delete target?"}</Title>
      <div className={styles.listBlock}>
        {deleteList.map((item, idx) => (
          <div key={`DelItem_${idx}`}>{item.name}</div>
        ))}
      </div>
      <div className={styles.buttonBlock}>
        <Button variant="outline" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>
    </Modal>
  );
};
export default DeleteTargetModal;
