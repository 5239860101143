/* eslint-disable camelcase */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { routes } from "router";
import { onChangeUrlParams, useQuery } from "helper/history";

import {
  deleteTicket,
  closeTicket,
  getTickets,
  getTicketsConfig,
  setTicketsParams,
  getTicketsRelatedTypes,
  getTicketsStatusList
} from "store/entities/tickets/actions";
import { getLabelsMini } from "store/entities/labels/actions";

import {
  getTicketsSelector,
  ticketsRelatedTypesSelector,
  ticketsTableColumns,
  statusListSelector,
  ticketsConfigSelector
} from "store/entities/tickets/selectors";
import { labelsMiniSelector } from "store/entities/labels/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Title, Search, Button, Select } from "components/simple";
import { Table } from "components/complex";

import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";

const Tickets = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");
  const queryStatus = query.get("status");

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getTickets.type])(state));

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const { page, pageSize, filterBy, type, status, search, configuration } = useSelector(
    state => state.tickets.ticketsParams
  );

  const { data, total } = useSelector(state => getTicketsSelector(state));
  const labelsOptions = useSelector(labelsMiniSelector);
  const typesOptions = useSelector(ticketsRelatedTypesSelector);
  const statusOptions = useSelector(statusListSelector);
  const configurationOptions = useSelector(ticketsConfigSelector);

  const { state: availableTicket } = useLocation();

  // get default request
  const getRequest = useCallback(() => {
    const statusVal = queryStatus ? queryStatus.charAt(0).toUpperCase() + queryStatus.slice(1) : "";
    const statusSelect = statusVal ? { value: statusVal, label: statusVal } : "";

    dispatch(
      setTicketsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: availableTicket?.ticketId ?? "",
        filterBy: "",
        type: "",
        status: statusSelect
      })
    );

    dispatch(getTicketsConfig());
    dispatch(getTicketsRelatedTypes());
    dispatch(getLabelsMini());
    dispatch(getTicketsStatusList());
  }, [queryPage, availableTicket?.ticketId]);

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setTicketsParams({
        page: 0,
        search: v
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setTicketsParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setTicketsParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setTicketsParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setTicketsParams({
          page: newPage
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setTicketsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
    const { id } = p;

    history.push(`${routes.ticketsDetails}/${id}`);
  };

  const deleteTicketsHandler = () => {
    dispatch(deleteTicket({ ids: selectionModel }));
  };

  const closeTicketsHandler = () => {
    dispatch(closeTicket({ ids: selectionModel }));
  };

  const filterChangeHandler = (option, variant) => {
    onChangeUrlParams("status", 0);
    onChangeUrlParams("page", 0);
    const value = !option ? "" : option;
    if (variant === "filter") {
      dispatch(
        setTicketsParams({
          filterBy: value,
          page: 0
        })
      );
      return;
    }
    if (variant === "data_type") {
      dispatch(
        setTicketsParams({
          type: value,
          page: 0
        })
      );
      return;
    }
    if (variant === "status") {
      dispatch(
        setTicketsParams({
          status: value,
          page: 0
        })
      );
      return;
    }
    if (variant === "configuration") {
      dispatch(
        setTicketsParams({
          configuration: value,
          page: 0
        })
      );
    }
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Tickets</Title>
      <div className={styles.searchRow}>
        <Search value={search} onSearch={handleSearch} />
        <div className={styles.buttonGroup}>
          <Button
            onClick={deleteTicketsHandler}
            className={styles.btn}
            disabled={!selectionModel.length}
          >
            Delete
          </Button>
          <Button
            onClick={closeTicketsHandler}
            className={styles.btn}
            disabled={!selectionModel.length}
          >
            Close
          </Button>
        </div>
      </div>
      <div className={styles.filterRow}>
        <Select
          containerClass={styles.select}
          onChange={v => filterChangeHandler(v, "filter")}
          value={filterBy}
          placeholder="Filter by: Label"
          options={labelsOptions}
          isClearable
        />
        <Select
          containerClass={styles.select}
          onChange={v => filterChangeHandler(v, "data_type")}
          value={type}
          placeholder="Type: Data type"
          options={typesOptions}
          isClearable
        />
        <Select
          containerClass={styles.select}
          onChange={v => filterChangeHandler(v, "status")}
          value={status}
          placeholder="Filter by: Status"
          options={statusOptions}
          isClearable
        />
        <Select
          containerClass={styles.select}
          onChange={v => filterChangeHandler(v, "configuration")}
          value={configuration}
          placeholder="Filter by: Configuration"
          options={configurationOptions}
          isClearable
        />
      </div>

      <div className={styles.table}>
        <Table
          data={isLoading ? [] : data}
          columns={ticketsTableColumns}
          loading={isLoading}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          page={page || 0}
          selectionModel={selectionModel}
          onSortModelChange={model => handleSort(model)}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          onPageChange={onPageChange}
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
        />
      </div>
    </ContentContainer>
  );
};

Tickets.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Tickets;
