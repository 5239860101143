import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Icon } from "components/simple";

import styles from "./styles.module.scss";

const modalStyles = {
  overflowY: "scroll",
  height: "100%",
  display: "block",
  zIndex: 999
};

const contentStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  borderRadius: "15px",
  padding: "40px",
  boxShadow: 24,
  border: "none",
  outline: "none"
};

const BasicModal = ({ isOpen, toggle, children, width, sx }) => {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={toggle}
        style={modalStyles}
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...contentStyles, width: `${width}px`, ...sx }}>
          <div onClick={toggle}>
            <Icon.Close className={styles.close} />
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} component="span">
            {children}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

BasicModal.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.node
};

BasicModal.defaultProps = {
  width: "680",
  isOpen: false,
  toggle: () => {},
  children: <div></div>
};

export default BasicModal;
