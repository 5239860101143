import React from "react";
import renderCellExpand from "helper/renderCellExpand";

export const templatesTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Name",
    field: "name",
    resizable: true,
    minWidth: 430,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Type",
    field: "data_type",
    resizable: true,
    minWidth: 250,
    flex: 1,
    renderCell: params => <div style={{ textTransform: "capitalize" }}>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const rbacTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Role",
    field: "name",
    resizable: true,
    minWidth: 400,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Permissions",
    field: "permissions_count",
    resizable: true,
    minWidth: 165,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Users",
    field: "users_count",
    resizable: true,
    minWidth: 205,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const userProfilesTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Email",
    field: "user__email",
    resizable: true,
    minWidth: 165,
    flex: 1,
    renderCell: params => {
      return renderCellExpand({
        ...params,
        value: params?.row?.email
      });
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Role",
    field: "role__name",
    resizable: true,
    minWidth: 165,
    flex: 1,
    renderCell: params => {
      return renderCellExpand({
        ...params,
        value: params?.row?.role
      });
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Permissions",
    field: "permissions_count",
    resizable: true,
    minWidth: 205,
    flex: 1,
    renderCell: params => {
      return renderCellExpand({
        ...params,
        value: params?.row?.permissions
      });
    },
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const labelsTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Label",
    field: "name",
    resizable: true,
    minWidth: 300,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];
