import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "store/entities/users/actions";
import { createErrorMessageSelector } from "store/entities/error/selector";
import styles from "./styles.module.scss";

import { styled, Link } from "@mui/material";

import { Button, Input } from "components/simple";

import { ReactComponent as AccuknoxLoginSvg } from "assets/images/AccuknoxLogin.svg";
import { ReactComponent as AccuknoxLogo } from "assets/images/AccuknoxLogo.svg";

export const AccuknoxLoginImage = styled(AccuknoxLoginSvg)(({ theme }) => {
  return {
    position: "absolute",
    height: "100%",
    width: "100%",
    right: 0,
    top: 0
  };
});

export const FormLink = styled(Link)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
  textAlign: "right"
}));

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const err = useSelector(state => createErrorMessageSelector([forgotPassword.type])(state));
  const [errorMessage, setErrorMessage] = useState(null);

  const [state, setState] = useState({
    email: ""
  });

  const handleSubmit = e => {
    e.preventDefault();

    if (!state.email.trim()) {
      setErrorMessage("this field is required");
      return;
    }

    const { email } = state;
    dispatch(forgotPassword({ username: email }));
  };

  const handleChange = (val, type) => {
    setErrorMessage(null);
    setState({
      ...state,
      [type]: val
    });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.title}>Forgot password</div>
        <div className={styles.description}>
          Enter the email associated with your account and we will send an email with code to reset
          a password.
        </div>

        <div className={styles.content}>
          <div>
            <Input
              label="Email"
              value={state.email}
              onChange={val => handleChange(val.target.value, "email")}
              invalid={errorMessage || err}
              containerClassName={styles.input}
              className={`${styles.inputcode}`}
            />
          </div>
          <Button className={styles.button} type="submit" disabled={!state.email.trim()}>
            Login
          </Button>
        </div>
      </form>
      <div className={styles.bg}>
        <AccuknoxLoginImage />
        <div className={styles.logo}>
          <AccuknoxLogo />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
