import { formatDate } from "helper/DateLib";
import React from "react";

const ticketColumns = [
  {
    headerName: "Ticket Title",
    field: "title",
    resizable: true,
    minWidth: 200,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Ticket Number",
    field: "desk_key",
    resizable: true,
    minWidth: 140,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Assets",
    field: "assets",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value.length || 0}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Comments",
    field: "comments",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Date opened",
    field: "created_at",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => {
      return <div>{formatDate(params.value)}</div>;
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Date closed",
    field: "closed_at",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => {
      return <div>{formatDate(params.value)}</div>;
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];

const groupsColumns = [
  {
    headerName: "Name",
    field: "name",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Tickets",
    field: "tickets_count",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Assets",
    field: "assets",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value.length || 0}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Findings",
    field: "findings",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.findings;
      const render = () => {
        return param.map(([key, val]) => {
          let color = "#0000";
          switch (key) {
            case "Medium":
              color = "#FEBD46";
              break;

            case "High":
              color = "#576ACA";
              break;

            case "Low":
              color = "#9EEC60";
              break;

            case "Informational":
              color = "#6678df";
              break;

            case "Critical":
              color = "#d83e3c";
              break;

            default:
              break;
          }
          return <div style={{ color }}>{val || ""}</div>;
        });
      };

      return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", gap: "5px" }}>
          {render()}
        </div>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Baseline",
    field: "compliances",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => {
      return (
        <>
          <div style={{ color: "#9EEC60" }}>{params?.value.passed ?? ""}</div>/
          <div style={{ color: "#d83e3c" }}>{params?.value.failed ?? ""}</div>
        </>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Date",
    field: "last_scan",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => {
      return <div>{formatDate(params.value)}</div>;
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Time",
    field: "time",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => {
      return <div>{formatDate(params.row.last_scan, "hh:mm A")}</div>;
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];

const monitorsColumns = [
  {
    headerName: "Name",
    field: "name",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Assets",
    field: "assets",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Group",
    field: "groups",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Data Type",
    field: "data_type_display",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];

const auditFilesColumns = [
  {
    headerName: "Audit File",
    field: "audit_file",
    resizable: true,
    minWidth: 300,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Source",
    field: "source",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Label",
    field: "labels_count",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Assets",
    field: "assets_count",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Baseline",
    field: "baselines_count",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Last seen",
    field: "last_seen",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "History",
    field: "history_count",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];

const linkedAssetsColumns = [
  {
    headerName: "Assets",
    field: "title",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Label",
    field: "label",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params?.value?.name || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Target",
    field: "targets",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Baseline",
    field: "compliances",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => {
      return (
        <>
          <div style={{ color: "#9EEC60" }}>{params?.value.passed ?? ""}</div>/
          <div style={{ color: "#d83e3c" }}>{params?.value.failed ?? ""}</div>
        </>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Total Vulnerabilities",
    field: "vulnerabilities",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value.length || 0}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Last Scan",
    field: "last_scan",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => {
      return <div>{formatDate(params.value)}</div>;
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Asset Type",
    field: "obj_type",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Data Type",
    field: "data_types",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: params => <div>{params.value.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];

export const columns = {
  Tickets: ticketColumns,
  Groups: groupsColumns,
  Monitors: monitorsColumns,
  "Audit Files": auditFilesColumns,
  "Linked Assets": linkedAssetsColumns
};
