import React from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";
import styles from "./styles.module.scss";
import { routes } from "router";
import Item from "./Item";
import ItemDropdown from "./ItemDropdown";
import { useBasePath } from "helper/history";

import { Link, useHistory } from "react-router-dom";
import { logout } from "store/entities/users/actions";

import AccuknoxSidebarLogo from "assets/images/AccuknoxSidebarLogo.svg";

const Wrapper = styled("div")(({ theme }) => ({
  background: theme.sideMenu.background
}));

export default function Sidebar() {
  const history = useHistory();
  const current = useBasePath();

  const dispatch = useDispatch();

  const isActive = r => {
    const currentRoute = current?.split("/")?.[1];
    const route = r.replaceAll("/", "");
    return currentRoute === route;
  };

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <Wrapper>
      <div className={`${styles["sidebar-container"]}`}>
        <Link to={routes.home}>
          <div className={`${styles.logo} ${styles.blue}`}>
            <img src={AccuknoxSidebarLogo} alt="logo" />
          </div>
        </Link>

        <div className={`${styles.sidebarWrapper}`}>
          <div className={styles["items-list"]}>
            <Item
              text="Dashboard"
              icon="dashboard"
              active={isActive(routes.home)}
              onClick={() => history.push(routes.home)}
            />

            <ItemDropdown
              text="Data & Structure"
              icon="datastructure"
              onClick={r => history.push(r)}
              list={[
                {
                  name: "Import History",
                  route: routes.dataStructureHistory
                },
                {
                  name: "Tags",
                  route: routes.dataStructureTags
                },
                {
                  name: "Groups",
                  route: routes.dataStructureGroups
                },
                {
                  name: "Datalist",
                  route: routes.dataStructureDatalist
                },
                {
                  name: "Assets",
                  route: routes.dataStructureAssets
                },
                {
                  name: "Labels",
                  route: routes.dataStructureLabels
                },
                {
                  name: "Baselines",
                  route: routes.dataStructureBaselines
                }
              ]}
            />
            <Item
              text="Vulnerabilities"
              icon="vulnerabilities"
              active={isActive(routes.vulnerabilities)}
              onClick={() => history.push(routes.vulnerabilities)}
            />
            <Item
              text="Baselines"
              icon="baselines"
              active={isActive(routes.baselines)}
              onClick={() => history.push(routes.baselines)}
            />
            <Item
              text="Targets"
              icon="targets"
              active={isActive(routes.targets)}
              onClick={() => history.push(routes.targets)}
            />
            <Item
              text="Cloud Accounts"
              icon="targets"
              active={isActive(routes.cloudAccounts)}
              onClick={() => history.push(routes.cloudAccounts)}
            />
            <Item
              text="Tickets"
              icon="tickets"
              active={isActive(routes.tickets)}
              onClick={() => history.push(routes.tickets)}
            />
            <Item
              text="Reports"
              icon="reports"
              active={isActive(routes.reports)}
              onClick={() => history.push(routes.reports)}
            />
            <Item
              text="Monitors"
              icon="monitors"
              active={isActive(routes.monitors)}
              onClick={() => history.push(routes.monitors)}
            />
            <Item
              text="Notifications"
              icon="notifications"
              active={isActive(routes.notifications)}
              onClick={() => history.push(routes.notifications)}
            />
            <div className={styles.divide}></div>
            <Item
              text="Settings"
              icon="settings"
              active={isActive(routes.settings)}
              onClick={() => history.push(routes.settings)}
            />

            <Item text="Log Out" icon="logout" active={false} onClick={() => onLogout()} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

Sidebar.propTypes = {};

Sidebar.defaultProps = {};
