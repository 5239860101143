import React from "react";
import PropTypes from "prop-types";

import { FormLabel } from "@mui/material";
import QuestionTooltip from "../QuestionTooltip";

import styles from "./styles.module.scss";

const Input = ({
  onChange,
  label,
  value,
  placeholder,
  disabled = false,
  required,
  containerClassName,
  className,
  onBlur,
  onFocus,
  invalid,
  tooltip,
  subTitle,
  ...inputProps
}) => {
  const handleBlur = params => {
    // eslint-disable-next-line
    onBlur && onBlur(params);
  };
  const handleFocus = params => {
    // eslint-disable-next-line
    onFocus && onFocus(params);
  };

  return (
    <div className={`${styles.container} ${containerClassName} ${required ? styles.required : ""}`}>
      {label && (
        <div className={styles.labelCont}>
          <FormLabel className={styles.label}>{label}</FormLabel>
          {tooltip && <QuestionTooltip text={tooltip} />}
        </div>
      )}
      <input
        {...inputProps}
        required={required}
        onChange={onChange}
        value={value ?? ""}
        disabled={disabled}
        placeholder={placeholder}
        className={`${styles.input} ${className} ${invalid && styles.error}`}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {invalid && <div className={styles["error-text"]}>{invalid}</div>}
      {subTitle && value && <div className={styles["sub-text"]}>{subTitle}</div>}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltip: PropTypes.string
};

Input.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  containerClassName: "",
  disabled: false,
  required: false,
  onChange: () => {},
  onBlur: () => {},
  className: "",
  invalid: "",
  tooltip: ""
};

export default Input;
