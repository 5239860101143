/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from "react";

import { Table } from "components/complex";
import { ContentContainer, Input, Title, InputSearch, Button } from "components/simple";

import styles from "./styles.module.scss";
import inputStyles from "../../../styles.module.scss";

const AssignedTables = ({
  columnsLeftTable,
  columnsRightTable,
  dataLeftTable,
  dataRightTable,
  isLoadingLeftTable,
  isLoadingRightTable,
  isLoadingOnAdd,
  titleLeftTable,
  searchVal,
  onCellClick,
  onSearch,
  onSubmitLeftTableSearch,
  onSubmitRightTableSearch,
  onAdd
}) => {
  const [selectionModelLeftTable, setSelectionModelLeftTable] = useState([]);
  const prevSelectionModelLeftTable = useRef(selectionModelLeftTable);

  useEffect(() => {
    if (prevSelectionModelLeftTable.current?.length) {
      setSelectionModelLeftTable(prevSelectionModelLeftTable.current);
    }
  }, [dataLeftTable]);

  // Search
  const timerRef = useRef();
  const triggerSearchRequest = (text, name) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (name === "leftTable") {
        onSubmitLeftTableSearch(text);
      } else {
        onSubmitRightTableSearch(text, name);
      }
    }, 500);
  };

  const handleSearch = (val, name) => {
    onSearch(val, name);
    triggerSearchRequest(val, name);
  };

  const customStyles = {
    height: 710,
    width: 1,
    padding: "30px",
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "1px solid #E7E6F8",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important"
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #E7E6F8",
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important",
        "&:focus, &:active": {
          outline: "none !important"
        }
      }
    }
  };

  const tableConfig = {
    checkboxVisible: false,
    paginationVisible: false,
    hideFooter: true,
    sortingMode: false
  };

  return (
    <div className={styles.wrapper}>
      <ContentContainer>
        <div className={inputStyles.controls}>
          <Input
            type="text"
            placeholder="Search"
            value={searchVal.leftTable}
            onChange={val => handleSearch(val.target.value, "leftTable")}
            containerClassName={inputStyles.searchContainer}
            className={inputStyles.search}
          />
          <Button
            onClick={() => onAdd(selectionModelLeftTable)}
            isLoading={isLoadingOnAdd}
            className={styles.addBtn}
          >
            Add
          </Button>
        </div>
        <Table
          {...tableConfig}
          data={isLoadingLeftTable ? [] : dataLeftTable}
          columns={columnsLeftTable}
          loading={isLoadingLeftTable}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModelLeftTable(newSelectionModel);
          }}
          selectionModel={selectionModelLeftTable}
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
          checkboxVisible
        />
      </ContentContainer>

      <ContentContainer>
        <div className={styles.searchTitle}>
          <Title>{titleLeftTable}</Title>
          <InputSearch
            value={searchVal.rightTable}
            onChange={val => handleSearch(val, "rightTable")}
            placeholder="Search"
          />
        </div>
        <Table
          data={isLoadingRightTable ? [] : dataRightTable}
          columns={columnsRightTable}
          loading={isLoadingRightTable}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
          {...tableConfig}
        />
      </ContentContainer>
    </div>
  );
};

AssignedTables.propTypes = {};

export default AssignedTables;
