import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "react-router-redux";
import { logout, refresh } from "./entities/users/actions";
import jwtDecode from "jwt-decode";

// import logger from "redux-logger";

import reducer from "./reducers";
import sagas from "./sagas";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const checkTokenExpirationMiddleware = () => next => action => {
  const token = localStorage.getItem("token");
  try {
    if (token && jwtDecode(token)) {
      const minutes = 10 * 60;
      if (token && jwtDecode(token).exp < Date.now() / 1000 + minutes) {
        next(refresh({ token, action }));
      } else if (action?.payload?.logout) {
        next(logout());
      } else {
        next(action);
      }
    } else if (action?.payload?.logout) {
      next(logout());
    } else {
      next(action);
    }
  } catch (err) {
    sessionStorage.removeItem("tenant");
    localStorage.removeItem("token");
    next(action);
  }
};

const middlewares = [checkTokenExpirationMiddleware, sagaMiddleware, routeMiddleware];
// const middlewares = [sagaMiddleware, routeMiddleware];

if (process.env.NODE_ENV === "development") {
  // middlewares.push(logger);
}

const configureRootStore = () => {
  const store = configureStore({
    reducer,
    middleware: [...middlewares]
  });

  sagaMiddleware.run(sagas);

  return store;
};

export default configureRootStore();
