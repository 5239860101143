import { useState } from "react";

import * as moment from "dayjs";

const INIT_SCHEDULE = {
  date: null,
  startTime: null,
  repeatDays: []
};

export const useSchedule = () => {
  const [schedule, setSchedule] = useState(INIT_SCHEDULE);

  const scheduleCtrl = {
    resetSchedule: initData => {
      if (initData) {
        let data = initData;
        if (initData.startTime) {
          const startTime = moment(initData.startTime).format("HH:mm");
          data = { ...initData, startTime };
        }
        setSchedule(data);
      } else {
        setSchedule(INIT_SCHEDULE);
      }
    },

    setFrequency: newFreq => {
      const newSchedule = {
        ...schedule,
        frequency: newFreq
      };

      setSchedule(newSchedule);
    },

    setTimeZone: newTZ => {
      const newSchedule = {
        ...schedule,
        timeZone: newTZ
      };

      setSchedule(newSchedule);
    },

    setDate: newDate => {
      const newSchedule = {
        ...schedule,
        date: newDate || null
      };

      setSchedule(newSchedule);
    },

    setStartTime: newTime => {
      const newSchedule = {
        ...schedule,
        startTime: newTime || null
      };

      setSchedule(newSchedule);
    },

    setRepeat: newRep => {
      const newSchedule = {
        ...schedule,
        repeat: newRep
      };

      setSchedule(newSchedule);
    },

    setRepeatDays: repeatDays => {
      const newSchedule = {
        ...schedule,
        repeatDays
      };

      setSchedule(newSchedule);
    }
  };

  return [schedule, scheduleCtrl];
};
