import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { routes } from "router/router";

import { Title, Modal, Loader } from "components/simple";

import { getDetailsAsset } from "store/entities/importHistory/service";

import styles from "./styles.module.scss";

const DetailsAssetModal = ({ open, data, close }) => {
  const [state, setState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const request = () => {
    setIsLoading(true);
    getDetailsAsset({ id: data?.id })
      .then(r => r.json())
      .then(res => {
        const result = res;
        setState(result);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      request();
    }
    return () => setState([]);
  }, [open]);

  const goToAsset = id => {
    history.push(`${routes.dataStructureAssetsDetails}/${id}`);
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Asset</Title>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.content}>
          {state?.map(item => {
            return (
              <div className={styles.item} key={item.id} onClick={() => goToAsset(item?.id)}>
                {item?.title}
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
};

export default DetailsAssetModal;
