import React, { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";

import styles from "./styles.module.scss";

// example
// const data = [
//   { title: "Low", value: 10, color: "#9EEC60" },
//   { title: "Critical", value: 15, color: "#EB5958" }
// ];

const PieChartCpmponent = ({
  className,
  data = [],
  viewBoxSize = [120, 120],
  center = [60, 60],
  onClick = () => null
}) => {
  const [hovered, setHovered] = useState(undefined);

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        {data.slice(0, 4).map((item, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.name}>{item?.title}</div>
            <div className={styles.color} style={{ background: item?.color }} />
          </div>
        ))}
      </div>
      <div className={`${styles.chart} ${className}`}>
        <PieChart
          data={data}
          lineWidth={65}
          startAngle={0}
          lengthAngle={270}
          center={center}
          segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
          segmentsShift={index => (index === hovered ? 6 : 0)}
          onClick={(event, index) => {
            onClick(index);
          }}
          onMouseOver={(_, index) => {
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(undefined);
          }}
          // eslint-disable-next-line react/no-unstable-nested-components
          label={({ x, y, dx, dy, dataEntry, dataIndex }) => {
            return (
              <text
                x={-x}
                y={y}
                dx={-dx}
                dy={dy}
                key={dataIndex}
                dominantBaseline="central"
                textAnchor="middle"
                style={{
                  fontSize: "8px",
                  transform: "scale(-1, 1)",
                  fill: "#000",
                  fontWeight: "600",
                  visibility: dataIndex === hovered ? "visible" : "hidden"
                }}
              >
                {dataEntry.value}
              </text>
            );
          }}
          viewBoxSize={viewBoxSize}
        />
      </div>
      <div className={styles.footer}>
        <div className={`${styles.list} ${styles.list2}`}>
          {data.slice(4, 8).map((item, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.name}>{item?.title}</div>
              <div className={styles.color} style={{ background: item?.color }} />
            </div>
          ))}
        </div>
        <div className={`${styles.list} ${styles.list3}`}>
          {data.slice(8, 12).map((item, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.name}>{item?.title}</div>
              <div className={styles.color} style={{ background: item?.color }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PieChartCpmponent;
