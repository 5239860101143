/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "components/simple";
import { Route } from "react-router-dom";
import { useBasePath } from "helper/history";

import { DataSource, Rbac, TicketTemplates, Integrations, Labels, UserProfiles } from "./Tabs";

import styles from "./styles.module.scss";
import { routes } from "router/router";

const Settings = ({ history }) => {
  const current = useBasePath();
  const getActive = () => {
    switch (current) {
      case routes.settings:
        return 0;
      case routes.settingsIntegrations:
        return 1;
      case routes.settingsTicketTemplates:
        return 2;
      case routes.settingsRbac:
        return 3;
      case routes.settingsLabels:
        return 4;
      case routes.settingsUserProfiles:
        return 5;
      default:
        return 0;
    }
  };

  const activeTab = getActive();
  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        return history.push(routes.settings);
      case 1:
        return history.push(routes.settingsIntegrations);
      case 2:
        return history.push(routes.settingsTicketTemplates);
      case 3:
        return history.push(routes.settingsRbac);
      case 4:
        return history.push(routes.settingsLabels);
      case 5:
        return history.push(routes.settingsUserProfiles);
      default:
        return null;
    }
  };

  const tabs = [
    {
      id: 0,
      label: `Data Source`,
      content: null
    },
    {
      id: 1,
      label: `Integrations`,
      content: null
    },
    {
      id: 2,
      label: `Ticket Templates`,
      content: null
    },
    {
      id: 3,
      label: `Rbac`,
      content: null
    },
    {
      id: 4,
      label: `Labels`,
      content: null
    },
    {
      id: 5,
      label: `User Profiles`,
      content: null
    }
  ];

  return (
    <div className={styles.container}>
      <Tabs tabs={tabs} value={activeTab} handleChange={handleChange} />

      <Route exact path={routes.settings} component={DataSource} />
      <Route exact path={routes.settingsIntegrations} component={Integrations} />
      <Route exact path={routes.settingsTicketTemplates} component={TicketTemplates} />
      <Route exact path={routes.settingsRbac} component={Rbac} />
      <Route exact path={routes.settingsLabels} component={Labels} />
      <Route exact path={routes.settingsUserProfiles} component={UserProfiles} />
    </div>
  );
};

Settings.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Settings;
