/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { routes } from "router";
import { onChangeUrlParams, useQuery } from "helper/history";

import {
  setNotificationsParams,
  getNotifications
  // deleteNotification
} from "store/entities/notifications/actions";
import {
  getNotificationsSelector,
  notificationsTableColumns
} from "store/entities/notifications/selectors";

import { getBaselines } from "store/entities/baseline/service";
import { getVulnerabilitiesMini } from "store/entities/notifications/service";
import { getImportHistory } from "store/entities/importHistory/service";

import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Title, SelectAsync, Search } from "components/simple";

import { Table } from "components/complex";

import styles from "./styles.module.scss";

const Notifications = ({ history }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getNotifications.type])(state));

  const { page, pageSize, filters } = useSelector(state => state.notifications.notificationsParams);
  const { data, total } = useSelector(getNotificationsSelector);

  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");

  // get default request
  const getRequest = () => {
    dispatch(
      setNotificationsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: "",
        filters: ""
      })
    );
  };

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  // Search
  const handleSearch = text => {
    onChangeUrlParams("page", 0);
    dispatch(
      setNotificationsParams({
        page: 0,
        search: text
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setNotificationsParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setNotificationsParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setNotificationsParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setNotificationsParams({
          page: newPage
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setNotificationsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
    history.push(`${routes.notificationsDetails}/${p?.id}`);
  };

  const hangleChangeFilter = (val, key) => {
    const res = {
      ...filters,
      [key]: val
    };
    dispatch(
      setNotificationsParams({
        filters: res,
        page: 0,
        search: "",
        ordering: ""
      })
    );
  };

  // const deleteHandler = () => {
  //   // already created: saga, service, actions
  //   dispatch(deleteNotification({ ids: selectionModel }));
  // };

  const request = async (search, pageNum, name) => {
    let fetchHandler;
    switch (name) {
      case "vulnerability":
        fetchHandler = getVulnerabilitiesMini;
        break;
      case "baseline":
        fetchHandler = getBaselines;
        break;
      case "scan":
        fetchHandler = getImportHistory;
        break;
      default:
        fetchHandler = () => {};
        break;
    }
    try {
      const res = await fetchHandler({
        search,
        page: pageNum,
        pageSize: 20,
        ordering: ""
      }).then(r => r.json());
      const listOptions = res?.results?.map(item => ({
        value: item?.id,
        label: item?.name || item?.vulnerability__name || item?.scan_file
      }));
      return { options: listOptions, next: res?.next };
    } catch (e) {
      // return console.log("err", e);
    }
  };

  const loadOptions = async (searchText, prevOptions, pageNum, name) => {
    const { options, next } = await request(searchText, pageNum, name);
    const hasMore = !searchText ? Boolean(next) : false;

    return {
      options,
      hasMore,
      additional: {
        page: pageNum + 1
      }
    };
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Notifications</Title>
      <div className={styles.searchRow}>
        <Search onSearch={handleSearch} />
        {/* <Button className={styles.btn} onClick={deleteHandler} disabled={!selectionModel.length}>
          Delete
        </Button> */}
      </div>
      <div className={styles.actionsRow}>
        <div className={styles.filters}>
          <SelectAsync
            containerClass={styles.select}
            onChange={val => hangleChangeFilter(val, "vulnerability")}
            placeholder="Filter by: Vulnerability"
            value={filters?.vulnerability || ""}
            isClearable
            cacheOptions
            defaultOptions
            loadOptions={(searchText, prevOptions, { page: pageNum }) => {
              return loadOptions(searchText, prevOptions, pageNum, "vulnerability");
            }}
            onInputChange={() => null}
            additional={{
              page: 0
            }}
          />
          <SelectAsync
            containerClass={styles.select}
            onChange={val => hangleChangeFilter(val, "baseline")}
            placeholder="Filter by: Baseline"
            value={filters?.baseline || ""}
            isClearable
            cacheOptions
            defaultOptions
            loadOptions={(searchText, prevOptions, { page: pageNum }) => {
              return loadOptions(searchText, prevOptions, pageNum, "baseline");
            }}
            onInputChange={() => null}
            additional={{
              page: 0
            }}
          />
          <SelectAsync
            containerClass={styles.select}
            onChange={val => hangleChangeFilter(val, "scan")}
            placeholder="Filter by: Scan"
            value={filters?.scan || ""}
            isClearable
            cacheOptions
            defaultOptions
            loadOptions={(searchText, prevOptions, { page: pageNum }) => {
              return loadOptions(searchText, prevOptions, pageNum, "scan");
            }}
            onInputChange={() => null}
            additional={{
              page: 0
            }}
          />
        </div>
      </div>
      <div className={styles.table}>
        <Table
          data={isLoading ? [] : data}
          columns={notificationsTableColumns}
          loading={isLoading}
          page={page || 0}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          onSortModelChange={model => handleSort(model)}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          onPageChange={onPageChange}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
        />
      </div>
    </ContentContainer>
  );
};

Notifications.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Notifications;
