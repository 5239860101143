import React from "react";

import TextField from "@mui/material/TextField";

const TimeSelect = ({ onChange, value, className }) => {
  const customStyles = {
    width: 205,
    "& .MuiInputLabel-root": {
      fontSize: "20px",
      lineHeight: "20px",
      color: "#2b3858 !important",
      bottom: "7px"
    },
    "& .MuiInput-root": {
      width: "100%",
      boxSizing: "border-box",
      padding: "7px 17px",
      border: "1px solid #d9dfea",
      backgroundColor: "#ffff",
      borderRadius: " 10px",
      color: "#2b3858 !important",
      fontSize: "16px",
      lineHeight: "20px",
      marginTop: "25px",

      "& .MuiInput-input": {
        height: "initial",
        padding: 0
      },

      "&:before": {
        content: "none"
      },
      "&:after": {
        content: "none"
      }
    }
  };

  return (
    <TextField
      id="time"
      label="Start"
      type="time"
      value={value}
      className={className}
      onChange={e => onChange(e.target.value)}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        step: 300 // 5 min
      }}
      sx={customStyles}
      variant="standard"
    />
  );
};

export default TimeSelect;
