/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { onChangeUrlParams, useQuery } from "helper/history";

import {
  deleteImportHistory,
  getImportHistory,
  setImportHistoryParams,
  addTag,
  getDataTypes,
  runReport
} from "store/entities/importHistory/actions";

import { getLabelList } from "store/entities/labels/actions";
import { getLabelListSelector } from "store/entities/labels/selectors";

import { getTagsMini } from "store/entities/tags/actions";
import { tagsMiniSelector } from "store/entities/tags/selectors";

import { addNotification } from "store/entities/notifications/actions";

import { historySelector, dataTypesListSelector } from "store/entities/importHistory/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import {
  ContentContainer,
  Title,
  Search,
  Select,
  ActionButton,
  DateRangePicker
} from "components/simple";

import { CreateTagsModal } from "../CreateTagsModal";

import { Table } from "components/complex";
import DeleteModal from "./DeleteModal";
import DetailsAssetModal from "./DetailsAssetModal";

import styles from "./styles.module.scss";

const History = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getImportHistory.type])(state));

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const [showTag, setShowTag] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetailsAssetModal, setShowDetailsAssetModal] = useState(false);

  const listLabel = useSelector(getLabelListSelector);
  const dataTypesList = useSelector(dataTypesListSelector);

  const { page, pageSize, filters, search } = useSelector(state => state.importHistory.params);
  const { columns, data, total } = useSelector(state => historySelector(state));

  const tagsMiniList = useSelector(tagsMiniSelector);

  const { state } = useLocation();

  // get default request
  const getRequest = () => {
    dispatch(
      setImportHistoryParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: state?.title || "",
        filters: ""
      })
    );
  };

  useEffect(() => {
    getRequest();
    dispatch(getTagsMini());
    dispatch(getLabelList());
    dispatch(getDataTypes());
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setImportHistoryParams({
        page: 0,
        search: v
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setImportHistoryParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setImportHistoryParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setImportHistoryParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = newPage => {
    prevSelectionModel.current = selectionModel;
    onChangeUrlParams("page", newPage + 1);

    if (page !== -1) {
      dispatch(
        setImportHistoryParams({
          page: newPage
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setImportHistoryParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
    const { field, id: uid } = p;
    if (field === "assets") {
      setShowDetailsAssetModal({ id: uid });
    }
  };

  // Actions
  const handleAction = type => {
    if (type === "run") {
      dispatch(runReport());
      return;
    }
    if (!selectionModel?.length) {
      dispatch(addNotification({ msg: "You must select at least one row", type: "warning" }));
      return;
    }
    if (type === "addTag") {
      setShowTag(true);
      return;
    }
    if (type === "delete") {
      setShowDeleteModal(true);
    }
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  const hangleChangeFilter = (val, key) => {
    const res = {
      ...filters,
      [key]: val
    };
    dispatch(
      setImportHistoryParams({
        filters: res,
        page: 0,
        search: "",
        ordering: ""
      })
    );
  };

  const onAddTag = obj => {
    dispatch(addTag(obj));
    setShowTag(false);
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>History</Title>
      <div className={styles.searchRow}>
        <Search value={search} onSearch={handleSearch} />
      </div>
      <div className={styles.actionsRow}>
        <div className={styles.filters}>
          <Select
            containerClass={styles.select}
            onChange={v => hangleChangeFilter(v, "dataTypes")}
            value={filters?.dataTypes || ""}
            placeholder="Filter by Data types"
            options={dataTypesList}
            isClearable
          />
          <DateRangePicker
            placeholder="Filter by date"
            onChange={v => hangleChangeFilter(v, "date")}
            value={filters?.date || ""}
            clearable
          />
          <Select
            containerClass={styles.select}
            onChange={v => hangleChangeFilter(v, "label")}
            value={filters?.label || ""}
            placeholder="Filter by label"
            options={listLabel}
            isClearable
          />
        </div>
        <div className={styles.actions}>
          <ActionButton type="run" onClick={handleAction} className={styles.actionbtn} />
          <ActionButton type="delete" onClick={handleAction} className={styles.actionbtn} />
          <ActionButton type="addTag" onClick={handleAction} className={styles.actionbtn} />
        </div>
      </div>
      <div className={styles.table}>
        <Table
          data={isLoading ? [] : data}
          columns={columns}
          loading={isLoading}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          page={page || 0}
          selectionModel={selectionModel}
          onSortModelChange={model => handleSort(model)}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          onPageChange={onPageChange}
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
        />
      </div>
      <CreateTagsModal
        show={showTag}
        data={selectionModel}
        onClose={() => setShowTag(false)}
        list={tagsMiniList}
        onSubmit={onAddTag}
      />
      <DeleteModal
        show={showDeleteModal}
        onSubmit={() => dispatch(deleteImportHistory({ ids: selectionModel }))}
        onClose={() => setShowDeleteModal(false)}
        count={selectionModel?.length}
      />
      <DetailsAssetModal
        open={Boolean(showDetailsAssetModal)}
        data={showDetailsAssetModal}
        close={() => setShowDetailsAssetModal(false)}
      />
    </ContentContainer>
  );
};

History.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default History;
