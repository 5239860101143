/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { routes } from "router";
import { onChangeUrlParams, useQuery } from "helper/history";

import { deleteMonitor, getMonitors, setMonitorsParams } from "store/entities/monitors/actions";
import { getDataListsDataType } from "store/entities/datalist/actions";

import { getMonitorsSelector } from "store/entities/monitors/selectors";
import { getDataListsDataTypeSelect } from "store/entities/datalist/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Title, Search, Button, Select, Icon } from "components/simple";
import { Table } from "components/complex";

import { AssetSelect, GroupSelect } from "helper/AsyncSelects";

import styles from "./styles.module.scss";

const Monitors = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getMonitors.type])(state));

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const { page, pageSize, filterBy } = useSelector(state => state.monitors);

  const { data, total, columns } = useSelector(state => getMonitorsSelector(state));

  const datalistList = useSelector(getDataListsDataTypeSelect);

  // get default request
  const getRequest = () => {
    dispatch(
      setMonitorsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: "",
        filterBy: ""
      })
    );
  };

  useEffect(() => {
    getRequest();
    dispatch(getDataListsDataType());
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setMonitorsParams({
        page: 0,
        search: v
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setMonitorsParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setMonitorsParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setMonitorsParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setMonitorsParams({
          page: newPage
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setMonitorsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
    const { id } = p;
    history.push(`${routes.monitorsConfiguration}/${id}`);
  };

  const deleteMonitorHandler = () => {
    dispatch(deleteMonitor({ ids: selectionModel }));
  };

  const filterChangeHandler = (val, key) => {
    const res = {
      ...filterBy,
      [key]: val
    };
    dispatch(
      setMonitorsParams({
        filterBy: res,
        page: 0,
        search: "",
        ordering: ""
      })
    );
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Monitors</Title>
      <div className={styles.searchRow}>
        <Search onSearch={handleSearch} />

        <Button
          onClick={deleteMonitorHandler}
          className={styles.btn}
          disabled={!selectionModel.length}
        >
          Delete <Icon.Delete color="#ffff" />
        </Button>
      </div>
      <div className={styles.filterRow}>
        <GroupSelect
          containerClass={styles.select}
          onChange={v => filterChangeHandler(v, "group")}
          value={filterBy?.group || ""}
          placeholder="Filter by group"
          isClearable
        />
        <AssetSelect
          containerClass={styles.select}
          onChange={v => filterChangeHandler(v, "asset")}
          value={filterBy?.asset || ""}
          placeholder="Filter by asset"
          isClearable
        />
        <Select
          containerClass={styles.select}
          onChange={v => filterChangeHandler(v, "data_type")}
          value={filterBy?.data_type || ""}
          placeholder="Filter by data type"
          options={datalistList}
          isClearable
        />
      </div>

      <div className={styles.table}>
        <Table
          data={isLoading ? [] : data}
          columns={columns}
          loading={isLoading}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          page={page || 0}
          selectionModel={selectionModel}
          onSortModelChange={model => handleSort(model)}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          onPageChange={onPageChange}
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
        />
      </div>
    </ContentContainer>
  );
};

Monitors.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Monitors;
