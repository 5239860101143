import React, { useState, useEffect } from "react";
import { Button, Title, Modal } from "components/simple";

import styles from "./styles.module.scss";
import { getTickets } from "store/entities/tickets/service";
import { Table } from "components/complex";
import { columns } from "./columns";
import { getGroups } from "store/entities/groups/service";
import { getAssets, getMonitors } from "store/entities/assets/service";
import { useHistory } from "react-router-dom";
import { routes } from "router";
import { getAuditChoices } from "store/entities/baseline/service";

const customStyles = {
  height: 300,
  width: 1,
  "& .super-app-theme--header": {
    backgroundColor: "#fff",
    borderBottom: "1px solid #E7E6F8"
  },
  "& .super-app-theme--row": {
    backgroundColor: "#fff",
    marginBottom: "0px"
  }
};

const modals = {
  Tickets: {
    request: getTickets,
    redirect: `${routes.tickets}`
  },
  Groups: {
    request: getGroups,
    redirect: `${routes.dataStructureGroups}`
  },
  Monitors: {
    request: getMonitors,
    redirect: `${routes.monitors}`
  },
  "Audit Files": {
    request: getAuditChoices,
    redirect: `${routes.dataStructureBaselines}`
  },
  "Linked Assets": {
    request: getAssets,
    redirect: `${routes.dataStructureAssets}`
  }
};

const AssetDetailsModal = ({ open, close, modalName, assetId }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const onRequest = async () => {
    if (total && total === data.length) return;
    const { request } = modals[modalName];
    setLoading(true);
    const res = await request({ page, assetId, search: "" })
      .then(r => {
        setLoading(false);
        return r.json();
      })
      .catch(() => {
        setLoading(false);
      });
    setPage(page + 1);
    setTotal(res.count);
    setData([...data, ...res.results]);
  };

  const reset = () => {
    setData([]);
    setPage(0);
  };

  const onBack = () => close();

  const onSeeAllHandler = () => {
    history.push(modals[modalName].redirect);
  };

  const onCellClick = row => {
    if (modalName === "Tickets") {
      history.push(`${routes.ticketsDetails}/${row.id}`);
    }
  };

  useEffect(() => () => reset(), [modalName]);

  return (
    <Modal width={1100} isOpen={open} toggle={close}>
      <Title>{modalName}</Title>
      <div className={styles.content}>
        <Table
          data={data}
          columns={columns[modalName]}
          loading={isLoading}
          checkboxVisible={false}
          paginationVisible={false}
          sortingMode={false}
          customStyles={customStyles}
          onCellClick={onCellClick}
          onRowsScrollEnd={onRequest}
          hideFooter
        />
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={onBack} variant="outline" className={styles.modalButton}>
          Back
        </Button>
        <Button onClick={onSeeAllHandler} className={styles.modalButton}>
          See all {modalName !== "Linked Assets" ? modalName : "Assets"}
        </Button>
      </div>
    </Modal>
  );
};

export default AssetDetailsModal;
