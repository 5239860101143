import React from "react";
import { FormLabel } from "@mui/material";

import styles from "./styles.module.scss";

const WeekDayButton = ({ label, checked, onCheck }) => (
  <div
    className={
      checked ? `${styles.weekDayButton} ${styles.weekDayButtonChecked}` : styles.weekDayButton
    }
    onClick={() => onCheck(!checked)}
  >
    {label}
  </div>
);

const WeekDaySelect = ({ containerClass, label, required, value, onValueChange }) => {
  const handleCheck = (name, checked) => {
    let newVal = [...value];
    if (checked) {
      if (!newVal.includes(name)) {
        newVal.push(name);
      }
    } else if (newVal.includes(name)) {
      newVal = newVal.filter(item => item !== name);
    }

    if (onValueChange) {
      onValueChange(newVal);
    }
  };

  return (
    <div className={`${containerClass} ${styles.container} ${required ? styles.required : ""}`}>
      {label && <FormLabel className={styles.inputLabel}>{label}</FormLabel>}
      <div className={styles.weekDayBlockBody}>
        <WeekDayButton
          label="S"
          checked={value.includes("sunday")}
          onCheck={checked => handleCheck("sunday", checked)}
        />
        <WeekDayButton
          label="M"
          checked={value.includes("monday")}
          onCheck={checked => handleCheck("monday", checked)}
        />
        <WeekDayButton
          label="T"
          checked={value.includes("tuesday")}
          onCheck={checked => handleCheck("tuesday", checked)}
        />
        <WeekDayButton
          label="W"
          checked={value.includes("wednesday")}
          onCheck={checked => handleCheck("wednesday", checked)}
        />
        <WeekDayButton
          label="T"
          checked={value.includes("thursday")}
          onCheck={checked => handleCheck("thursday", checked)}
        />
        <WeekDayButton
          label="F"
          checked={value.includes("friday")}
          onCheck={checked => handleCheck("friday", checked)}
        />
        <WeekDayButton
          label="S"
          checked={value.includes("saturday")}
          onCheck={checked => handleCheck("saturday", checked)}
        />
      </div>
    </div>
  );
};

export default WeekDaySelect;
