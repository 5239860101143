import React from "react";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import Router from "./router/router";

import store from "./store";

import "./styles/index.scss";

import Notifications from "components/simple/Notifications";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <div className="App">
            <Router />
            <Notifications />
          </div>
        </Provider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
