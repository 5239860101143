import React, { useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import { Icon } from "components/simple";

const DateRangePickerComponent = ({
  placeholder,
  label,
  onChange,
  value = "",
  clearable = true,
  className,
  ...rest
}) => {
  const [isForcePickerOpen, setIsOpen] = useState(false);

  const onClear = e => {
    e?.stopPropagation();
    onChange("");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={`${styles.container} ${className}`}>
        {label && <div className={styles.label}>{label}</div>}

        <div>
          <DateRangePicker
            {...rest}
            open={isForcePickerOpen}
            onClose={() => setIsOpen(false)}
            inputFormat="MM/dd/yy"
            calendars={1}
            value={value || [null, null]}
            onChange={newValue => {
              onChange(newValue);
            }}
            renderInput={(startProps, endProps) => {
              const start = startProps?.inputProps?.value;
              const end = endProps?.inputProps?.value;

              return (
                <div className={styles.wrapper} onClick={() => setIsOpen(true)}>
                  <span>{value ? `${start}  ${end ? `- ${end}` : ""}` : placeholder} </span>
                  {start && clearable ? (
                    <div className={styles.close} onClick={onClear}>
                      <Icon.Close />
                    </div>
                  ) : (
                    <Icon.Calendar />
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

DateRangePickerComponent.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func
};

DateRangePickerComponent.defaultProps = {
  className: "",
  placeholder: "",
  label: "",
  onChange: () => null
};

export default DateRangePickerComponent;
