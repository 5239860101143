import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verification } from "store/entities/users/actions";
import { createErrorMessageSelector } from "store/entities/error/selector";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

import { styled, Link } from "@mui/material";

import { Button, Input } from "components/simple";

import { ReactComponent as CircleLogo } from "assets/images/CircleLogo.svg";
import { ReactComponent as AccuknoxLoginSvg } from "assets/images/AccuknoxLogin.svg";
import { ReactComponent as AccuknoxLogo } from "assets/images/AccuknoxLogo.svg";

import Icon from "assets/images";

export const CircleLogoImg = styled(CircleLogo)(({ theme }) => {
  return {
    "& linearGradient stop": {
      stopColor: theme.palette.primary.main
    },
    "& g circle": {
      stroke: theme.palette.primary.main
    }
  };
});

export const AccuknoxLoginImage = styled(AccuknoxLoginSvg)(({ theme }) => {
  return {
    position: "absolute",
    height: "100%",
    width: "100%",
    right: 0,
    top: 0
  };
});

export const FormLink = styled(Link)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
  textAlign: "right"
}));

const VerificationCode = () => {
  const dispatch = useDispatch();
  const err = useSelector(state => createErrorMessageSelector([verification.type])(state));
  const [errorMessageCode, setErrorMessageCode] = useState(null);
  const params = useParams();
  const user_id = params?.id;
  const [state, setState] = useState({
    code: ""
  });

  const handleSubmit = e => {
    e.preventDefault();

    if (!state.code.trim()) {
      setErrorMessageCode("this field is required");
      return;
    }
    const { code } = state;

    dispatch(verification({ user_id, token: code?.replaceAll(" ", "") }));
  };

  const handleChange = (val, type) => {
    if (val.trim().length >= 8) {
      return;
    }
    setErrorMessageCode(null);
    const res = val.replace(/ /gm, "");

    let num = `${res.substring(0, 3)} ${res.substring(3, 6)}`;

    num = num.trim();
    setState({
      ...state,
      [type]: num
    });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.icon}>
          <CircleLogoImg />
        </div>
        <div className={styles.title}>Please enter MFA code</div>
        <div className={styles.description}>
          Two-factor authentication (MFA) is enabled for your account. Please enter a code to log
          in.
        </div>

        <div className={styles.content}>
          <div>
            <Input
              label="Enter code"
              value={state.code}
              onChange={val => handleChange(val.target.value, "code")}
              invalid={errorMessageCode || err}
              containerClassName={styles.input}
              className={`${styles.inputcode}`}
            />
          </div>
          <Button className={styles.button} type="submit" disabled={state?.code.trim().length < 7}>
            Login
          </Button>
        </div>
      </form>
      <div className={styles.bg}>
        <AccuknoxLoginImage />
        <div className={styles.logo}>
          <AccuknoxLogo />
        </div>
      </div>
    </div>
  );
};

export default VerificationCode;
