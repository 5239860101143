import React from "react";
import { Box, FormLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";

import styles from "./styles.module.scss";

const DateSelect = ({ containerClass, error, onChange, value, label, required }) => {
  return (
    <div className={`${containerClass} ${styles.container} ${required ? styles.required : ""}`}>
      {label && <FormLabel className={styles.inputLabel}>{label}</FormLabel>}
      <DatePicker
        className={styles.dateCtrl}
        PopperProps={{ style: { zIndex: 20000 }, disablePortal: true }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box className={styles.inputBox}>
            <input className={styles.inputCtrl} ref={inputRef} {...inputProps} />
            {InputProps?.endAdornment}
          </Box>
        )}
        value={value}
        onChange={onChange}
      />
      {error && <div className={styles.error}>{!error ? "" : error}</div>}
    </div>
  );
};

export default DateSelect;
