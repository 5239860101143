import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "router/router";
import { Table } from "components/complex";
import { Button, ContentContainer, Search } from "components/simple";
import { cloudAccountsColumns } from "./tableColumns";

import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionStatusModal,
  changeEnabledStatus,
  setCloudParams
} from "store/entities/cloudAccounts/actions";
import {
  getCloudAccountsSelector,
  getDeleteModal,
  getStatusModal
} from "store/entities/cloudAccounts/selectors";
import StatusModal from "./StatusModal";
import DeleteModal from "./DeleteModal";

const customStyles = {
  height: 610,
  width: 1,
  paddingTop: 0,
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "none !important",
    borderTop: "none !important"
  },
  "& .MuiDataGrid-pinnedColumnHeaders": {
    boxShadow: "none !important"
  },
  "& .MuiDataGrid-pinnedColumns": {
    boxShadow: "none !important",
    "& .super-app-theme--row": {
      borderTopLeftRadius: "10px !important",
      borderBottomLeftRadius: "10px !important"
    }
  },
  "& .super-app-theme--header": {
    backgroundColor: "#fff"
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .super-app-theme--row": {
      borderBottom: "none !important",
      borderBottomRightRadius: "10px",
      borderTopRightRadius: "10px"
    }
  },
  "& .super-app-theme--row": {
    backgroundColor: "#fff",
    border: "1px solid #E7E6F8",
    boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
    marginBottom: "5px",
    "&:last-child": {
      marginBottom: 0
    }
  }
};

const CloudAccounts = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const { page, pageSize } = useSelector(state => state.cloudAccounts.params);
  const { total, data } = useSelector(getCloudAccountsSelector);
  const statusModal = useSelector(getStatusModal);
  const deleteModal = useSelector(getDeleteModal);

  const onAddAccountClick = () => {
    history.push(routes.addCloudAccount);
  };

  const closeStatusModal = () => {
    dispatch(actionStatusModal({ show: false, status: false, id: null }));
  };

  const onStatusChange = () => {
    dispatch(changeEnabledStatus({ id: statusModal.id, status: !statusModal.status }));
  };

  const handleSort = s => {
    if (!s.length) {
      dispatch(
        setCloudParams({
          ordering: ""
        })
      );
      return;
    }

    const { field, sort } = s[0];

    dispatch(
      setCloudParams({
        ordering: sort === "asc" ? field : `-${field}`
      })
    );
  };

  const setPageSize = v => {
    dispatch(
      setCloudParams({
        pageSize: v
      })
    );
  };

  const onPageChange = newPage => {
    if (page !== -1) {
      dispatch(
        setCloudParams({
          page: newPage
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      setCloudParams({
        search
      })
    );
  }, [search]);

  return (
    <ContentContainer className={styles.container}>
      <div className={styles.controls}>
        <Search value={search} onSearch={setSearch} />
        <Button onClick={onAddAccountClick}>Add Account</Button>
      </div>
      <Table
        data={data}
        columns={cloudAccountsColumns}
        onSortModelChange={model => handleSort(model)}
        page={page || 0}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowCount={total}
        paginationMode="server"
        onPageChange={onPageChange}
        checkboxVisible={false}
        customStyles={customStyles}
      />
      <StatusModal
        isOpen={statusModal.show}
        status={statusModal.status}
        close={closeStatusModal}
        onStatusChange={onStatusChange}
      />
      <DeleteModal isOpen={deleteModal.show} accountId={deleteModal.id} />
    </ContentContainer>
  );
};

export default CloudAccounts;
