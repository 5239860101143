import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "store/entities/users/actions";
import { createErrorMessageSelector } from "store/entities/error/selector";
import styles from "./styles.module.scss";

import { styled } from "@mui/material";

import { Button, Input } from "components/simple";

import { ReactComponent as AccuknoxLoginSvg } from "assets/images/AccuknoxLogin.svg";
import { ReactComponent as AccuknoxLogo } from "assets/images/AccuknoxLogo.svg";

import { routes } from "router/router";

export const AccuknoxLoginImage = styled(AccuknoxLoginSvg)(({ theme }) => {
  return {
    position: "absolute",
    height: "100%",
    width: "100%",
    right: 0,
    top: 0
  };
});

export const FormLink = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
  textAlign: "right"
}));

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const err = useSelector(state => createErrorMessageSelector([login.type])(state));
  const [errorMessageEmail, setErrorMessageEmail] = useState(null);
  const [errorMessagePassword, setErrorMessagePassword] = useState(null);

  const [state, setState] = useState({
    email: "",
    password: ""
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (!state.email?.length) {
      setErrorMessageEmail("this field is required");
      return;
    }
    if (!state.password?.length) {
      setErrorMessagePassword("this field is required");
      return;
    }

    const { email, password } = state;

    dispatch(login({ username: email, password }));
  };

  const handleChange = (val, type) => {
    setErrorMessageEmail(null);
    setErrorMessagePassword(null);
    setState({
      ...state,
      [type]: val
    });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.title}>Login</div>
        <div>
          <Input
            label="Email"
            value={state.email}
            onChange={val => handleChange(val.target.value, "email")}
            invalid={errorMessageEmail || Boolean(err)}
            containerClassName={styles.input}
          />
        </div>
        <div>
          <Input
            label="Password"
            value={state.password}
            onChange={val => handleChange(val.target.value, "password")}
            invalid={errorMessagePassword || err}
            type="password"
            containerClassName={styles.input}
            className={`${styles.inputPassword}`}
          />
        </div>
        {/* <div className={main["error-text"]}>{errorMessage}</div> */}
        <FormLink
          onClick={() => {
            history.push(routes.forgotPassword);
          }}
        >
          Forgot password?
        </FormLink>
        <Button className={styles.button} type="submit">
          Login
        </Button>
      </form>
      <div className={styles.bg}>
        <AccuknoxLoginImage />
        <div className={styles.logo}>
          <AccuknoxLogo />
        </div>
      </div>
    </div>
  );
};

export default Login;
