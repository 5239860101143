/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createBaseline,
  getBaselineSources,
  getBaseline,
  editBaseline,
  getLabelList,
  setAuditChoicesParams,
  getAuditChoices
} from "store/entities/baseline/actions";

import {
  getSourcesListSelect,
  getBaselineDataSelector,
  getLabelListSelector
} from "store/entities/baseline/selectors";
import { addNotification } from "store/entities/notifications/actions";

import { createLoadingSelector } from "store/entities/loading/selector";
import { useParams } from "react-router-dom";

import { Select, Button, Title, Input, Icon, Switcher } from "components/simple";

import AddAuditFilesModal from "./AddAuditFilesModal";

import styles from "./styles.module.scss";
import { AllLabelsSelect } from "helper/AsyncSelects";

const CreateBaseline = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const listSource = useSelector(getSourcesListSelect);
  const listLabel = useSelector(getLabelListSelector);
  const baselineData = useSelector(getBaselineDataSelector);

  const id = params?.id;

  const [state, setState] = useState({
    name: "",
    source: "",
    label: "",
    result_value: false
  });

  const [listAuditFiles, setListAuditFiles] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(getBaseline({ id }));
    }
  }, [selectTenant]);

  useEffect(() => {
    dispatch(getBaselineSources());
    dispatch(getLabelList());
  }, [selectTenant]);

  useEffect(() => {
    if (id && baselineData !== null) {
      setState({
        name: baselineData?.name,
        source: baselineData?.source,
        label: listLabel?.find(item => item.value === baselineData?.label) || "",
        result_value: baselineData?.result_value
      });
      setListAuditFiles(baselineData?.audit_files);
    }
  }, [baselineData, listLabel, id]);

  useEffect(() => {
    if (!state.source) return;
    dispatch(
      setAuditChoicesParams({
        source: state?.source,
        label: state?.label,
        isMiniRequest: true
      })
    );
  }, [state?.source?.value, state?.label?.value]);

  const isLoading = useSelector(
    st =>
      createLoadingSelector([createBaseline.type])(st) ||
      createLoadingSelector([editBaseline.type])(st) ||
      createLoadingSelector([getAuditChoices.type])(st)
  );

  const validation = () => {
    let isValid = true;
    const audit_files = listAuditFiles?.map(item => item.value);

    if (!audit_files?.length) {
      isValid = false;
    }

    if (!isValid) {
      dispatch(addNotification({ msg: "You need to add Audit file", type: "warning" }));
    }
    return isValid;
  };

  const onSave = e => {
    e.preventDefault();
    if (validation()) {
      const res = {
        name: state.name,
        source: state?.source?.value,
        label: state?.label?.value,
        result_value: state?.result_value,
        audit_files: listAuditFiles?.map(item => item.value)
      };
      if (id) {
        dispatch(editBaseline({ ...res, id }));
      } else {
        dispatch(createBaseline(res));
      }
    }
  };

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const onAddFiles = res => {
    setListAuditFiles(res);
    closeModal();
  };

  const onRemoveFile = it => {
    const res = listAuditFiles.filter(item => item.value !== it?.value);
    setListAuditFiles(res);
  };

  return (
    <form className={styles.container} onSubmit={onSave}>
      <div className={styles.head}>
        <Title>Create Baseline</Title>
        <Button type="submit" disabled={isLoading}>
          Save
        </Button>
      </div>
      <div className={styles.row}>
        <Input
          type="text"
          placeholder="Name"
          label="Name"
          value={state.name}
          onChange={val => handleChange(val.target.value, "name")}
          containerClassName={styles.searchContainer}
          className={styles.input}
          required
        />
        <Select
          containerClass={styles.select}
          onChange={val => handleChange(val, "source")}
          value={state?.source}
          label="Source"
          placeholder="Source"
          options={listSource}
          required
        />
        <AllLabelsSelect
          containerClass={styles.select}
          onChange={val => handleChange(val, "label")}
          value={state?.label}
          label="Label"
          placeholder="Label"
          closeMenuOnSelect
          isClearable
          cacheOptions
        />
        <Switcher
          label="Result"
          options={{
            active: "Pass",
            inactive: "Fail"
          }}
          value={state?.result_value}
          onChange={val => handleChange(val, "result_value")}
        />
      </div>
      <div className={styles.buttonRow}>
        <Button variant="outline" onClick={() => setOpenModal(true)}>
          Add
        </Button>
      </div>
      <div className={styles.list}>
        {listAuditFiles?.map(item => {
          return (
            <div className={styles.item} key={item?.label}>
              <Icon.File />
              <div className={styles.itemName}>{item?.label}</div>
              <div className={styles.buttonRemove} onClick={() => onRemoveFile(item)}>
                <Icon.Delete />
              </div>
            </div>
          );
        })}
      </div>

      <AddAuditFilesModal
        isLoading={isLoading}
        open={openModal}
        close={closeModal}
        onAddFiles={onAddFiles}
        data={listAuditFiles}
        params={state}
      />
    </form>
  );
};

CreateBaseline.propTypes = {};

export default CreateBaseline;
