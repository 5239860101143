import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { goBack } from "helper/history";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "../notifications/actions";

const getTicketsParams = state => {
  return {
    page: state.tickets.ticketsParams.page,
    pageSize: state.tickets.ticketsParams.pageSize,
    ordering: state.tickets.ticketsParams.ordering,
    search: state.tickets.ticketsParams.search,
    filterBy: state.tickets.ticketsParams.filterBy.value,
    type: state.tickets.ticketsParams.type.value,
    status: state.tickets.ticketsParams.status.value || "",
    configuration: state.tickets.ticketsParams.configuration.value || ""
  };
};

// Workers //

// Set Tickets Params
function* setTicketsParamsWorker() {
  const { page, pageSize, ordering, search, filterBy, type, status, configuration } = yield select(
    getTicketsParams
  );
  yield put(
    actions.getTickets({
      page,
      pageSize,
      ordering,
      search,
      filterBy,
      type,
      status,
      configuration
    })
  );
}

// Get Tickets
function* getTicketsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTickets), payload);
    yield put(actions.getTicketsSuccess(response));
  } catch (error) {
    yield put(actions.getTicketsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Ticket
function* getTicketWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTicket), payload);
    yield put(actions.getTicketSuccess(response));
  } catch (error) {
    yield put(actions.getTicketFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Create Ticket
function* createTicketWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.createTicket), payload);
    yield put(actions.createTicketSuccess(response));
    yield put(addNotification({ msg: `You have successfully created ${payload?.length} tickets` }));
    goBack();
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.createTicketFailed(error));
  }
}

// Edit Ticket
function* editTicketWorker({ payload }) {
  const { action, ...rest } = payload;
  try {
    const response = yield call(makeRequest(service.editTicket), rest);
    yield put(actions.getTicket(response.id));
    yield put(actions.editTicketSuccess(response));
    yield put(addNotification({ msg: `Ticket successfully updated` }));
    if (action) {
      goBack();
    }
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.editTicketFailed(error));
  }
}

// Delete Ticket
function* deleteTicketWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteTicket), id)));
    yield put(actions.deleteTicketSuccess(response));
    yield put(actions.setTicketsParams());
    yield put(
      addNotification({ msg: `${ids?.length > 1 ? "Tickets" : "Ticket"} successfully deleted` })
    );
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.deleteTicketFailed(error));
  }
}

// Close Ticket
function* closeTicketWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.closeTicket), id)));
    yield put(actions.closeTicketSuccess(response));
    yield put(actions.setTicketsParams());
    yield put(
      addNotification({ msg: `${ids?.length > 1 ? "Tickets" : "Ticket"} successfully closed` })
    );
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.closeTicketFailed(error));
  }
}

// Get TicketsConfig
function* getTicketsConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTicketsConfig), payload);
    yield put(actions.getTicketsConfigSuccess(response));
  } catch (error) {
    yield put(actions.getTicketsConfigFailed(error));
  }
}

// Get Vulnerability Tickets
function* getVulnerabilityTicketsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getVulnerabilityTickets), payload);
    yield put(actions.getVulnerabilityTicketsSuccess(response));
  } catch (error) {
    yield put(actions.getVulnerabilityTicketsFailed(error));
  }
}

// Get Ticket Comments
function* getTicketCommentsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTicketComments), payload);
    yield put(actions.getTicketCommentsSuccess(response));
  } catch (error) {
    yield put(actions.getTicketCommentsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Post Ticket Comment
function* postTicketCommentWorker({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield call(makeRequest(service.postTicketComment), data);
    yield put(actions.postTicketCommentSuccess(response));
    yield put(actions.getTicketComments(data.ticket_id));
    yield put(addNotification({ msg: `Comment was successfully added` }));
    callback();
  } catch (error) {
    yield put(actions.postTicketCommentFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Post Vulnerability Ticket Comment
function* postVulnerabilityTicketCommentWorker({ payload }) {
  const { data, findingId, successCallback } = payload;
  try {
    const response = yield call(makeRequest(service.postVulnerabilityTicketComment), data);
    yield put(actions.postVulnerabilityTicketCommentSuccess(response));
    yield put(actions.getVulnerabilityTickets(findingId));
    yield put(addNotification({ msg: `Comment was successfully added` }));
    successCallback();
  } catch (error) {
    yield put(actions.postVulnerabilityTicketCommentFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Tickets Preview
function* getTicketsPreviewWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTicketsPreview), payload);
    yield put(actions.getTicketsPreviewSuccess(response));
  } catch (error) {
    yield put(actions.getTicketsPreviewFailed(error));
  }
}

// Get Baseline Tickets
function* getBaselineTicketsWorker({ payload }) {
  const { noTicketsCallback, ...rest } = payload;
  try {
    const response = yield call(makeRequest(service.getBaselineTickets), rest);
    yield put(actions.getBaselineTicketsSuccess(response));
    if (!response.length) {
      yield put(addNotification({ msg: `First you need to create a ticket`, type: "warning" }));
      noTicketsCallback();
    }
  } catch (error) {
    yield put(actions.getBaselineTicketsFailed(error));
  }
}

// Post Baseline Ticket Comment
function* postBaselineTicketCommentWorker({ payload }) {
  const { data, baselineId, controlId, successCallback } = payload;
  try {
    const response = yield call(makeRequest(service.postBaselineTicketComment), data);
    yield put(actions.postBaselineTicketCommentSuccess(response));
    yield put(actions.getBaselineTickets({ baselineId, controlId }));
    yield put(addNotification({ msg: `Comment was successfully added` }));
    successCallback();
  } catch (error) {
    yield put(actions.postBaselineTicketCommentFailed(error));
    yield put(addNotification({ msg: `Failed to add comment`, type: "error" }));
  }
}

// Get tickets related-types
function* getTicketsRelatedTypesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTicketsRelatedTypes), payload);
    yield put(actions.getTicketsRelatedTypesSuccess(response));
  } catch (error) {
    yield put(actions.getTicketsRelatedTypesFailed(error));
  }
}

// Get tickets status list
function* getTicketsStatusListWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTicketsStatusList), payload);
    yield put(actions.getTicketsStatusListSuccess(response));
  } catch (error) {
    yield put(actions.getTicketsStatusListFailed(error));
  }
}

// Workers End//

// Watchers //

// Set Tickets Params
function* watchSetTicketsParamsWorker() {
  yield takeEvery(actions.setTicketsParams, setTicketsParamsWorker);
}

// Get Tickets
function* watchGetTicketsWorker() {
  yield takeEvery(actions.getTickets, getTicketsWorker);
}

// Get Ticket
function* watchGetTicketWorker() {
  yield takeEvery(actions.getTicket, getTicketWorker);
}

// Сreate Ticket
function* watchCreateTicketWorker() {
  yield takeEvery(actions.createTicket, createTicketWorker);
}

// Edit Ticket
function* watchEditTicketWorker() {
  yield takeEvery(actions.editTicket, editTicketWorker);
}

// Delete Ticket
function* watchDeleteTicketWorker() {
  yield takeEvery(actions.deleteTicket, deleteTicketWorker);
}

// Close Ticket
function* watchCloseTicketWorker() {
  yield takeEvery(actions.closeTicket, closeTicketWorker);
}

// Get TicketsConfig
function* watchGetTicketsConfigWorker() {
  yield takeEvery(actions.getTicketsConfig, getTicketsConfigWorker);
}

// Get Vulnerability Tickets
function* watchGetVulnerabilityTicketsWorker() {
  yield takeEvery(actions.getVulnerabilityTickets, getVulnerabilityTicketsWorker);
}

// Get Ticket Comments
function* watchGetTicketCommentsWorker() {
  yield takeEvery(actions.getTicketComments, getTicketCommentsWorker);
}

// Post Ticket comment
function* watchPostTicketCommentWorker() {
  yield takeEvery(actions.postTicketComment, postTicketCommentWorker);
}

// Post Vulnerability Ticket comment
function* watchPostVulnerabilityTicketCommentWorker() {
  yield takeEvery(actions.postVulnerabilityTicketComment, postVulnerabilityTicketCommentWorker);
}

// Get Baseline Tickets
function* watchBaselineTicketsWorker() {
  yield takeEvery(actions.getBaselineTickets, getBaselineTicketsWorker);
}

// Post Baseline Ticket comment
function* watchPostBaselineTicketCommentWorker() {
  yield takeEvery(actions.postBaselineTicketComment, postBaselineTicketCommentWorker);
}

// Get Tickets Preview
function* watchGetTicketsPreviewWorker() {
  yield takeEvery(actions.getTicketsPreview, getTicketsPreviewWorker);
}

// Get tickets related-types
function* watchGetTicketsRelatedTypesWorker() {
  yield takeEvery(actions.getTicketsRelatedTypes, getTicketsRelatedTypesWorker);
}

// Get tickets status list
function* watchGetTicketsStatusListWorker() {
  yield takeEvery(actions.getTicketsStatusList, getTicketsStatusListWorker);
}

// Watchers End//

export default function* rootSaga() {
  yield all([
    fork(watchSetTicketsParamsWorker),
    fork(watchGetTicketsWorker),
    fork(watchGetTicketWorker),
    fork(watchCreateTicketWorker),
    fork(watchEditTicketWorker),
    fork(watchDeleteTicketWorker),
    fork(watchCloseTicketWorker),
    fork(watchGetTicketsConfigWorker),
    fork(watchGetVulnerabilityTicketsWorker),
    fork(watchGetTicketCommentsWorker),
    fork(watchPostTicketCommentWorker),
    fork(watchPostVulnerabilityTicketCommentWorker),
    fork(watchGetTicketsPreviewWorker),
    fork(watchBaselineTicketsWorker),
    fork(watchPostBaselineTicketCommentWorker),
    fork(watchGetTicketsRelatedTypesWorker),
    fork(watchGetTicketsStatusListWorker)
  ]);
}
