import { useState } from "react";

export const useKeyValueList = () => {
  const [keyValList, setKeyValList] = useState([]);

  const ctrlObj = {
    reset: initData => {
      if (initData) {
        const keyList = Array.from(Object.keys(initData));

        const newList = [];
        keyList.forEach(key => newList.push({ key, value: initData[key] }));

        setKeyValList(newList);
      } else {
        setKeyValList([]);
      }
    },

    init: initData => {
      if (initData?.length) {
        setKeyValList(initData);
      } else {
        setKeyValList([]);
      }
    },

    addItem: () => {
      setKeyValList([...keyValList, { key: "", value: "" }]);
      return keyValList.length;
    },

    setItemKey: (id, key) => {
      if (id < keyValList.length) {
        const newKeyValList = keyValList.slice();
        newKeyValList[id].key = key;

        setKeyValList(newKeyValList);
      }
    },

    setItemValue: (id, value) => {
      if (id < keyValList.length) {
        const newKeyValList = keyValList.slice();
        newKeyValList[id].value = value;

        setKeyValList(newKeyValList);
      }
    },

    removeItem: id => {
      if (id < keyValList.length) {
        const newKeyValList = [...keyValList];
        newKeyValList.splice(id, 1);

        setKeyValList(newKeyValList);
      }
    }
  };

  return [keyValList, ctrlObj];
};
