import { createDraftSafeSelector } from "@reduxjs/toolkit";

import renderCellExpand from "helper/renderCellExpand";

const targets = state => state.targets?.targets;
const targetTypes = state => state.targets?.targetTypes;
const targetTags = state => state.targets?.targetTags?.results;
const targetAssets = state => state.targets?.assets?.results;

const TARGETS_COLUMNS = [
  {
    headerName: "ID",
    field: "id",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    sortable: true
  },
  {
    headerName: "Name",
    field: "name",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    sortable: true
  },
  {
    headerName: "Target type",
    field: "target_type",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    sortable: true
  },
  {
    headerName: "Tags",
    field: "tags",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    sortable: true
  }
];

function findTargetTypeName(id, typesList) {
  if (!id || !typesList) {
    return "";
  }

  const typeItem = typesList.find(item => item.id === id);
  return typeItem ? typeItem.name : "";
}

function buildTargetData(src, typesList) {
  return src?.map(item => ({
    id: item.id,
    name: item.name,
    tags: item?.tags?.map(key => key?.value)?.join(", "),
    target_type: findTargetTypeName(item.target_type, typesList)
  }));
}

export const getTargetsSelector = createDraftSafeSelector(
  targets,
  targetTypes,
  (state, typesList) => ({
    total: state.count,
    count: state.count,
    columns: TARGETS_COLUMNS,
    data: buildTargetData(state.results, typesList)
  })
);

export const getTargetTypeOptionsSelector = createDraftSafeSelector(targetTypes, state => {
  if (!state) {
    return [];
  }

  return state.map(item => ({
    value: item.id,
    label: item.name
  }));
});

export const getTargetTagOptionsSelector = createDraftSafeSelector(targetTags, state => {
  if (!state) {
    return [];
  }

  return state.map(tag => ({
    value: tag.id,
    label: tag.value
  }));
});

export const getTargetAssetsOptionsSelector = createDraftSafeSelector(targetAssets, state => {
  if (!state) {
    return [];
  }

  return state.map(asset => ({
    value: asset.id,
    label: asset.title
  }));
});

export const getPlaybooksSelector = createDraftSafeSelector(
  state => state.targets.playbooks,
  state => {
    if (!state) {
      return [];
    }

    return state?.results.map(playbook => ({
      value: playbook.id,
      label: playbook.name
    }));
  }
);

export const getMinionsSelector = createDraftSafeSelector(
  state => state.targets.minions,
  state => {
    if (!state) {
      return [];
    }

    return state?.results.map(minion => ({
      value: minion.id,
      label: minion.name
    }));
  }
);

export const getVariablesSelector = createDraftSafeSelector(
  state => state.targets.variables,
  state => {
    if (!state.count) {
      return [];
    }

    return state?.results;
  }
);

export const getVariableOptionsSelector = createDraftSafeSelector(
  state => state.targets.variableOptions,
  state => {
    if (!state.count) {
      return [];
    }

    return state?.results.map(variable => ({
      value: variable.id,
      label: variable.full_name
    }));
  }
);

export const getPlaybookConfigsSelector = createDraftSafeSelector(
  state => state.targets.playbookConfigs,
  state => {
    if (!state?.results?.length) {
      return {};
    }

    const configs = state?.results.reduce((acc, config) => {
      return {
        ...acc,
        [config.tag.id]: {
          configId: config.id,
          playbook: { value: config.playbook.id, label: config.playbook.name },
          minion: { value: config.minion.id, label: config.minion.name },
          label: { value: config.label.id, label: config.label.name },
          target: config.target,
          tag: config.tag,
          schedule: config.schedule,
          parameters: config.variables.reduce(
            (accum, variable) => ({
              ...accum,
              [variable.function_variable]: {
                value: variable.variable,
                label: variable.variable_name
              }
            }),
            {}
          )
        }
      };
    }, {});

    const tags = state?.results.map(config => ({ value: config.tag.id, label: config.tag.name }));

    return { tags, configs };
  }
);
