/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { routes } from "router";

import { editTicket, getTicket } from "store/entities/tickets/actions";

import {
  getPriorities,
  getStatuses,
  getPrioritiesFreshService,
  getConnectWiseConfig
} from "store/entities/tickets/service";

import { getTicketSelector } from "store/entities/tickets/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Button, Input, Select, TextEditor } from "components/simple";
import DetailsModal from "./DetailsModal";

import styles from "./styles.module.scss";
import { formatDate } from "helper/DateLib";

const Details = ({ id }) => {
  const [state, setState] = useState({});
  const selectTenant = useSelector(s => s.users.selectTenant);
  const [focus, setFocus] = useState(false);
  const history = useHistory();

  const [priorityOptions, setPriorityOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const ticket = useSelector(s => getTicketSelector(s));

  const isSaveLoading = useSelector(s => createLoadingSelector([editTicket.type])(s));
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const inputChangeHandler = (val, name) => {
    if (name === "priority" || name === "status") {
      setState({
        ...state,
        [name]: val.value
      });
      return;
    }
    setState({
      ...state,
      [name]: val
    });
  };

  useEffect(() => {
    return () => setState({});
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getTicket(id));
      return;
    }
    setState({});
  }, [id, selectTenant]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(ticket).length !== 0 && id) {
      setState({
        ...ticket
      });

      if (ticket?.ticket_profile_type === "freshserviceprofile") {
        getPrioritiesFreshService({ id: ticket?.ticket_profile_id }).then(res => {
          const list = Object.entries(res?.priorities).map(([key, val]) => {
            return { value: key, label: val };
          });
          setPriorityOptions(list);
        });
      } else if (ticket?.ticket_profile_type === "connectwiseprofile") {
        getConnectWiseConfig({ id: ticket?.ticket_profile_id }).then(res => {
          const list = Object.entries(res?.priorities).map(([key, val]) => {
            return { value: key, label: val };
          });
          setPriorityOptions(list);
        });
      } else {
        getPriorities({ id: ticket?.ticket_profile_id, model: ticket?.ticket_profile_type }).then(
          res => {
            if (typeof res[0] !== "object") {
              return [];
            }
            const list = res?.map(item => ({ value: item.id, label: item.name }));
            setPriorityOptions(list);
          }
        );
      }

      getStatuses({ id: ticket?.ticket_profile_id, type: ticket?.ticket_profile_type }).then(
        res => {
          const list = res?.map(([value, label]) => ({ value, label }));
          setStatusOptions(list);
        }
      );
    }
  }, [ticket]);

  const saveHandler = e => {
    e.preventDefault();
    const action = e.nativeEvent.submitter.name;
    const data = {
      ...state
    };
    delete data.related_objects;

    if (id) {
      dispatch(
        editTicket({
          id,
          data,
          action
        })
      );
    }
  };

  const getOptionValue = (options, v) => {
    return options?.find(item => item?.value === v);
  };

  const onBlur = () => {
    setFocus(false);
  };
  const onFocus = () => {
    setFocus(true);
  };

  const openDetails = it => {
    if (it.type === "finding") {
      history.push(`${routes.vulnerabilitiesDetails}/${it?.id}`);
      return;
    }
    setDetailsModalOpen(it);
  };

  const {
    created_by,
    updated_by,
    label,
    project,
    baseline,
    issue_type,
    ticket_profile_type,
    desk_key,
    third_party_user,
    assets,
    closed_at,
    related_objects,
    attachment,
    desk_url
  } = state;

  return (
    <ContentContainer className={styles.container}>
      <form onSubmit={saveHandler}>
        <div className={styles.row}>
          <div className={styles.block}>
            <div>Updated by</div>
            <div>{updated_by || "-"}</div>
          </div>
          <div className={styles.block}>
            <div>Created by</div>
            <div>{created_by}</div>
          </div>
          <div className={styles.block}>
            <div>Label</div>
            <div>{label || "-"}</div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.block}>
            <div>Baseline</div>
            <div>{baseline || "-"}</div>
          </div>
          <div className={styles.block}>
            <div>Project</div>
            <div>{project || "-"}</div>
          </div>
          <Select
            label="Priority"
            onChange={v => inputChangeHandler(v, "priority")}
            value={getOptionValue(priorityOptions, state.priority)}
            options={priorityOptions}
            containerClass={styles.select}
            required={ticket?.ticket_profile_type === "freshserviceprofile"}
          />
        </div>

        <div className={styles.row}>
          <div className={styles.block}>
            <div>Issue type</div>
            <div>{issue_type || "-"}</div>
          </div>
          <div className={styles.block}>
            <div>Ticket profile type</div>
            <div>{ticket_profile_type || "-"}</div>
          </div>
          <div className={styles.block}>
            <div>Ticket Number</div>
            <div>{desk_key || "-"}</div>
          </div>
        </div>

        <div className={`${styles.row} ${styles.row2fr}`}>
          <div className={styles.innerColumn}>
            <Input
              label="Title"
              type="title"
              value={state.title}
              onChange={e => inputChangeHandler(e.target.value, "title")}
              containerClassName={styles.input}
              required
              onBlur={onBlur}
              onFocus={onFocus}
            />
            <div className={styles.block}>
              <div>Third party user</div>
              <div>{third_party_user || "-"}</div>
            </div>
          </div>
          <div className={`${styles.block} ${styles.list}`}>
            <div>Assets</div>

            {assets?.length
              ? assets.map(asset => (
                  <Link to={`${routes.dataStructureAssetsDetails}/${asset.id}`} key={asset.id}>
                    {asset.title}
                  </Link>
                ))
              : "-"}
          </div>
        </div>

        <div className={`${styles.row} ${styles.row2fr}`}>
          <Select
            label="Status"
            onChange={v => inputChangeHandler(v, "status")}
            value={getOptionValue(statusOptions, state.status)}
            options={statusOptions}
            containerClass={styles.select}
            required
          />
          <div className={styles.innerBlocks}>
            <div>
              <div>Closed Date</div>
              <div>{closed_at ? formatDate(closed_at, "DD/MM/YYYY") : "-"}</div>
            </div>
            <div>
              <div>Closed Time</div>
              <div>{closed_at ? formatDate(closed_at, "hh:mm A") : "-"}</div>
            </div>
          </div>
        </div>
        <div className={styles.related_obj}>
          <div className={styles.subTitlte}>Related objects</div>
          <div className={styles.related_obj_list}>
            {related_objects?.map(item => {
              return (
                <div className={styles.item} key={item.id} onClick={() => openDetails(item)}>
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>

        <div className={`${styles.row} ${styles.row2fr}`}>
          <div className={styles.innerColumn}>
            <div className={styles.block}>
              <div>Ticket attachment</div>
              <div>{attachment || "-"}</div>
            </div>
            <div className={styles.block}>
              <div>Link</div>
              <div>
                {desk_url ? (
                  <a className={styles.link} target="_blank" rel="noreferrer" href={desk_url}>
                    {desk_url}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <TextEditor
            label="Ticket Description"
            value={state?.description}
            handleChange={v => inputChangeHandler(v, "description")}
            optionsList={{ autofocus: !focus }}
            onFocus={onFocus}
            isPreviewOn
          />
        </div>

        <div className={styles.btnsContainer}>
          <Button name="goBack" type="submit" isLoading={isSaveLoading} className={styles.btn}>
            Save
          </Button>
          <Button type="submit" variant="outline" className={styles.btn}>
            Save and continue editing
          </Button>
        </div>
      </form>
      <DetailsModal
        isOpen={Boolean(isDetailsModalOpen)}
        onCloseModal={() => setDetailsModalOpen(false)}
        data={isDetailsModalOpen}
        history={history}
      />
    </ContentContainer>
  );
};

Details.propTypes = {};

export default Details;
