/* eslint-disable camelcase */
import React, { useEffect } from "react";

import { userProfilesTableColumns } from "../../tableColumns";
import { useSelector, useDispatch } from "react-redux";
import { createLoadingSelector } from "store/entities/loading/selector";
import { getUserProfiles, setUserProfilesParams } from "store/entities/settings/actions";
import { getUserProfilesSelector } from "store/entities/settings/selectors";

import { routes } from "router";

import { Table } from "components/complex";
import { ContentContainer, Title, Button, Search } from "components/simple";

import styles from "./styles.module.scss";

const UserProfiles = ({ history }) => {
  const dispatch = useDispatch();

  const { page, pageSize } = useSelector(state => state.settings.userProfiles);
  const { data, total } = useSelector(getUserProfilesSelector);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getUserProfiles.type])(state));

  // get default request
  const getRequest = () => {
    dispatch(setUserProfilesParams({ page: 0, ordering: "", search: "" }));
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  // Next page
  const onPageChange = newPage => {
    dispatch(setUserProfilesParams({ page: newPage }));
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setUserProfilesParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setUserProfilesParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setUserProfilesParams({
          ordering: ""
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setUserProfilesParams({
        pageSize: v
      })
    );
  };

  const onCellClick = v => {
    const { id } = v;
    history.push(`${routes.settingsUserProfilesDetails}/${id}`);
  };

  const addUserHandler = () => {
    history.push(routes.settingsUserProfilesDetails);
  };

  const customStyles = {
    height: 710,
    width: 1,
    padding: "30px",
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    }
  };

  const tableConfig = {
    hideSelectedRowCount: true,
    checkboxVisible: false
  };

  // Search
  const handleSearch = text => {
    dispatch(
      setUserProfilesParams({
        page: 0,
        search: text
      })
    );
  };

  return (
    <ContentContainer>
      <div className={styles.controls}>
        <Title>User Profiles</Title>
        <div className={styles.btnsContainer}>
          <Search onSearch={handleSearch} />
          <Button className={styles.btn} onClick={addUserHandler} icon="plus">
            Add user
          </Button>
          {/* <Button
            className={`${styles.btn} ${styles.delete}`}
            onClick={deleteHandler}
            disabled={!selectionModel?.length}
          >
            Delete
            <Icon.Delete color="#ffff" />
          </Button> */}
        </div>
      </div>
      <Table
        data={isLoading ? [] : data}
        columns={userProfilesTableColumns}
        loading={isLoading}
        page={page || 0}
        rowCount={total}
        onSortModelChange={model => handleSort(model)}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        paginationMode="server"
        onPageChange={onPageChange}
        onCellClick={e => onCellClick(e)}
        customStyles={customStyles}
        {...tableConfig}
      />
    </ContentContainer>
  );
};

UserProfiles.propTypes = {};

export default UserProfiles;
