/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getPermissions,
  getUsers,
  editRole,
  addRole,
  getRole
} from "store/entities/settings/actions";
import {
  usersEmailsSelect,
  permissionsSelect,
  roleSelector
} from "store/entities/settings/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Title, Button, Input, Select } from "components/simple";

import styles from "./styles.module.scss";

const RbacRoleDetails = () => {
  const initialState = {
    name: "",
    permissions: [],
    user_profile: []
  };
  const [state, setState] = useState({ ...initialState });
  const selectTenant = useSelector(s => s.users.selectTenant);

  const role = useSelector(roleSelector);
  const usersOptions = useSelector(usersEmailsSelect);
  const permissionsOptions = useSelector(permissionsSelect);

  const isSaveLoading = useSelector(
    s => createLoadingSelector([editRole.type])(s) || createLoadingSelector([addRole.type])(s)
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  const inputChangeHandler = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(getRole({ id }));
      return;
    }
    setState({
      ...initialState
    });
  }, [id, selectTenant]);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPermissions());
  }, [selectTenant]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(role).length !== 0 && id && role?.permissions) {
      const { permissions, user_profile } = role;

      const permissionsSelected = permissionsOptions.filter(option => {
        return permissions.find(permission => permission === option.value);
      });
      const usersSelected = usersOptions.filter(option => {
        return user_profile.find(user => user.id === option.value);
      });
      setState({
        ...role,
        permissions: permissionsSelected,
        user_profile: usersSelected
      });
    }
  }, [role, permissionsOptions, usersOptions]);

  const saveHandler = e => {
    e.preventDefault();
    const { name, permissions, user_profile } = state;
    const permissionsIds = permissions.map(p => p.value);
    const usersIds = user_profile.map(u => u.value);
    if (id) {
      dispatch(
        editRole({
          id,
          data: {
            name,
            visible_permissions: permissionsIds,
            user_profile: usersIds
          }
        })
      );
      return;
    }
    dispatch(
      addRole({
        name,
        visible_permissions: permissionsIds,
        user_profile: usersIds
      })
    );
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>{id ? "Role details" : "Add role"}</Title>
      <form onSubmit={saveHandler}>
        <Input
          label="Role"
          value={state.name}
          onChange={e => inputChangeHandler(e.target.value, "name")}
          containerClassName={styles.input}
          required
        />
        <Select
          containerClass={styles.select}
          onChange={v => inputChangeHandler(v, "permissions")}
          value={state.permissions}
          label="Permissions"
          options={permissionsOptions}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          allowSelectAll
        />
        <Select
          containerClass={styles.select}
          onChange={v => inputChangeHandler(v, "user_profile")}
          value={state.user_profile}
          label="Users"
          options={usersOptions}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
        />
        <Button type="submit" className={styles.btn} isLoading={isSaveLoading}>
          Save
        </Button>
      </form>
    </ContentContainer>
  );
};

RbacRoleDetails.propTypes = {};

export default RbacRoleDetails;
