import React from "react";
import PropTypes from "prop-types";

import * as routes from "router/routes";

import { Route } from "react-router-dom";
import Login from "../Login";
import VerificationCode from "../VerificationCode";

import ResetPassword from "../ResetPassword";
import ForgotPassword from "../ForgotPassword";

import styles from "./styles.module.scss";

const AuthContainer = () => {
  return (
    <div className={styles.container}>
      <Route path={routes.loginScreen} component={Login} />
      <Route exact path={`${routes.verification}/:id`} component={VerificationCode} />
      <Route
        exact
        path={`${routes.resetPasswordScreen}/:uid/:user_token`}
        component={ResetPassword}
      />
      <Route exact path={routes.forgotPassword} component={ForgotPassword} />
    </div>
  );
};

AuthContainer.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({})
};

AuthContainer.defaultProps = {
  location: {}
};

export default AuthContainer;
