import _ from "lodash";

export const createErrorMessageSelector = actions => state => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  return (
    _(actions)
      .map(action => {
        const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action);
        const [, requestName] = matches;
        return _.get(state, `error.${requestName}`);
      })
      .compact()
      .first() || ""
  );
};
// HOW USE:  const err = useSelector(state => createErrorMessageSelector([getUser.type])(state));
