/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getLabelList } from "store/entities/labels/actions";
import {
  // getPermissions,
  getUserProfilesGroup,
  getOneUserProfile,
  deleteUserProfiles,
  addUserProfiles,
  editUserProfiles
} from "store/entities/settings/actions";

import { getRoles } from "store/entities/settings/service";

import {
  ContentContainer,
  Title,
  Button,
  Input,
  Tags,
  Checkbox,
  // Select,
  SelectAsync
} from "components/simple";

import styles from "./styles.module.scss";

import { getLabelListSelector } from "store/entities/labels/selectors";
import {
  getGroupsListForUserProfileSelect,
  // permissionsSelect,
  getOneUserProfileSelector
} from "store/entities/settings/selectors";

const UserProfilesDetails = () => {
  const params = useParams();
  const id = params?.id;
  const [state, setState] = useState({
    email: "",
    role: "",
    first_name: "",
    last_name: "",
    // permissions: "",
    labels: [],
    groups: [],
    mfa: false
  });

  const listLabel = useSelector(getLabelListSelector);
  const groupList = useSelector(getGroupsListForUserProfileSelect);
  // const permissions = useSelector(permissionsSelect);
  const editData = useSelector(getOneUserProfileSelector);

  const selectTenant = useSelector(st => st.users.selectTenant);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getOneUserProfile({ id }));
    }
  }, [id, selectTenant]);

  useEffect(() => {
    if (editData && id) {
      setState(editData);
    }
  }, [editData]);

  useEffect(() => {
    // dispatch(getPermissions());
    dispatch(getLabelList());
    dispatch(getUserProfilesGroup());
  }, [selectTenant]);

  const onSave = e => {
    e.preventDefault();
    const res = {
      user: {
        email: state?.email,
        first_name: state?.first_name,
        last_name: state?.last_name
      },
      role_id: state?.role?.value,
      label_ids: state?.labels?.map(item => item?.value),
      group_ids: state?.groups?.map(item => item?.value),
      mfa: state?.mfa
    };
    if (id) {
      dispatch(editUserProfiles({ id, data: { ...res } }));
      return;
    }
    dispatch(addUserProfiles(res));
  };

  const inputChangeHandler = (val, key) => {
    setState({ ...state, [key]: val });
  };

  const onDelete = () => {
    dispatch(deleteUserProfiles({ id }));
  };

  const requst = async (search, page) => {
    try {
      const res = await getRoles({ search, page, pageSize: 20 }).then(r => r.json());
      const listOptions = res?.results?.map(item => ({ value: item?.id, label: item?.name }));
      return { options: listOptions, next: res?.next };
    } catch (e) {
      // return console.log("err", e);
    }
  };

  const loadOptions = async (search, prevOptions, { page }) => {
    const { options, next } = await requst(search, page);
    const hasMore = !search ? Boolean(next) : false;

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    };
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>User profile details</Title>
      <form className={styles.form} onSubmit={onSave}>
        <div className={styles.content}>
          <Input
            value={state.email}
            containerClassName={styles.inputContainer}
            label="Email"
            type="email"
            required
            autoComplete="off"
            onChange={val => inputChangeHandler(val.target.value, "email")}
          />

          <Input
            value={state.first_name}
            containerClassName={styles.inputContainer}
            label="First name"
            type="text"
            required
            onChange={val => inputChangeHandler(val.target.value, "first_name")}
          />

          <Input
            value={state.last_name}
            containerClassName={styles.inputContainer}
            label="Last name"
            type="text"
            required
            onChange={val => inputChangeHandler(val.target.value, "last_name")}
          />

          <SelectAsync
            containerClass={styles.selectPanel}
            onChange={val => inputChangeHandler(val, "role")}
            value={state?.role}
            placeholder="Role"
            label="Role"
            isClearable
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            onInputChange={() => null}
            required
            additional={{
              page: 0
            }}
          />

          {/* <Select
            containerClass={styles.select}
            onChange={v => inputChangeHandler(v, "permissions")}
            value={state.permissions}
            placeholder="Permissions"
            label="Permissions"
            options={permissions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable={false}
          /> */}

          <Tags
            values={state.labels}
            list={listLabel}
            onChange={val => inputChangeHandler(val, "labels")}
            label="Labels"
          />
          <Tags
            values={state.groups}
            list={groupList}
            onChange={val => inputChangeHandler(val, "groups")}
            label="Groups"
          />
        </div>
        <div>
          <Checkbox
            value={state.mfa}
            onChange={val => inputChangeHandler(val, "mfa")}
            label="MFA"
            className={styles.checkbox}
            disabled={!state.mfa}
          />
        </div>

        <div className={styles.buttonGroup}>
          <Button type="submit" className={styles.button}>
            Save
          </Button>
          {id ? (
            <Button onClick={onDelete} variant="outline" className={styles.button}>
              Delete
            </Button>
          ) : null}
        </div>
      </form>
    </ContentContainer>
  );
};

UserProfilesDetails.propTypes = {};

export default UserProfilesDetails;
