import { Button, Input, Textarea } from "components/simple";
import FileUploader from "components/simple/FileUploader";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { addGoogleCloudAccount } from "store/entities/cloudAccounts/actions";
import StepsToGet from "../StepsToGet";

import styles from "./styles.module.scss";

const steps = {
  dragAndDrop: {
    title: "Drag and Drop",
    steps: [
      'Log into your Google Cloud console and navigate to <a target="_blank" rel="noreferrer" href="https://console.cloud.google.com/iam-admin/serviceaccounts">IAM Admin > Service Accounts</a>.',
      'Click on "Create Service Account".',
      'Enter "AccuKnox" in the "Service account name", then enter "Accuknox API Access" in the description.',
      "Click on Continue.",
      "Select the role: Project > Viewer and click Continue.",
      'Click on "Create Key".',
      'Leave the default JSON selected and click "Create".',
      "Save the provided JSON file",
      "Drag and Drop the saved JSON file"
    ]
  }
};

const GoogleCloudForm = ({ onBackHandler, onCancelHandler, label, tag }) => {
  const dispatch = useDispatch();
  const [getSteps, setGetSteps] = useState("");
  const [state, setState] = useState({
    projectID: "",
    clientEmail: "",
    privateKey: ""
  });

  const onSave = e => {
    e.preventDefault();
    const data = {
      project_id: state.projectID,
      client_email: state.clientEmail,
      private_key: state.privateKey,
      label: label.value,
      tag: tag?.value || null
    };

    dispatch(addGoogleCloudAccount(data));
  };

  const closeSteps = useCallback(() => {
    setGetSteps("");
  }, [setGetSteps]);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSave}>
        <Input
          type="text"
          label="Project ID"
          placeholder="Enter Your GCP Project ID"
          value={state.projectID}
          onChange={val => handleChange(val.target.value, "projectID")}
          containerClassName={styles.searchContainer}
          className={styles.input}
          required
        />
        <Input
          type="email"
          label="Client Email"
          placeholder="Enter Your Client Email"
          value={state.clientEmail}
          onChange={val => handleChange(val.target.value, "clientEmail")}
          containerClassName={styles.searchContainer}
          className={styles.input}
          required
        />
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Private Key*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("dragAndDrop")}>
              Show steps
            </span>
          </div>
          <Textarea
            placeholder="Enter Your Private Key"
            value={state.privateKey}
            onChange={val => handleChange(val, "privateKey")}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.btnContainer}>
          <Button variant="outline" onClick={onBackHandler}>
            Back
          </Button>
          <Button variant="outline" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Connect</Button>
        </div>
      </form>
      {getSteps && (
        <div className={styles.stepsContainer}>
          <StepsToGet steps={steps[getSteps]} onClose={closeSteps} />
        </div>
      )}
    </div>
  );
};

export default GoogleCloudForm;
