export const API = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;

export const authorization = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
};

export const authorizationTenant = () => {
  const tenantID = sessionStorage.getItem("tenant")
    ? JSON.parse(sessionStorage.getItem("tenant"))
    : JSON.parse(localStorage.getItem("tenant"));

  return {
    "Tenant-Id": `${tenantID?.value}`
  };
};

export const contentTypeJson = () => {
  return { "Content-Type": "application/json" };
};
